.phoneSelectItem {
    text-align: left;
    position: relative;
}
.phoneSelectItem .input_label {
    color: #686866;
    font-family: Uniform;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 10px;
    text-align: left;
}
.phoneSelectItem input {
    color: var(--neutral-charcoal-black, #1D1D1B);
    width: -webkit-fill-available !important;
    border: 2px solid #B9B9B8;
    border-radius: 4px;
    padding: 0 2% 0 20%;
    height: 40px;
    margin-top: 5px;
    outline: none;
    font-family: Uniform;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.PhoneInputBox { 
    position: relative;
    background-color: #ffffff;
    border-radius: 2px 0px 0px 2px !important;
    margin-top: 5px;
    font-size: 15px;
    font-weight: 400!important;
    outline: none;
    /* border: 2px solid #B9B9B8; */
    background-color: #f5f5f5;
    padding: 0 !important;
    /* width: 76% !important; */
    /* padding: 10px 12px; */
  }

.PhoneInputBox img{
    position: absolute;
    right: 5%;
    bottom: 33%;
}

.PhoneInputBox select{
    -moz-appearance:none;
    -webkit-appearance:none;
    appearance:none;
    width: 100% !important;
    border: none !important;
    outline: none !important;
    padding: 13px 16px;
}

  
.PhoneInputBox select option {
  color: #1D1D1B; 
  width: 100%;
  padding: 20px;
  margin-top: 20px;
}

.PhoneInputBox option .selectColor{
  color: #b9b8b84f; 
}

.phoneContainer{
    display: flex !important; 
    flex-direction: row !important;
    height: 44px !important;
}

.countryPosition{
    position: absolute;
    top: 24px;
    left: 2px;
}

.countryFlag{
    margin-right: 5px;
    width: 7%;
    height: auto;
}

/* Screen For 1280*1024 */
@media screen and (max-width: 1280px) {
    .phoneSelectItem input {
        padding: 0 2% 0 22%;
    }
 }

 /* Screen For 1024*600 */
@media screen and (min-width: 992px) and (max-width: 1242px) {
    .phoneSelectItem input {
        padding: 0 2% 0 26%;
    }

}