.genReportingContainer{
    display: flex;
    align-items: flex-start;
    gap: 20px;
    align-self: stretch;
  }

.reportingTimeSec{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--M, 12px);
    flex: 1 0 0;
  }

.reportingCalendar{
    display: flex;
    align-items: flex-start;
    gap: 20px;
  }

.reportTimeCalenderDiv div{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }

  .anyFacilitySec{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--M, 12px);
    align-self: stretch;
  }

  .specifyRowArea{
    width: -webkit-fill-available;
  }

  .reportingCalendar .css-1rv8zyt-MuiStack-root{
    padding-top: 0px !important;
    padding-bottom: 0px !important;

  }