.addContracContainer{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--XL, 24px);
    align-self: stretch;
    border-radius: 8px;
    background: #FFF;
}

.addContractRow{
    display: flex;
    flex-direction: row;
    width: 100%;
}

.addContractCol1{
    width: 30%;
}

.addContractCol2{
    width: 70%;
}

.addContracContainer .addContractRow h1{
    color: var(--Neutral-Charcoal-Black-500, #1D1D1B);
    font-family: Uniform;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-transform: uppercase;
    margin: 0;
    -webkit-text-stroke-width: 0.3px;
}

.addContracContainer .addContractRow p{
    color: var(--Neutral-Charcoal-Black, #1D1D1B);
    font-family: Uniform;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
    -webkit-text-stroke-width: 0.3px;
}