
.userRequestContainer{
    display: flex;
    padding: 9px 12px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-radius: 8px;
    background: linear-gradient(143deg, #05220C -6.63%, #12471F 97.99%);
}

.viewReqCol1 p{
    color: #FFF;
    font-family: Uniform;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
    -webkit-text-stroke-width: 0.2px;
}

.viewReqBtn{
    display: flex;
    padding: 8px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 4px;
    background:  #FFF;
}

.viewReqBtn p{
    color: var(--Neutral-Charcoal-Black, #1D1D1B);
    text-align: center;
    font-family: Uniform;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    margin: 0;
    -webkit-text-stroke-width: 0.2px;
}