
.regBackBtn{
    cursor: pointer;
    border: 1px solid var(--primary-aqua-blue, #008BB2);
    background: transparent;
    display: flex;
    padding: 12px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 4px;
   }
   .regBackBtn p{
    color: var(--primary-aqua-blue, #008BB2) !important;
    text-align: center !important;
    font-family: Uniform !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 800 !important;
    line-height: normal!important;
    /* -webkit-text-fill-color: 0.2px !important; */
   }
   .regBackBtn:hover {
    border-radius: 4px;
    border: 1px solid  #008BB2;
    background: var(--primary-aqua-blue-50, #E6F3F7);
   }