@font-face {
  font-family: 'Woodman-HeavyRounded';
  src: url('../../src/assets/fonts/woodmanFont/Woodman-HeavyRounded.otf') format('opentype');
   /* You can include other formats here like .woff, .woff2, .ttf */
  /* Woodman-HeavyRounded */
}
@font-face {
  font-family: 'Uniform';
  src: url('../../src/assets/fonts/uniformFont/Uniform.ttf') format('opentype');
   /* You can include other formats here like .woff, .woff2, .ttf */
  /* Uniform-Condensed-Light */
  /* Uniform */
}

html {
  /* font-size: 62.2%; */
}

  .row{
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .cursorPointer{
    cursor: pointer;
  }
  .mL-05r{
    margin-left: 0.5rem;
  }
  .mL-1r{
    margin-left: 1rem;
  }
  .mL-2r{
    margin-left: 2rem;
  }
  .mL-3r{
    margin-left: 3rem;
  }
  .mb_1r{
    margin-bottom: 1rem;
  }
  .mt_1r{
    margin-top: 1rem;
  }
  .mt_2r{
    margin-top: 2rem;
  }
  .wd-100{
    width: 100%;
  }
  .wd-90{
    width: 90%;
  }
  .wd-60{
    width: 60%;
  }
  .wd-65{
    width: 65%;
  }
  .wd-70{
    width: 70%;
  }
  .wd-50{
    width: 50%;
  }
  .wd-40{
    width: 40%;
  }
  .wd-45{
    width: 45%;
  }
  .wd-35{
    width: 35%;
  }
  .wd-33{
    width: 33%;
  }
  .wd-30{
    width: 30%;
  }
  .wd-38{
    width: 38%;
  }
  .wd-25{
    width: 25%;
  }
  .wd-20{
    width: 20%;
  }
  .wd-10{
    width: 10%;
  }

  .gap-2{
    gap:2% !important;
  }
  .gap-3{
    gap:3% !important;
  }

  .bsInputRow{
    display: flex;
    flex-direction: row;
    gap: 20px;
  }

  .basicDetails_timeLineContents {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .bsCityDiv{
    width: 33.5%;
  }
  .mb-30{
    margin-bottom: 30px !important;
  }
  .mb-40{
    margin-bottom: 40px !important;
  }

  .mb-16{
    margin-bottom: 16px !important;
  }

  .mb-5{
    margin-bottom: 5px !important;
  }

  .horizontalLine{
    width: 100%;
    border: 1px solid #1D1D1B;
    opacity: 0.1;
    margin-bottom: 1rem;
    margin-top: 1rem;
  }

  .menuHorizontalLine{
    width: 100%;
    border: 1px solid #FFFFFF;
    opacity: 0.1;
  }

  .gifDiv{
    width: 243.467px;
    height: 220px;
  }

  .relativePosition{
    position: relative;
  }

  .login_Eyeicon_position {
    z-index: 3;
    position: absolute;
    right: 1%;
    top: 29px;
    bottom: auto;
    background: #ffffff;
    padding: 7px;
  }
  
  .login_Eye_icon {
    height: 20%;
    width: 20px;
  }

  .eye_color {
    color:#008BB2;
  }

  .colorWhite{
    color: #ffffff;
  }  

  .navbar-nav li{
    cursor: pointer;
  }

  .hasThisText{
    font-family: Uniform;
    font-size: 15px;
    font-style: normal;
    font-weight: 800 !important;
    line-height: 20px;
    margin:0;
  }

  .errorIcon{
    width: 13px;
    height: auto;
    margin-right: 2px;
    margin-bottom: 1px;
  }

  .inputError{
    display: flex !important;
    align-items: center;
    color: #F45B69 !important;
    font-weight: 400 !important;
    margin: 0;
    margin-top: 5px !important;
    font-size: 12px !important;
    line-height: 0px !important;
  }

  input{
    font-family: 'Uniform';
    font-size: 16px;
    color: #1D1D1B;
  }
  
  .custom-input::placeholder {
    opacity: 0.2;
   }

   .error-border {
    border: 2px solid #F45B69 !important; /* Red border color for error */
    /* Add any additional styles as needed */
  }

  .input-error {
    border: 2px solid #F45B69 !important;
    /* Add any additional styles as needed */
  }

  .NextBtn{
    display: contents;
  }

  .css-vqmohf-MuiButtonBase-root-MuiRadio-root{
    padding: 0 8px !important;
  }

  .branchSecHeading{
    color: var(--primary-aqua-blue-900, #003A4B);
    font-family: Uniform;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    -webkit-text-stroke-width: 0.5px;
  }

  
/* emission forms */

.mendatoryStart{
  display: flex;
}
.mendatoryStart p{
  margin: 0;
}

.emissionDecSec_Col1 .mandatory_info p{
  font-size: 12px;
  padding: 2px 4px;
}

.bottomSubmitRow{
  width: 100%;
  display:flex;
  justify-content: space-between;
  margin-top: 1rem;
}

.form-row {
  display: flex;
  flex-direction: row;
}
.form-row p{
 color:  #979796;
 font-family: Uniform;
 font-size: 12px;
 font-style: normal;
 font-weight: 400;
 line-height: 20px;
 margin: 0px;
}

.emisSectionGap{
  display: flex;
  flex-direction: column;
  align-self: stretch;
  border-radius: 8px;
  background: var(--Neutral-Pure-White, #FFF);
}

.initiativeSections{
  display: flex;
  flex-direction: column;
  align-self: stretch;
  border-radius: 8px;
}

.initiativeSections .css-1elwnq4-MuiPaper-root-MuiAccordion-root{
  display: flex;
  flex-direction: column;
  align-self: stretch;
  border-radius: 8px;
  background: var(--Neutral-Pure-White, #FAFAFA);
}

  .emissionDecSec_Col2 p {
    color: var(--neutral-charcoal-black, #1D1D1B);
    font-family: Uniform;
    font-size: 14px;
    font-style: normal;
    font-weight: 800;
    line-height: 20px;
    margin:0;
  }

  .mandatory_info p{
    font-size: 14px;
    margin: 0 0 0 10px;
    padding: 4px 8px;
    background-color: #e2f3f7;
    border: 1px solid #008db3;
    border-radius: 5px;
  }

  .typographyRow{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .editBtnRow{
    display: flex;
    flex-direction: row;
    gap: 12px;
  }

  .viewCmtRow{
    display: flex;
    padding-left: 260px;
    align-items: center;
    gap: 8px;
}
  
  .GenrlInfo{
    display: flex;
    align-items: center;
    width: 100%;
    align-items: stretch;
   }

  .GenrlInfo h3{
    color: var(--primary-aqua-blue, #008BB2);
    text-align: center;
    font-family: Uniform;
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: 20px;
    text-transform: uppercase;
    margin: 0;
  }

  .checkboxSection{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
  }

  .emissionCopy{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 32px;
  }
 

  .checkboxSection label{
    display: flex;
    align-items:center;
    gap: 8px;
  }
  .checkboxSection label input p{
    color: var(--neutral-charcoal-black, #1D1D1B);
    text-align: center;
    font-family: Uniform;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .specifyRow{
    display: flex;
    flex-direction: column ;
  }

  .equitySection{
    display: flex;
    padding: 16px;
    flex-direction: column;
    gap: 20px;
    align-self: stretch;
    border-radius: 8px;
    background: #FAFAFA;
  }
  
  .remove{
    display: flex;
    gap: 5px;
    align-items: center;
    color: #008BB2;
    text-align: right;
    font-family: Uniform;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .remove_div{
    display: flex;
    justify-content: space-between;
   }

   p.remove{
    color: #008BB2;
    text-align: right;
    font-family: Uniform;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
   }
   
   p.doRepText{
    font-weight: 100 ;
    color: #686866;
    font-size: 12px;
    padding-bottom: 10px;
  }

  p.equityText{
    color: #003A4B ;
    font-weight: 800 ;
    font-size: 14px;
    margin: 0;
}

.addCmtRow{
  display: flex;
  padding-left: 260px;
  align-items: center;
  gap: 8px;
}

.addBranch_div{
  display: flex;
  padding: 0rem 1rem;
 }

 .addBranch{
  display: flex;
  gap: 5px;
  align-items: center;
  color: #008BB2;
  text-align: right;
  font-family: Uniform;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
} 

.flexColumn{
  display: flex;
  flex-direction: column;
}

.mt-12{
  margin-top: 12px !important;
}
.mb-12{
  margin-bottom: 12px;
}
.mt-28{
  margin-top: 28px !important;
}

.mt-50{
  margin-top: 50px;
}

.mb-28{
  margin-bottom: 28px;
}
.sectionSpacing{
  margin-top: 28px;
  margin-bottom: 28px;
}

.repCompPdf {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  gap: 20px 45px;
}

.addPdfWidth .pdfUpload{
  width: 97%;
  /* width: 27%; */
  /* display: block; */
  padding: 20px 20px;
}

.preePdfRow{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
}

.scopeSection{
  margin-top: 12px;
  width: -webkit-fill-available;;
  border-radius: 4px;
  background-color: #FAFAFA;
  display: flex;
  flex-direction: column;
  padding: 16px 16px;
  gap: 20px;
 }
  
  html {
    font-size: 100%;
  }
  .mt-40{
    margin-top: 40px;
  }
  .mb-40{
    margin-bottom: 40px;
  }

 /*=== header-area start ===*/


.header-area {
    padding: 15px 0px;
    position: relative;
    top: 0px;
    width: 100%;
    display: block;
    z-index: 1000;
    transition: 0.3s all ease;
  }

  .foot-container{ 
    display: flex;
    flex-direction: row;
    margin: auto;
    width: 90%;
  }

  .container{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: auto;
    /* width: 90%; */
    width: 84%;
    /* overflow: hidden; */
  }

.header-main {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 75px;
  }

  .header-logo img {
    width: 112.678px;
    height: 44px;
    margin-right: 64px;
  }

  .navbar-brand img {
  }
  .navbar-brand img:hover {
    cursor: pointer;
  }

  
.navbar-nav li {
    /* padding-left: 41px; */
    color: var(--neutral-charcoal-black, #1D1D1B);
    font-family: Uniform;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-right: 36px !important;
  }
  .navbar-nav li.logout {
    padding-left: 5px;
  }
  .navbar-nav li a{
    text-decoration: none;
}

  .navbar-nav li a {
    font-family:Uniform;
    font-size: 14px !important;
    font-weight: 400 !important;
    color: #1D1D1B !important;
    text-align: center !important;
    margin-left: 0.9rem;
  }
  
  .navbar-nav li Link::after {
    content: "";
    width: 60%;
    height: 4px;
    display: block;
    background: #444343;
    border-radius: 50px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -5px;
    opacity: 0;
    transition: 0.2s all ease;
  }
  
  .navbar-nav li Link::after,
  .navbar-nav li Link:hover {
    opacity: 1;
  }
  
  .containers {
    padding-left: 30px;
    padding-right: 30px;
  }
  
  .navbar-nav li .login-btn {
    font-size: 15px;
    font-weight: 700;
    color: #008BB2;
    display: inline-block;
    padding: 12px 12px;
    border-radius: 5px;
    margin-left: -15px;
    cursor: pointer;
  }
  
  .navbar-nav li .loginactive {
    background: #00637E;
    color: white !important;
    border: none;
  }

  .navbar-collapse ul{
    margin: 0;
  }
  
  .navbar-nav li .loginBtn_Color:hover {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background: #F8FDFF;
    box-shadow: 0px 0px 8px 0px rgba(29, 29, 27, 0.20) inset;

    
  }
  
  .navbar-nav li .login-btn::after {
    display: none;
  }
  
.navbar-collapse {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex!important;
    flex-basis: auto;
    align-items: center;
    flex-grow: 1;
}
.navbar-expand-lg .navbar-nav {
    flex-direction: row;
}
.ms-auto {
    margin-left: auto!important;
}
.navbar-nav {
    display: flex;
    list-style: none;
    margin-bottom: 0;
    padding-left: 0;
}
  .navbar-nav li .loginBtn_Color{
      color: #008BB2 !important;
      font-weight: 700 !important;
      margin-right: 0px;
     }


.navbar-nav li {
    color: var(--neutral-charcoal-black, #1D1D1B);
    /* padding-left: 41px; */
    -webkit-text-stroke-width: 0.2px;
}

.loginSignUp_row{
  display: flex;
  justify-content: flex-end;
}


  /*=== footer-area start ===*/
  .footTop_Sec{
    padding-top: 100px;
    position: relative;
  }
  .footer-area {
    background: #003a4b;
    padding: 45px 0px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 25vh;
   }
  
  .footer-main {
    width: 100%;
    margin: 0 auto;
  }
  .logo_column{
    flex: 0 0 auto;
    width: 50%;
    text-align: left;
    margin-left: 20px;
  }
 .footer_rightCol{
    display: flex;
    width: 50%;
    flex-direction: row;
    justify-content: center;
 }
 .child_div{
    width:50%
 }
  .footer-item ul li a {
    text-decoration: none;
    font-family: Uniform;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: #ffffff;
    display: inline-block;
    transition: 0.2s all ease;
  }
  
  .footer-item ul li .active {
    font-weight: 700;
    margin-bottom: 25px;
  }
  
  .footer-item ul li a:hover {
    opacity: 0.85;
  }
  
  .footer-right img {
    width: 230px;
    transition: 0.2s all ease;
  }
  .footer-main ul {
    list-style-type: none;
  }
  
  .footer-right ul li {
    padding-bottom: 32px;
  }
  
  .footer-right img:hover {
    opacity: 0.85;
  }
  
  .footer-middle {
    position: relative;
  }
 
  .social-media {
    margin-top: 40px;

  }
  .social-media ul{
    padding-left: 0px;
    display: inline-flex;
    align-items: flex-start;
    gap: 23px;
    margin: 0;
  }
  
  .social-media ul li {
    display: inline-block;
    padding: 0 0px;
  }
  
  .social-media ul li a {
    color: #ffffff;
    transition: 0.2s all ease;
    cursor: pointer;
  }
  
  .social-media ul li a:hover {
    opacity: 0.8;
  }
  .fa-lg {
    font-size: 24px;
   }
  
  .copy-cnt {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .copy-cnt p {
    font-size: 16px;
    font-weight: 400;
    color: #ffffff;
    margin: 0;
  }
  .footer_logoImg{
    width: 163.895px;
    height: 64px;
  }
    .foot_ul ul{
      text-align: left;
      margin: 0;
      padding: 0;
      width: 50%;
    }
     .foot_ul .foot_li_title{
      color: var(--neutral-pure-white, #FFF);
      font-family: Uniform;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px;
      text-transform: uppercase;
      -webkit-text-stroke-width: 0.4px;
     }

    .foot_ul li{
      color: #ffffff;
      font-family: Uniform;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; 
     }

    li.FooterMenu{
      color: var(--neutral-pure-white, #FFF);
      font-family: Uniform;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      opacity: 0.7;
      -webkit-text-stroke-width: 0;
     }

     .footerAddress{
      color: var(--neutral-pure-white, #FFF);
      font-family: Uniform;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      opacity: 0.7;
      -webkit-text-stroke-width: 0;
     }

     .foot_ul p{
      color: var(--neutral-pure-white, #FFF);
      font-family: Uniform;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      margin: 0;
     }
     .foot_location{
       width: 50%;
     }

    .foot_rightbg{
      width: 90px;
      position: absolute;
      left: 0;
      bottom: 100px;
    }
    .foot_leftbg{
      position: absolute;
      right: 12px;
      width: 13%;
      bottom: 29px;
    }
   .copyRight{
        background-color: #0E2B33;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
     }
     .copyRight_col1{
        width: 50%;
     }
     .copyRight_col2{
        width: 50%;
     }
     .copyRight p{
        color: #ffffff;
     }
     .copyRight_col1 p{
        opacity: 0.6;
        margin: 0px;
        color: var(--neutral-pure-white, #FFF);
        font-family: Uniform;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        padding: 13px 95px;
      }
      .copyRight_col2 p{
        opacity: 0.6;
        margin: 0px;
        color: var(--neutral-pure-white, #FFF);
        text-align: right;
        font-family: Uniform;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        padding: 13px 95px;
     }

     /*........................ Home Page.......................... */
     .container{ 
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: auto;
      /* width: 90%; */
      width: 84%;
      /* overflow: hidden; */
     }
    
    /* Screen For 1680*1050 */
    @media screen and (min-width: 1621px) and (max-width: 1820px) {
        .header-main {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px 30px;
        }

        .foot_rightbg{
          left: 0px;
          bottom: 120px;
        }
        .foot_leftbg{
          width: 16%;
          right: 5px;
          bottom: 52px;
        }
    }
    
    /* Screen For 1440*900 */
    @media screen and (min-width: 1281px) and (max-width: 1620px) {
        .header-main {
            display: flex;
            align-items: center; 
            justify-content: space-between;
            padding: 10px 48px;
        }
        .navbar-nav li {
          margin-right: 0;
        }
        .foot_rightbg{
          left: 0px;
          bottom: 105px;
          width: 85px;
        }
        .foot_leftbg{
          width: 16%;
          right: 5px;
          bottom: 54px;
        }
    }
    
    /* Screen For 1280*1024 */
     @media screen and (max-width: 1280px) {
    
        .header-logo img {
            width: 110px;
        }
    
        .header-main {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px 25px;
        }
        .navbar-nav li .loginBtn_Color {
            color: #008BB2 !important;
            font-weight: 700 !important;
            padding-right: 20px;
        }
        .navbar-nav li .login-btn {
            font-size: 15px;
            width: 60%;
            display: inline-block;
            padding: 7px 28px;
            border-radius: 5px;
            margin-left: -10px;
        }
        .foot_rightbg{
          left: 0px;
          bottom: 97px;
          width: 83px;
        }
        .foot_leftbg{
          width: 18%;
          right: 5px;
          bottom: 54px;
        }
    }
    
    /* Screen For 1024*600 */
    @media screen and (min-width: 992px) and (max-width: 1242px) {
        .navbar-nav li a {
          font-family: Uniform;
          font-size: 15px !important;
          font-weight: 400 !important;
          color: #1D1D1B !important;
        }
        .navbar-nav li {
          padding-left: 35px;
          margin-right: 0 !important;
        }
        .navbar-nav li .login-btn {
            font-size: 15px;
            width: 57px;
            display: inline-block;
            padding: 7px 25px;
            border-radius: 5px;
            margin-left: -10px;
        }
        .foot_rightbg{
          left: 0px;
          bottom: 100px;
          width: 73px;
        }
        .foot_leftbg{
          width: 18%;
          right: 5px;
          bottom: 54px;
        }
        .logo_column{
          width: 40%;
        }
        .footer_rightCol{
          width: 60%;
        }
    }
    
    @media screen and (min-width: 768px) and (max-width: 992px) {
        .header-logo img {
          width: 85px;
          margin-right: 20px;
       }
       .navbar-nav li a {
            font-size: 12px !important;
        }
        .navbar-nav li {
          padding-left:20px;
          font-size: 12px;
          margin-right: 0 !important;
      }
      .navbar-nav li .login-btn {
        font-size: 12px !important;
        width: 77%;
        padding: 7px 14px !important;
        margin-left: 6px;
      }
      .input-item input {
        width: 87% !important;
      }
      .foot_leftbg {
        position: absolute;
        right: 25px;
        width: 22%;
        margin-top: 95px;
       }
       .foot_rightbg {
        position: absolute;
        left: 0px;
        margin-top: 103px;
      }
      .logo_column{
        width: 40%;
      }
      .footer_rightCol{
        width: 60%;
      }
    }
    
    @media screen and (max-width: 767px) {
        .header-logo img {
          width: 65px;
          height: auto;
          margin-right: 10px;
        }
      .input-item input {
        width: 93% !important;
      }
      .foot_leftbg {
        position: absolute;
        right: 25px;
        width: 22%;
        margin-top: 95px;
       }
       .foot_rightbg {
        position: absolute;
        left: 0px;
        margin-top: 103px;
      }

      .navbar-nav li .login-btn {
        font-size: 9px !important;
        width: 65%;
        padding: 7px 14px !important;
        margin-left: 6px;
      }
      .navbar-nav li {
        padding-left: 10px;
        font-size: 9px;
        margin-right: 0 !important;
      } 
      .logo_column{
        width: 45%;
        margin-left: 0px;
      }
      .footer_rightCol{
        width: 55%;
      } 
    
    }
    
    @media screen and (max-width: 575px) {
        body {
            display: block;
            margin: 0px;
        }
        .navbar-collapse {
            display: none !important;
        }
        .container {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: auto;
            width: 80%;
            overflow: hidden;
        }
        /* Header Menu */
        .header-main{
            display: none;
        }
        .header-area {
            padding: 0px 0px;
            position: sticky;
            background: linear-gradient(175deg, #00141A -18.79%, #003A4B 102.02%);
            width: 100%;
          }
         .mobile_header{
            display: flex;
           flex-direction: row;
           align-items: center;
           height: 85px;
           padding: 0px 25px;
          }
          .mobileHeader_Col1{
            width: 50%;
            text-align: left;
          }
          .mobileHeader_Col2{
            width: 50%;
            text-align: right;
          }
          .mobileHeader_Col2 img{
            width: 50%;
          }
    
          /* Header Burger Menu */
          .mobNav_container {
            background-color: #ffffff;
            width: 100%;
            position: absolute;
            left: 0;
            top: 100%;
            height: 100vh;
          }
        
          .menuList_container {
            height: 8vh;
            display: flex;
            flex-direction: "row";
            margin: 15px 20px;
            align-items: center;
          }
          .menuList_firstCol {
            width: 10%;
          }
          .menuList_secondCol {
            width: 80%;
            text-align: left;
            margin-left: 10px;
          }
          .menuList_thirdCol {
            width: 10%;
          }
          .fontAwesomeIcon_img {
            height: 18px;
            width: 18px;
          }
          .burgerMenu_List_Text {
            font-family:Uniform;
            font-weight:700;
            font-size: 15px;
          }
    
      
        /* Footer Section */
        .footer-area {
            background: #003a4b;
            padding: 15px 0px;
            width: 100%;
        }
        .foot-container {
            width: 100%;
        }
        .footer-main .row {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .logo_column {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-around;
          margin-left: 0px;
        }
        .social-media {
          margin-top: 0px;
          width: auto;
          max-width: inherit;
          padding-left: 3rem;
        }
        .foot_location {
            width: 20%;
        }    
        .footTop_Sec {
          padding-top: 0px;
      }
        .footer_logoImg {
            width: 100px;
        }
        .footer_rightCol{
          display: flex;
          width: 100%;
          justify-content: space-evenly;
          align-items: center;
         }
         .footer-item ul li a {
            text-wrap: nowrap;
            font-size: 14px;
            color: #ffffff;
            display: inline-block;
            transition: 0.2s all ease;
        }
        .foot_ul ul {
          text-align: left;
          margin: 0;
          padding: 0;
          width: auto;
        }
        .foot_ul li {
          font-size: 15px;
        }
        .foot_ul p{
          font-size: 15px;
        }
        .copyRight {
            background-color: #0e2b33;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .copyRight_col1 {
            width: 100%;
        }
        .copyRight_col2{
            width: 100%;
        }
        .copyRight p{
          font-size: 14px ;
            text-align: center !important;
            margin: 5px 5px;
        }
        .social-media ul {
          gap: 12px;
        }
    }
    
    /* ----------------------------------------------400----------------------------------------- */
    
    @media (min-width: 300px) and (max-width: 400px) {
        body {
            display: block;
            margin: 0px;
        }
        .container {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: auto;
            width: 75%;
        }
    
        /* Header Menu */
        .header-area {
            padding: 0px 0px;
            position: sticky;
            background: linear-gradient(175deg, #00141A -18.79%, #003A4B 102.02%);
            width: 100%;
          }
         .mobile_header{
            display: flex;
           flex-direction: row;
           align-items: center;
           height: 85px;
           padding: 0px 25px;
          }
          .mobileHeader_Col1{
            width: 50%;
            text-align: left;
          }
          .mobileHeader_Col2{
            width: 50%;
            text-align: right;
          }
          .mobileHeader_Col2 img{
            width: 50%;
          }
    
          /* Header Menu */
        .header-area {
            padding: 0px 0px;
            position: sticky;
            background: linear-gradient(175deg, #00141A -18.79%, #003A4B 102.02%);
            width: 100%;
          }
         .mobile_header{
            display: flex;
           flex-direction: row;
           align-items: center;
           height: 85px;
           padding: 0px 25px;
          }
          .mobileHeader_Col1{
            width: 50%;
            text-align: left;
          }
          .mobileHeader_Col2{
            width: 50%;
            text-align: right;
          }
          .mobileHeader_Col2 img{
            width: 50%;
          }
    
          /* Header Burger Menu */
          .mobNav_container {
            background-color: #ffffff;
            width: 100%;
            position: absolute;
            left: 0;
            top: 100%;
            height: 100vh;
          }
        
          .menuList_container {
            height: 8vh;
            display: flex;
            flex-direction: "row";
            margin: 15px 20px;
            align-items: center;
          }
          .menuList_firstCol {
            width: 10%;
          }
          .menuList_secondCol {
            width: 80%;
            text-align: left;
            margin-left: 10px;
          }
          .menuList_thirdCol {
            width: 10%;
          }

          .burgerMenu_List_Text {
            font-family:Uniform;
            font-weight:700;
            font-size: 15px;
          }
          .footer_logoImg{
            margin-left: 7px;
          }
          
      
        /* Footer Section */
        .logo_column {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-around;
        }
        .social-media {
          margin-top: 0px;
          width: auto;
          max-width: inherit;
          padding-left: 3rem;
       }
    
    }

    .MuiPaper-root .container{
      width: max-content;
    }
    .MuiPaper-root .container p{
      width: max-content;
      text-wrap: nowrap;
    }

.MuiSelect-outlined{
  padding: 0%; 
  font-family: Uniform;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 75%;
}

