
     /* Chairman Section */
     .chairman_section {
        width: 84%;
        padding-top: 80px;
        padding-bottom: 100px;
        margin: auto;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        position: relative;
     }
     .chairmanBackground-text{
      bottom: 0;
      font-family: 'Woodman-HeavyRounded';
      font-size: 202px;
      left: -150px;
      right: 0;
      top: 9%;
      line-height: 200px;
      opacity: 0.09;
      pointer-events: none;
      position: absolute;
      text-align: left;
      z-index: -1;
      color: transparent;
      -webkit-text-stroke-width: 1px;
      -webkit-text-stroke-color: black;
   }
     .chairmanSec_Col1 {
      width: 50%;
      display: flex;
      flex-direction: column;
      gap: 32px;
     }
     .chairmanSec_Col2 {
        width: 50%;
        display: flex;
        justify-content: flex-end;
     }
     .chairman_section h1 {
      color: var(--neutral-charcoal-black, #1D1D1B);
      font-family: Woodman-HeavyRounded;
      font-size: 40px;
      font-style: normal;
      font-weight: 400;
      line-height: 56px;
      text-align:left ;
      margin: 0;
      width: 70%;
      }
     .chairman_section h2 {
      color: var(--neutral-charcoal-black, #1D1D1B);
      margin: 0;
      font-family: Uniform;
      font-size: 24px;
      font-style: normal;
      font-weight: 800;
      line-height: 36px; 
      text-align: left;
      }
      .chairmanName{
        text-align: right !important;
      }
     .chairman_section p {
      color: var(--neutral-charcoal-black, #1D1D1B);
      margin: 0;
      font-family: Uniform;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      text-align: left;
      line-height: 32px;
      width: 92%;
      -webkit-text-stroke-width: 0.2px;
    }

    .chairImgDiv{
        position: relative;
        margin-right: 80px
    }

    .chairImgDiv h2{
        margin: 0;
        margin-top: 12px;
        color: var(--neutral-charcoal-black, #1D1D1B);
        text-align: right;
        font-family: Uniform;
        font-size: 24px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
    }
    
    .chairImgDiv h3{
        margin: 0;
        color: var(--neutral-charcoal-black, #1D1D1B);
        text-align: right;
        font-family: Uniform;
        font-size: 14px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
        text-transform: uppercase;
    }
    
     .chairImg1{
      width: 345px;
      height: auto;
     }

     .chairImg2{
        position: absolute;
        height: auto;
        left: -66px;
        bottom: 58px;
        width: 94px;
     }

     .chairImg3{
        position: absolute;
        bottom: 0;
        right: -100px;
        top: 30px;
        width: 150px;
        opacity: 1;
     }


     /* Screen For 1680*1050 */
    @media screen and (min-width: 1621px) and (max-width: 1820px) {}

     /* Screen For 1440*900 */
     @media screen and (min-width: 1281px) and (max-width: 1620px) {

        .chairman_section p {
            width: 93%;
        }
     }

      /* Screen For 1280*1024 */
      @media screen and (max-width: 1280px) {}

      /* Screen For 1024*600 */
    @media screen and (min-width: 992px) and (max-width: 1242px) {
        .chairman_section {
            display: flex;
            flex-direction: column;
        }
      .chairmanSec_Col1 {
        width: 100%;
      }
        .chairmanSec_Col2 {
            width: 100%;
        }
        .chairmanSec_Col2 {
          margin-top: 20px;
          display: flex;
          justify-content: flex-start;
        }

        .chairmanBackground-text {
            top: 4%;
            left: -105px;
            font-size: 130px;
        }
     }

    @media screen and (min-width: 768px) and (max-width: 992px) {
        .chairman_section{
            display: flex;
            flex-direction: column !important;
            align-items: flex-start !important
        }
        .chairmanSec_Col1 {
            width: 100%;
        }
        .chairmanSec_Col2 {
            width: 100%;
            display: flex;
            justify-content: flex-start;
            margin-top: 20px;
        }
        .chairmanBackground-text {
            top: 4%;
            left: -86px;
            font-size: 130px;
        }
    }

    @media screen and (max-width: 767px) {
        .chairman_section{
            display: flex;
            flex-direction: column !important;
            align-items: flex-start !important
        }
        .chairmanSec_Col1 {
            width: 100%;
            gap: 8px;
        }
        .chairmanSec_Col2 {
            width: 100%;
            display: flex;
            justify-content: flex-start;
            margin-top: 20px;
        }
        .chairman_section h1 {
            font-size: 34px;
            font-style: normal;
            line-height: 56px;
            text-align: left;
            margin: 0;
        }
        .chairman_section h2 {
            font-size: 17px;
            font-style: normal;
            line-height: 36px;
            text-align: left;
        }
        .chairman_section p {
            font-size: 12px;
            text-align: left;
            line-height: 19px;
            letter-spacing: 0px;
            width: 100%;
        }
        .chairmanBackground-text {
            top: 4%;
            left: -72px;
            font-size: 122px;
        }
        .chairImgDiv {
            margin-left: 26px;
        }
    
    }

    @media screen and (max-width: 575px) {
        body {
            display: block;
            margin: 0px;
        }
        .chairmanBackground-text{
            display: none;
        }
        .chairman_section {
            display: flex !important;
            flex-direction: column !important;
            align-items: center !important;
            margin: auto;
            width: 90%;
            overflow: hidden;
            padding-top: 60px;
            padding-bottom: 80px;
        }
       /* Chairman Section */
        .chairmanSec_Col1 {
            width: 100%;
        }
        .chairmanSec_Col1 {
            width: 100%;
        }
    
        .chairman_section h1 {
            font-size: 23px;
            line-height: 39px;
            width: 100%;
        }
        
        .chairmanSec_Col2 {
            width: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .chairman_section h2 {
            font-size: 17px;
            font-style: normal;
            line-height: 27px;
            text-align: left;
        }
        .chairImgDiv {
            margin-left: 46px;
        }
        .chairImg1 {
            width: 274px;
            height: auto;
        }
    
    }

    /* ----------------------------------------------400----------------------------------------- */
    
    @media (min-width: 300px) and (max-width: 400px) {
        body {
            display: block;
            margin: 0px;
        }
       /* Chairman Section */
        .chairmanSec_Col1 {
            width: 100%;
        }
        .chairmanSec_Col1 {
            width: 100%;
        }
    
        .chairman_section h1 {
            font-size: 23px;
            line-height: 39px;
            width: 100%;
        }
        
        .chairmanSec_Col2 {
            width: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .chairman_section h2 {
            font-size: 17px;
            font-style: normal;
            line-height: 27px;
            text-align: left;
        }
        .chairImg1 {
            width: 244px;
            height: auto;
        }
    
    }
    