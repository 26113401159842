.ghgEmissionPreCon{
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: -webkit-fill-available;
}

.ghgEmissPreTitle{
    color: #1D1D1B;
    font-family: Uniform;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
    -webkit-text-stroke-width: 0.5px;
}

.ghgEmissPreScopeRow{
    display: flex;
    gap: 12px;
}

.ghgEmissScopePre{
    width: -webkit-fill-available;
    display: flex;
    flex-direction: row;
    gap: 12px;
    border-radius: 8px;
    padding: 16px;
}

.ghgEmissPreCol{
    width: 33%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    background-color: #FAFAFA;
}

.ghgEmissPreCol h1{
    color: #003A4B;
    font-family: Uniform;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    margin: 0;
    -webkit-text-stroke-width: 0.5px;
}

.resultPreSec{
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.resultValue{
    display: flex;
    flex-direction: column;
    gap: 2px;
    border-radius: 8px;
    padding: 8px;
    border: 1px solid #B9B9B8;
    background-color: #FFFFFF;
}

.resultPreTitle{
    color: #4A4A49;
    font-family: Uniform;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-transform: uppercase;
    margin: 0;
    -webkit-text-stroke-width: 0.2px;
}

.resultValue h1{
    color: #1D1D1B;
    font-family: Uniform;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-transform: uppercase;
    margin: 0;
    -webkit-text-stroke-width: 0.5px;
}

.resultValue p{
    color: #1D1D1B;
    font-family: Uniform;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
    -webkit-text-stroke-width: 0.5px;
}

.resultDisrip{
    color: #1D1D1B;
    font-family: Uniform;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
    -webkit-text-stroke-width: 0.3px;
}