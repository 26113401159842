.cmntDrawer .MuiDrawer-paperAnchorRight {
  width: 60% !important;
  display: block;
}

.cmntDrawer .css-1hskriy {
  width: 100% !important;
  height: 100%;
  overflow-y: hidden;
}

.chatBoxInnerSec {
  height: calc(100% - 226.4px);
  display: flex;
  overflow-y: scroll;
  overflow-x: none;
  padding-bottom: 20% !important;
  padding: 12px 16px 16px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--L, 16px);
  flex: 1 0 0;
  align-self: stretch;
}

.cmntChatSection {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--M, 12px);
  align-self: stretch;
}

.pdfDocRow {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 12px;
}

.pdfDocRow .pdfDocContainer {
  width: 13%;
}

.commentDrawContainer{
  height: 100%;
}
