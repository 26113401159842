.orgRatingSec {
    background: linear-gradient(175deg,#00141a -18.79%,#003a4b 102.02%);
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin: auto;
    width: -webkit-fill-available ; 
    min-height: 100vh ;
    overflow: hidden; 
    padding: 16px 8px;
  }

  .orgRatingInnerCon{
    padding: 2rem 4rem;
    display: flex;
    width: -webkit-fill-available;
    gap: 3%;
    align-items: flex-start;
    border-radius: var(--corner-radius-corner-radius-large, 20px);
    background: #F7F7F7;
    box-shadow: 0px 0px 8px 0px rgba(29, 29, 27, 0.20);
  }

  .orgRatingCol1 {
    width: 75%;
  }

  .orgRatingCol2 {
    width: 25%;
  }