.myRatingSec{
    display: flex;
    flex-direction: row;
    gap: 40px;
    padding: 29px 80px;
    justify-content: space-between;
}

.myRatingTitle{
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.myRatingTitle h1{
    color: #FFF;
    font-family: Uniform;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
    -webkit-text-stroke-width: 0.5px;
}

.myRatingTitle p{
    color: #F7F7F7;
    font-family: Uniform;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
}