.auditChatBox{
    display: flex;
    width: 70%;
    flex-direction: column;
    align-items: flex-end;
    gap: 8px;
    align-self: stretch;
    align-items: flex-start;
}

.auditChatBoxCon{
    min-width: auto;
    display: flex;
    padding: 8px;
    flex-direction: column;
    align-items: flex-end;
    gap: 8px;
    align-items: flex-start;
    border-radius: 4px;
    border: 0.5px solid var(--Neutral-Charcoal-Black-50, #E8E8E8);
    background: #FAFAFA;
    box-sizing: border-box;
}

.auditChatTitle {
  color: var(--Secondary-Magenta, #a20074);
  font-family: Uniform;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  margin: 0;
  -webkit-text-stroke-width: 0.2px;
}

.auditRightChatBox{
    margin-left: auto;
    margin-right: inherit;
    display: flex;
    flex-direction: column;
    gap: 8px;
    max-width: 70%;
}