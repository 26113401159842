.auditCamical_list {
    display: flex;
    flex-direction: row;
    width: -webkit-fill-available;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.auditGasBg {
    width: -webkit-fill-available;
    display: flex;
    padding: 8px;
    flex-direction: column;
    align-items: flex-start;
    gap: 2px;
    border-radius:4px;
    background: #F0F0F0;
}

.auditGasBg h1{
    color: var(--Neutral-Charcoal-Black-500, #1D1D1B);
    font-family: Uniform;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-transform: uppercase;
    margin: 0;
    -webkit-text-stroke-width: 0.3px;
}

.auditGasBg p{
    color: var(--Neutral-Charcoal-Black-500, #1D1D1B);
    font-family: Uniform;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-transform: uppercase;
    margin: 0;
    -webkit-text-stroke-width: 0.3px;
}

.scopeGreenBg{
    display: flex;
    width: -webkit-fill-available;
    padding: 8px;
    flex-direction: column;
    align-items: flex-start;
    gap: 2px;
    border-radius:4px;
    background: #BDE4C7;
}

.scopeGreenBg h1{
    color: var(--Neutral-Charcoal-Black-500, #1D1D1B);
    font-family: Uniform;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-transform: uppercase;
    margin: 0;
    -webkit-text-stroke-width: 0.3px;
}

.scopeGreenBg p{
    color: var(--Neutral-Charcoal-Black-500, #1D1D1B);
    font-family: Uniform;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-transform: uppercase;
    margin: 0;
    -webkit-text-stroke-width: 0.3px;
}


