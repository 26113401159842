.chatFooterMenuCon {
  position: absolute;
  left: 15px;
  bottom: 70px;
  display: flex;
  flex-direction: column;
  background: var(--Neutral-Pure-White, #fff);
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.08);
  padding: 1px;
  border-radius: 4px;
}

.footerMenuRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  padding: 12px 10px;
}

.rightArraowIcon {
  height: 10px;
}

.recContainer {
  position: absolute;
  left: 22%;
  bottom: 107%;
}

.footerMenuRow:hover {
  background-color: #e6f3f7;
  cursor: pointer;
}
