.auditpdfCol{
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: flex-end;
}

.auditChatPdfSec{
    display: flex;
    flex-direction: row;
    gap: 8px;
    flex-wrap: wrap;
    justify-content: flex-end;
}