.graphImg{
    width: calc(50% - 10.6px);
    box-sizing: border-box;
    background: #ffffff;
    box-shadow: 0px 4px 40px 0px #E8E8E8;
    border-radius: 8px;
}

.graphImg img{
    width: 100%;
    min-height: 264px;
}