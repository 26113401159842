.auditInfoOffsetContainer{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--XL, 24px);
    align-self: stretch;
    border-radius: 8px;
    background: #FFF;
}

.auditInveBoundariesCon h1{
    color: var(--Neutral-Charcoal-Black-500, #1D1D1B);
    font-family: Uniform;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
    -webkit-text-stroke-width: 0.5px;
}
