.userTypeDetContainer{
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    flex: 1 0 0;
    align-self: stretch;
    border-radius: 8px;
    border: 1px solid #E8E8E8;
    background: #FFF;
}

.proTabsContainer{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--CornerRadius-CornerRadius_Large, 20px);
    align-self: stretch;
    height: 100%;
}

.userTypeButton{
    display: flex;
    flex-direction: row;
    gap: 5px;
    border-radius: 6px;
    border: 0.5px solid #54B1CB;
}

.userTypeButton button{
    display: flex;
    padding: 10px 12px;
    align-items: center;
    border: 0;
    border-radius: 4px;
    background: #E6F3F7;
}

.userTypeButton p{
    color: #686866;
    font-family: Uniform;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
    -webkit-text-stroke-width: 0.2px;
}

