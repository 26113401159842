 .equityForm{
    display: flex;
    align-items: flex-start;
    align-content: flex-start;
    gap: 20px;
    align-self: stretch;
    flex-wrap: wrap;
  }

  .equityFormCol1{
    width: 65%;
  }

  .equityFormCol2{
    width: 32%;
  }

  .notVerify{
    margin-left: 50px;
  }

  .flexRow{
    display: flex;
    flex-direction: row;
  }

  .orgConsolidateSec{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--M, 12px);
    align-self: stretch;
   }

  .orgListDownSec{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--M, 12px);
    align-self: stretch;
  }

  .subsidiarySec{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
  }

.financCheck .css-dmmspl-MuiFormGroup-root{
  display: flex !important;
  flex-direction: row !important;
}

.financCheck .css-1h7anqn{
  display: flex !important;
  flex-flow: row !important;
}


