
.rejectTitleBox{
    display: flex;
    width: 480px;
    padding: var(--XL, 24px) 40px;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    border-radius: 4px;
    background: var(--Neutral-Pure-White, #FFF);
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.08);
}

.rejectTitleDiv{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    width: 78%;
}

.rejectPopUpForm{
    width: -webkit-fill-available;
}

.rejectTitleBox h1{
    color: var(--Neutral-Charcoal-Black, #1D1D1B);
    text-align: center;
    font-family: Uniform;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
    -webkit-text-stroke-width: 0.7px;
}

.rejectTitleBox p{
    color: var(--Neutral-Charcoal-Black, #1D1D1B);
    text-align: center;
    font-family: Uniform;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    margin: 0;
    -webkit-text-stroke-width: 0.2px;
}

.rejectBtnRow{
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: 40px;
}

.goBackBtn{
    cursor: pointer;
    border: 1px solid transparent;
    border-radius: 4px;
    transition: background-color 0.3s, color 0.3s;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 2rem;
    padding: 12px;
    gap: 4px;
    background-color: transparent;
}

.goBackBtn:hover{
    background-color: #f0f0f0;
}

.goBackBtn p{
    color: var(--Neutral-Charcoal-Black, #1D1D1B);
    text-align: center;
    font-family: Uniform;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
    -webkit-text-stroke-width: 0.5px;
}

.proRejectBtn button{
    display: flex;
    padding: 12px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 4px;
    background: #F45B69;
}

.proRejectBtn button div{
    color: var(--Neutral-Pure-White, #FFF);
    text-align: center;
    font-family: Uniform;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}