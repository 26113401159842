.reciveSummarySec{
    display: flex;
    flex-direction: column;
    gap: 8px;
    max-width: 100%;
    min-width: auto;
}

.summaryCol{
    overflow-wrap: anywhere;
}
.descripCol{
    overflow-wrap: anywhere;
}

.summaryCol h1{
    color: var(--Neutral-Charcoal-Black, #1D1D1B);
    font-family: Uniform;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
    -webkit-text-stroke-width: 1px;
}

.summaryCol p{
    color: var(--Neutral-Charcoal-Black, #1D1D1B);
    font-family: Uniform;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
    -webkit-text-stroke-width: 0.2px;
}

.descripCol h1{
    color: var(--Neutral-Charcoal-Black, #1D1D1B);
    font-family: Uniform;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
    -webkit-text-stroke-width: 1px;
}

.descripCol p{
    color: var(--Neutral-Charcoal-Black, #1D1D1B);
    font-family: Uniform;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
    -webkit-text-stroke-width: 0.2px;
}