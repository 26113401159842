
.profileBasicHeadSec{
    display: flex;
    flex-direction: column;
    padding: 20px;
    align-items: flex-start;
    align-content: flex-start;
    gap: 16px 16px;
    align-self: stretch;
    flex-wrap: wrap;
    border-radius: 8px;
    background: #FAFAFA;
}

.profileBasicHeadSec h1{
    color: var(--Neutral-Charcoal-Black, #1D1D1B);
    font-family: Uniform;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
    -webkit-text-stroke-width: 0.5px;
}

.profileBasicHeadSec .profileHomeAddrTitle{
    color: var(--Neutral-Charcoal-Black, #003A4B);
    font-family: Uniform;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
    -webkit-text-stroke-width: 0.5px
}

.basicProDetails{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    align-content: flex-start;
    gap: 16px 0px;
    align-self: stretch;
}

.basicHeadqDetDiv{
    display: flex;
    width: 632px;
    align-items: flex-start;
    align-content: flex-start;
    gap: var(--XL, 24px) 32px;
    flex-shrink: 0;
}

.basHeadqDetails{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: -webkit-fill-available;
    gap: 3px;
    width: 50%;
}

.basHeadqDetails p{
    color: var(--Neutral-Charcoal-Black-300, #686866);
    font-family: Uniform;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    margin: 0;
    -webkit-text-stroke-width: 0.2px;
}

.basHeadqDetails h1{
    color: var(--Neutral-Charcoal-Black, #1D1D1B);
    font-family: Uniform;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
    -webkit-text-stroke-width: 0.5px;
    overflow-wrap: anywhere;
}