.emisRatSection{
    display: flex;
    width: calc(25% - 10.6px);
    box-sizing: border-box;
    min-height: 320px;
    align-items: flex-start;
    gap: 24px;
    border-radius:  8px;
    background:  #FFF;
    box-shadow: 0px 4px 40px 0px #E8E8E8;
    flex-direction: column;
    justify-content: space-between;
}

.emisRatInner{
    padding: 9px 0px 0px 9px;
}

.emisRatHeading{
    color: var(--neutral-charcoal-black-200, #979796);
    font-family: Uniform;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    margin: 0;
}

.emisRatInner h3{
    color: var(--neutral-charcoal-black, #1D1D1B);
    font-family: Uniform;
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 1.92px;
    margin: 0;
    -webkit-text-stroke-width: 2px;
}

.validValue{
    color: var(--neutral-charcoal-black, #1D1D1B);
    font-family: Uniform;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.8px;
    margin: 0;
    margin-top: 12px;
}

.emisRatImg{
    width: 100%;
}

.emisRatImg img{
    width: 100%;
    display: flex;
    align-items: flex-end;
}



