.userProgressCard{
   height: inherit;
   display: flex;
   flex-direction: column;
   gap: 12px;
   border-radius: 8px;
   padding: 16px 24px; 
   background: var(--Neutral-Pure-White, #FFF);
   box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.08);
}

.chartLayoutSection{
   height: inherit;
    display: flex;
    flex-direction: column;
    gap: 12px;
    border-radius: 8px;
    background: var(--Neutral-Pure-White, #FFF);
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.08);
}

.chartLayoutTextSec{
   padding: 16px 24px; 
}

 .cardTypeIcon{
    width: 40px;
    height: auto;
 }

.userProgressCardCol {
    display: flex;
    flex-direction: column;
    gap: 8px;
}
 .userProgressCardCol span{
    color: var(--neutral-charcoal-black, #979796);
    text-align: left;
    font-family: Uniform;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 13px;
    margin: 0;
    -webkit-text-stroke-width: 0.2px;
 }

 .usersValueRow{
    display: flex;
    width: -webkit-fill-available;
    justify-content: space-between;
 }

 .usersValueRow h1{
    color: var(--neutral-charcoal-black, #1D1D1B);
    text-align: left;
    font-family: Uniform;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 37px;
    margin: 0;
    -webkit-text-stroke-width: 1px;
 }

 .progressUpDownRow{
    display: flex;
    gap: 2px;
    align-items: center;
 }

 .progressUpDownRow h3{
    color: var(--neutral-charcoal-black, #2BA84A);
    text-align: left;
    font-family: Uniform;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    margin: 0;
    -webkit-text-stroke-width: 0.2px;
 }

 .totalEmissDeclareRow{
    display: flex;
    width: -webkit-fill-available;
    justify-content: space-between;
 }

 .totalEmissDeclareRow span{
   color: var(--neutral-charcoal-black, #979796);
   text-align: left;
   font-family: Uniform;
   font-size: 12px;
   font-style: normal;
   font-weight: 400;
   line-height: 13px;
   margin: 0;
   -webkit-text-stroke-width: 0.2px;
}

 .totalEmissDeclareRow h1{
   color: var(--neutral-charcoal-black, #1D1D1B);
   text-align: left;
   font-family: Uniform;
   font-size: 48px;
   font-style: normal;
   font-weight: 400;
   line-height: 37px;
   margin: 0;
   -webkit-text-stroke-width: 1px;
 }

 .totalEmissDeclareRow p{
   color: var(--neutral-charcoal-black, #1D1D1B);
   text-align: left;
   font-family: Uniform;
   font-size: 20px;
   font-style: normal;
   font-weight: 400;
   line-height: 20px;
   margin: 0;
   -webkit-text-stroke-width: 0.1px;
 }

 .totalEmissDeclare{
    display: flex;
    flex-direction: column;
    gap: 10px;
 }

 .totalEmissDeclareCol{
   display: flex;
   flex-direction: column;
   gap: 10px;
 }

 .userProgressCard span{
   color: var(--neutral-charcoal-black, #979796);
   text-align: left;
   font-family: Uniform;
   font-size: 12px;
   font-style: normal;
   font-weight: 400;
   line-height: 13px;
   margin: 0;
   -webkit-text-stroke-width: 0.2px;
}

 .netEmissionSection{
   display: flex;
   justify-content: space-between;
   gap: 8px;
 }

 .companyDataRow{
   display: flex;
   align-items: center;
   gap: 5px;
 }

 .companyDataRow p{
   color: var(--neutral-charcoal-black, #1D1D1B);
   text-align: left;
   font-family: Uniform;
   font-size: 12px;
   font-style: normal;
   font-weight: 400;
   line-height: 13px;
   margin: 0;
   -webkit-text-stroke-width: 0.2px;
 }

 .netEmissionSection h1{
   color: var(--neutral-charcoal-black, #1D1D1B);
   text-align: left;
   font-family: Uniform;
   font-size: 12px;
   font-style: normal;
   font-weight: 400;
   line-height: 13px;
   margin: 0;
   -webkit-text-stroke-width: 0.5px;
 }

 .netEmissTitle{
   color: var(--neutral-charcoal-black, #686866);
   text-align: left;
   font-family: Uniform;
   font-size: 11px;
   font-style: normal;
   font-weight: 400;
   line-height: 13px;
   margin: 0;
   -webkit-text-stroke-width: 0.2px;
 }

 .reprtedCardCon{
    min-width: 16%;
    width: 214px;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 12px;
    border-radius: 8px;
    padding: 16px 24px; 
    background: var(--Neutral-Pure-White, #FFF);
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.08);
    width: calc(50% - 16px);
    box-sizing: border-box;
 }

 .reportingCardIcon{
    width: 48px;
    height: auto;
 }

 .usersValueRow  .reportingValue{
  font-size: 48px;
 }

 .totalEmissDeclareRow .selectProfileCon .MuiSelect-select {
   color: var(--neutral-charcoal-black, #1D1D1B) !important;
   text-overflow: ellipsis;
   font-family: Uniform;
   font-size: 14px;
   font-style: normal;
   font-weight: 400;
   line-height: normal;
   -webkit-text-stroke-width: 0.2px;
   margin: 0;
   width: 120px;
   border-radius: 5px;
   border: 1px solid #B9B9B8;
   padding: 8px 10px;
}

.totalEmissDeclareRow .selectProfileCon .css-pqjvzy-MuiSvgIcon-root-MuiSelect-icon {
   color: #1D1D1B !important;
}
