.infoScopOneSec{
    width: -webkit-fill-available;
    display: flex;
    padding: var(--L, 16px);
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    gap: var(--M, 12px);
    align-self: stretch;
    border-radius: var(--CornerRadius-CornerRadius_Small, 4px);
    background: #FAFAFA;
}

.scope1Row{
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
}

.camical_list{
    display: flex;
    flex-direction: row;
    gap: 1%;
    justify-content: space-between;
    margin: 8px 0 12px;
    width: 100%;
}

.camical_list div{
    display: flex;
    gap: 5px;
    margin: 0;
    width: 100%;
    padding: 10px 8px;
    flex-direction: column;
}

.camical_list div p{
    margin: 0;
}

.emScopePart1Ques{
    width: 35%;
}

.scope1Row .emScopePart1Ques{
    color: var(--Primary-Aqua-Blue-900, #003A4B) !important;
    font-family: Uniform;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
    -webkit-text-stroke-width: 0.5px;
}

.info1GasRow{
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 12px;
}

.info1GasCol1{
    width: 80%;
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.info1GasData{
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius:4px;
    background: #F0F0F0; 
    padding: 5px;
    gap: 12px;
}

.info1GasCol2{
    width: 20%;
    height: max-content;
    display: flex;
    padding: 8px;
    flex-direction: column;
    align-items: flex-start;
    gap: 2px;
    border-radius: 4px;
    background: #BDE4C7;
}

.info1GasRow .info1GasData h1{
    color: var(--Neutral-Charcoal-Black-500, #1D1D1B);
    font-family: Uniform;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-transform: uppercase;
    margin: 0;
    -webkit-text-stroke-width: 0.5px;
}

.info1GasData p{
    color: var(--Neutral-Charcoal-Black-500, #1D1D1B);
    font-family: Uniform;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
    -webkit-text-stroke-width: 0.3px;
}

.info1GasRow .info1GasCol2 h1{
    color: var(--Neutral-Charcoal-Black-500, #1D1D1B);
    font-family: Uniform;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-transform: uppercase;
    margin: 0;
    -webkit-text-stroke-width: 0.5px;
}

.info1GasCol2 p{
    color: var(--Neutral-Charcoal-Black-500, #1D1D1B);
    font-family: Uniform;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
    -webkit-text-stroke-width: 0.3px;
}

.gasBg p{
    font-size: 12px !important;
    color: #1D1D1B;
    font-weight:700 !important;
}

.greenBg{
    background-color: #BDE4C7;
}

.scopeRightBorder{
    display: flex;
    border-right: 1px solid #1D1D1B;
    min-height: auto;
    opacity: 0.1;
 }
