.chatBoxFootContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 16px;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  border-top: 1px solid #e8e8e8;
  background: #fff;
}

.chatBoxSubmitBtnRow {
  display: flex;
  flex-direction: row;
  width: -webkit-fill-available;
  gap: 20px;
  align-items: center;
}

.footerMenuRow p {
  color: #1d1d1b;
  font-family: Uniform;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin: 0;
  -webkit-text-stroke-width: 0.1px;
}
