
.userReqForm{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: -webkit-fill-available;
}

.userReqFormCol2{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 20px;
}

.userReqInputBox{
    width: 50%;
}

.userSearchBox{
    position: relative;
}

.userSearchIcon{
    position: absolute;
    bottom: 0;
    top: 18px;
    left: 10px;
}

.userSearchBox .input-item input {
    padding: 0 12px 0 28px;
}