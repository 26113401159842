.emisStep2Container {
  background: linear-gradient(175deg, #00141a -18.79%, #003a4b 102.02%);
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin: auto;
  width: -webkit-fill-available;
  min-height: 100vh;
  overflow: hidden;
  padding: 16px 8px;
  gap: 20px;
}

.emission2TitleDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 32px;
}

.emis2ButtonDiv {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 40%;
  gap: 20px;
  text-wrap: nowrap;
}

.emis2CancelBtn {
  height: fit-content;
}

.emis2SubmitBtn {
  height: fit-content;
}

.emis2InnerContainer {
  padding: 2rem 4rem;
  display: flex;
  width: -webkit-fill-available;
  gap: 32px;
  border-radius: var(--corner-radius-corner-radius-large, 20px);
  background: #f7f7f7;
  box-shadow: 0px 0px 8px 0px rgba(29, 29, 27, 0.2);
  align-items: stretch;
  align-items: flex-start;
}

.emission2SecCol1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 25%;
}

.emission2SecCol1 h2 {
  color: var(--neutral-pure-white, #fff);
  font-family: Uniform;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
}
.emission2SecCol1 .basicDetails_timeLineContents p {
  font-family: Uniform;
  font-size: 16px;
  font-style: normal;
  font-weight: 100;
  line-height: 24px;
}

.emis2NowNextStep {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  margin-bottom: 1rem;
}

.emis2NowNextStep .nextStep {
  width: 100%;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  margin-bottom: 0.5rem;
}
.emis2NowNextStep .nextStep p {
  color: #1d1d1b;
  font-family: Uniform;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
}

.emis2NowNextStep .emisAudit {
  width: 100%;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
}
.emis2NowNextStep .emisAudit p {
  color: #1d1d1b;
  font-family: Uniform;
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  margin: 0;
}

.emis2leftTitle h1 {
  color: var(--Neutral-Charcoal-Black, #1d1d1b);
  font-family: Uniform;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  margin: 0;
  -webkit-text-stroke-width: 1px;
}

.emission2SecCol2 {
  padding: 24px;
  align-items: flex-start;
  width: 75%;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  gap: 40px;
  flex: 1 0 0;
}

.Emiss2CodewaveTitleDiv {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.emis2CodeCol1 {
  width: 65%;
  display: flex;
}

.emis2CodeCol1 img {
  width: 15%;
  margin-right: 1rem;
}

.emis2CodeCol1 h3 {
  color: #003a4b;
  font-size: 22px;
  font-weight: 600;
  margin: 0;
}

.emiss2InventoryYear {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.emiss2InventoryYear p {
  margin: 0;
  color: #2ba84a;
  font-weight: 700;
}

.emiss2InventoryYear h3 {
  margin-bottom: 0.5rem;
}

.emis2CodwaveLogo {
  display: flex;
  flex-direction: row;
  margin-bottom: 12px;
}

.emiss2InnerMainBlock {
  display: flex;
  padding: 0;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
}
