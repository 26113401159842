.profileBasicInfoCon{
    width: -webkit-fill-available;
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 24px;
    border-radius: 8px;
    background: var(--Neutral-Pure-White, #FFF);
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.08);
}

.basicInfoSection{
    width: -webkit-fill-available;
    display: flex;
    flex-direction: row;
    gap: 32px;
    flex-wrap: wrap;
}

.basicInfoSecData{
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 31%;
}

.profileBasicInfoCon h1{
    color: var(--Primary-Aqua-Blue-300, #003A4B);
    font-family: Uniform;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
    -webkit-text-stroke-width: 0.5px;
}

.basicInfoSecData span{
    color: var(--Primary-Aqua-Blue-300, #686866);
    font-family: Uniform;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    margin: 0;
    -webkit-text-stroke-width: 0.2px;
}

.basicInfoSecData p{
    color: var(--Primary-Aqua-Blue-300, #1D1D1B);
    font-family: Uniform;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
    -webkit-text-stroke-width: 0.3px;
}