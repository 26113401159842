
.logCredsSection {
  background: linear-gradient(175deg,#00141a -18.79%,#003a4b 102.02%);
  display: flex;
  flex-direction: row;
  align-items: stretch;
  margin: auto;
  width: -webkit-fill-available; 
  min-height: 100vh;
  overflow: hidden; 
}

.logCredsCol1 {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 25% ;
  padding: 4% 2% 0% 2%;
  align-items: center;
}

.logCredsCol2 {
  background-color: #F7F7F7;
  padding: 120px 120px 246px 120px;
  width: 75%;
  display: flex;
  flex-direction: column;
}

.logCreds_TopBg{
  height: 19rem;
  position: absolute;
  right: -181px;
  top: 0%;
  opacity: 0.2;
  z-index: 1;
 }
.logCreds_bottomBg{
  height: 9rem;
  position: absolute;
  right: 0px;
  bottom: 40%;
  opacity: 0.2;
 }

.logCreds_leftBg{
  height: 6rem;
  position: absolute;
  left: 0%;
  top: 28%;
  opacity: 0.2;
 }

 .logCreds_timeLineContents{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.logCredsCol1 .logCreds_timeLineContents p{
  font-family: Uniform;
  font-size: 16px;
  font-style: normal;
  font-weight: 100;
  line-height: 24px ;
 }

.logCreds_logoDiv{
  height: 8vh;
}

.logCreds_logoDiv img {
  width: 112.678px;
  height: 44px;
}

 .logCredsCol2 p {
  color: var(--neutral-charcoal-black, #1D1D1B);
  font-family: Uniform;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin: 0;
  -webkit-text-stroke-width: 0.2px;
  display: contents;
}

.logCredsCol2 h1 {
  color: var(--neutral-charcoal-black, #1D1D1B);
  font-family: Uniform;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: left;
  margin: 0 0 0px 0;
  -webkit-text-stroke-width: 1px;
}

.logCredsCol1 h2{
  color: var(--neutral-pure-white, #FFF);
  font-family: Uniform;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
}

.logCredsCol1 p{
  color: var(--neutral-pure-white, #FFF);
  font-family: Uniform;
  font-size: 14px;
  font-style: normal;
  font-weight: 100;
  line-height: 24px ;
} 

.logCredsForm{
  margin-top: 1rem;
  z-index: 3;
}

.createPassText{
  color: var(--neutral-charcoal-black, #1D1D1B);
  font-family: Uniform;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}


.logCreds_fullname{
  margin-bottom: 1rem;
  width: 33%;
 }

 .logCreds_password{
  position: relative;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row;
  gap: 20px;

}

.passCol1{
  width: 33%;
}

.passCol2{
  width: 33%;
}

.passCriteriaSec{
  display: flex;
  width: 796px;
  padding: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--corner-radius-corner-radius-medium, 8px);
  flex-shrink: 0;
  border-radius: 8px;
  background: #FFF;
  box-shadow: 0 0 8px 0 rgb(245 245 245);
}

.passCriteriaSec .criteriaHeading{
  color: var(--neutral-charcoal-black, #1D1D1B);
  font-family: Uniform;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  -webkit-text-stroke-width: 0.6px;
}

.passCriteriaRuls{
  color: var(--neutral-charcoal-black, #1D1D1B);
  font-family: Uniform;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.titleRow{
  margin-bottom: 1rem;
}

.removeSec_row{
  display: flex;
  flex-direction: row;
  align-items: center !important;
}

.cancelBtn_Row{
  display: flex;
  justify-content: flex-end;
}

.NextBtn{
  display: contents;
}

.logCred_BtnRow{
  display:flex;
  justify-content: space-between;
  margin-top: 2rem;
}
     
    
    
     