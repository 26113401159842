.timelineChartSec .apexcharts-menu-icon{
    display: none !important;
}

.timelineChartSec .apexcharts-legend-series{
    width: 31% !important;
}

.timelineChartSec .apx-legend-position-top{
   position: absolute;
   left: 15px !important;
   width: 600px !important;
   top: 70px !important;
}

.apexcharts-grid-borders {
    display: none !important;
}

.timelineChartSec #SvgjsLine1414{
    stroke: none !important;
}

.timelineChartSec #SvgjsG1254{
    /* margin-top: -220px !important; */
}

@media screen and (min-width: 200px) and (max-width: 1642px) {
    .timelineChartSec #SvgjsLine1413{
        stroke: none !important;
    }
    
    .timelineChartSec #SvgjsLine1414{
        stroke: none !important;
    }
}