.chatBoxFootContainer{
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 16px;
    align-items: center;
    gap: 12px;
    align-self: stretch;
    border-top: 1px solid #E8E8E8;
    background: #FFF;
    justify-content: flex-end;
    position: fixed !important;
    bottom: 0;
    width: -webkit-fill-available;
}

.chatBoxSubmitBtnRow{
    display: flex;
    flex-direction: row;
    width: -webkit-fill-available;
    gap: 20px;
    align-items: center;
}

.footerMenuRow p{
    color: #1D1D1B;
    font-family: Uniform;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
    -webkit-text-stroke-width: 0.1px;
  }
