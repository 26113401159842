.imgUploadCon{
    width: fit-content;
    display: flex;
    gap: 4px;
    padding: 8px;
    border: 1px solid #1D1D1B;
    border-radius: 4px;
    align-items: center;
}

.imgUploadCon img{
    width: 22px;
    height: auto;
}

.imgUploadCon p{
    color: var(--neutral-pure-white, #1D1D1B);
    text-align: center;
    font-family: Uniform;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    list-style: none;
    margin: 0;
    -webkit-text-stroke-width: 0.5px;
}