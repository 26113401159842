.adminForm {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: -webkit-fill-available;
}

.adminFormCol2 {
  display: flex;
  flex-direction: row;
  gap: 15px;
}

.adminInputBox {
  width: 100%;
}

.adminSearchBox {
  position: relative;
  margin-right: 210px;
}

.searchIcon {
  position: absolute;
  bottom: 0;
  top: 18px;
  left: 10px;
}

.adminDateInput {
  width: 100%;
  height: 10px;
}

.adminSearchBox .input-item input {
  padding: 0 12px 0 28px;
}


@media screen and (max-width: 1439px) {
  .adminForm{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}
