.sendByRow{
    display: flex;
    flex-direction: row;
}

.sendByRow{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
}

.sendByRow p{
    color: var(--Neutral-Charcoal-Black-400, #4A4A49);
    font-family: Uniform;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
    -webkit-text-stroke-width: 0.2px;
}
p.sendByTime{
    color: var(--Neutral-Charcoal-Black-200, #979796);
    font-family: Uniform;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
}

.cmtHeaderRightBorder {
    display: flex;
    border-right: 1px solid #1D1D1B;
    height: 2vh;
    opacity: 0.2;
}