.calendarLabel {
  color: #686866;
  font-family: Uniform;
  font-size: 12px !important;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: left;
}
.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 12px 12px !important;
  height: 1.1rem !important;
  padding-right: 0 !important;
  padding-left: 12px !important;
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}

.css-4jnixx-MuiStack-root {
  padding-top: 5px !important;
}
.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border-radius: 4px !important;
  border: none !important;
  padding: 16px 20px !important;
  margin-top: 5px !important;
  outline: none;
}
.css-11a8txn-MuiStack-root > :not(style) ~ :not(style) {
  margin-top: 0px !important;
}
.datepickerIcon {
  display: none !important;
}
.css-1d3z3hw-MuiOutlinedInput-notchedOutline
  .custom-datepicker-icon
  .specific-icon-class {
  display: none !important;
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 8px 14px !important;
}
/* .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline{ */
.css-1bn53lx.Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: transparent !important;
}

.css-1uvydh2 {
  padding: 9px 9px !important;
}

/* .customInputCalender {
  border: 2px solid #b9b9b8 !important;
  border-radius: 4px !important;
  margin-top: 4px !important;
  width: 100%;
} */

.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root input::placeholder {
  color: red !important;
  /* color: var(--neutral-charcoal-black, #1D1D1B); */
  font-family: Uniform;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  opacity: 0.2;

  -webkit-text-stroke-width: 0.2px;
}

.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
  padding-right: 0px !important;
}

.css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root {
  margin-right: 0 !important;
}

.css-78trlr-MuiButtonBase-root-MuiIconButton-root {
  padding: 0 !important;
}

.css-78trlr-MuiButtonBase-root-MuiIconButton-root:hover {
}

/* .customInputCalender:focus {
  border: 2px solid #008bb2;
  box-shadow: 0 0 8px 0 rgba(138, 202, 220, 1);
}

.customInputCalender.error {
  border-radius: 4px !important;
  border: 2px solid #f45b69 !important;
  box-shadow: 0 0 4px 0 rgba(244, 91, 105, 0.5) !important;
} */

/* .react-datepicker__view-calendar-icon input {
  padding: 13px 25px 13px 40px !important;
}

.react-datepicker__input-container .react-datepicker__calendar-icon {
  position: absolute !important;
  padding: 0.5rem !important;
  box-sizing: content-box !important;
  margin-top: 10px !important;
} */

.react-datepicker-wrapper {
  margin-top: 4px;
}

.react-datepicker__view-calendar-icon input {
  border: 2px solid #b9b9b8;
  border-radius: 4px;
  padding: 14px 10px 14px 12px !important;
  width: 190px;
}

.react-datepicker__input-container .react-datepicker__calendar-icon {
  right: 0px;
  top: 6px;
}

.react-datepicker__close-icon {
  right: 23px !important;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  margin: 0.3rem !important;
}
