
.paginationContainer{
    display: flex;
    padding: 10px 12px;
    justify-content: space-between;
    align-items: center;
    border-radius: 4px;
    background: #EEE;
}

.paginationCol1{
    display: flex;
    align-items: center;
    gap: 16px;
    align-self: stretch;
}

.paginationCol2{
    display: flex;
    align-items: center;
    gap: 8px;
}

.paginationCol1 p{
    color: var(--Neutral-Charcoal-Black-500, #1D1D1B);
    font-family: Uniform;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
    -webkit-text-stroke-width: 0.2px;
}

.paginationCol1 h1{
    color: var(--Neutral-Charcoal-Black-500, #1D1D1B);
    font-family: Uniform;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
    -webkit-text-stroke-width: 0.2px;
}

.paginationCol1 .rightBorder {
    display: flex;
    border-right: 2px solid #1D1D1B;
    height: 8vh;
    margin-left: 0;
    margin-right: 0;
    opacity: 0.2;
}

.paginationCol2 p{
    color: var(--Neutral-Charcoal-Black-500, #1D1D1B);
    font-family: Uniform;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
    -webkit-text-stroke-width: 0.2px;
}

.paginationCol2 .css-194a1fa-MuiSelect-select-MuiInputBase-input {
    border: 1px solid gray;
    border-radius: 3px;
}