.chatCommentBox{
    width: -webkit-fill-available;
    display: flex;
    height: 30px;
    padding: 8px;
    flex-direction: column;
    gap: var(--M, 12px);
    border-radius: 4px;
    border: 0.5px solid var(--Neutral-Charcoal-Black-100, #B9B9B8);
    background: var(--Neutral-Pure-White, #FFF);
    outline: none;
}

.chatCommentBox:focus {
    border: 2px solid #008BB2;
    box-shadow: 0 0 8px 0 rgba(138, 202, 220, 1);
  }

  .chatBoxFootContainer .chatCommentBox::placeholder{
    font-family: Uniform;
    color: #1D1D1B;
    opacity: 20%;
    font-size: 12px;
    font-weight: 600;
  }