
    /* Get In Touch Section */

    .input-item {
        margin-bottom: 28px;
        text-align: left;
       }
       .input_label {
        color: #686866;
        font-family: Uniform;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 10px;
        text-align: left;
      }
      .input-item input {
        width: 95%;
      }
      .phoneNumber_input{
        width: 100%;
      }
      .submitBtn{
        cursor: pointer;
        width: 18%;
        border-radius: 4px;
        background: #00637E;
        box-shadow: 0px 2px 4px 0px rgba(0, 139, 178, 0.12);
       }
       .custom-input::placeholder {
         opacity: 0.2;
        }
       .submitBtn p{
          padding: 12px 10px;
          font-size: 15px;
          font-weight: 500;
          color: #ffffff;
          text-align: center ! important;
       }
       .getTouchTop_sec{
        background: #F7F7F7;
       }
      .getTouch_section {
        padding-top: 80px;
        padding-bottom: 80px;
        display: flex;
        align-items: flex-start;
      }
      .getTouchSec_Col1 {
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding-right: 100px;
        gap: 32px;
      }
     .getTouchSec_Col2{
       width: 50%;
      }
      .getTouchSec_Col1 h1{
        color: var(--neutral-charcoal-black, #1D1D1B);
        font-family: Woodman-HeavyRounded;
        font-size: 40px;
        font-style: normal;
        font-weight: 400;
        line-height: 56px;
        margin: 0px;
        width: 70%;
       }
      .getTouch_section p{
        color: var(--neutral-charcoal-black, #1D1D1B);
        margin: 0;
        font-family: Uniform;
        font-size: 16px;
        font-style: normal;      
        font-weight: 400;
        line-height: 32px; 
        text-align: left;
        -webkit-text-stroke-width: 0.2px;
      }
      .getInTochAlert{
        margin-top: 1.5rem;
        margin-bottom: 1rem;
       }
       .getInTochAlert p {
        font-size: 15px;
        margin:0;
        color: red;
       }

  /* Screen For 1680*1050 */
    @media screen and (min-width: 1621px) and (max-width: 1820px) {
        .input-item input{
            width: 94%;
        }
     }

    /* Screen For 1440*900 */
    @media screen and (min-width: 1281px) and (max-width: 1620px) { 
        .input-item input{
            width: 93% !important;
        }
          .input-item textarea {
            width: 93%;
          }
    }

    /* Screen For 1280*1024 */
    @media screen and (max-width: 1280px) {
        .getTouchSec_Col1 h1 {
            text-align: left
          }
          .input-item input{
            width: 93% !important;
         }
    }

     /* Screen For 1024*600 */
     @media screen and (min-width: 992px) and (max-width: 1242px) {
       .container {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: auto;
            width: 90%;
        }
        .input-item textarea {
            width: 93% !important;
        }
    }

    @media screen and (min-width: 768px) and (max-width: 992px) {
        .getTouch_section{
            display: flex;
            flex-direction: column !important;
            align-items: flex-start !important;
        }
        .input-item input {
            width: 87% !important;
        }
        .input-item textarea {
            width: 87% !important;
        }
        .getTouchSec_Col1 {
            width: 80%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding-right: 100px;
        }
        .getTouchSec_Col2 {
            width: 100%;
        }
        .submitBtn {
            width: 40%;
            border-radius: 4px;
            background: #00637E;
            box-shadow: 0px 2px 4px 0px rgba(0, 139, 178, 0.12);
        }
    }

    @media screen and (max-width: 767px) {
        .getTouch_section{
            display: flex;
            flex-direction: column !important;
            align-items: flex-start !important;
        }
        .getTouchSec_Col1 {
            width: 85%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding-right: 100px;
        }
        .getTouchSec_Col2 {
            width: 100%;
        }
        .getTouchSec_Col1 h1 {
            font-size: 37px;
            font-style: normal;
            line-height: 56px;
            margin: 0px;
        }
        .submitBtn {
            width: 40%;
            border-radius: 4px;
            background: #00637E;
            box-shadow: 0px 2px 4px 0px rgba(0, 139, 178, 0.12);
        }  
    
    }

    @media screen and (max-width: 575px) {
        body {
            display: block;
            margin: 0px;
        }
        /* contact us */
        .getTouch_section{
            width: 90%;
            padding-top: 40px;
            padding-bottom: 40px;
            display: flex;
        }
        .getTouchSec_Col1 {
            width: 85%;
            padding-right: 0px;
            padding-left: 0px;
        }
        .getTouchSec_Col2 {
            width: 100%;
        }
        .getTouchSec_Col1 h1 {
            font-size: 23px;
            line-height: 42px;
            margin-bottom: 20px;
            width: 100%;
        }
        .getTouchSec_Col1 p{
            display: none;
        }
        .input-item input {
            border-radius: 4px;
            border: 1px solid #B9B9B8;
            padding: 16px 20px;
            width: 85% !important;
            margin-top: 5px;
        }
        .input-item textarea {
            border-radius: 4px !important;
            border: 2px solid #B9B9B8!important;
            padding: 10px 20px!important;
            width: 85%!important;
            margin-top: 5px!important;
            resize: none;
            overflow: auto;
        }
        .submitBtn {
          width: 30%;
        }
        .submitBtn p {
          padding: 5px 10px;
        }
    
    }

    /* ----------------------------------------------400----------------------------------------- */
    
    @media (min-width: 300px) and (max-width: 400px) {
        body {
            display: block;
            margin: 0px;
        }
       
        /* contact us */
        .getTouch_section{
            width: 90%;
            padding-top: 40px;
            padding-bottom: 40px;
        }
        .getTouchSec_Col1 {
            width: 100%;
            padding-right: 0px;
            padding-left: 0px;
        }
        .getTouchSec_Col2 {
            width: 100%;
        }
        .getTouchSec_Col1 h1 {
            font-size: 23px;
            line-height: 42px;
            margin-bottom: 20px;
            width: 100%;
        }
        .getTouchSec_Col1 p{
            display: none;
        }
        .input-item input {
            border-radius: 4px;
            border: 1px solid #B9B9B8;
            padding: 16px 20px;
            width: 80%;
            margin-top: 5px;
        }
        .input-item textarea {
            border-radius: 4px !important;
            border: 2px solid #B9B9B8!important;
            padding: 10px 20px!important;
            width: 84%!important;
            margin-top: 5px!important;
            resize: none;
       }
       .containerPhoneCountryCode{
            display: flex;
            flex-wrap: wrap;
       }
    
    }
    
    