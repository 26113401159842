
.uploadBox{
    border: 2px dashed #008BB2;
    display: flex;
    padding: 40px 305px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    background: #F7FDFF;
    white-space: nowrap;
}

.pdfmt{
  margin-top: 25px;
  margin-bottom: 15px;
}

.uploadBox_innerdiv{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.uploadBox_innerdiv p{
  color: #979796;
  font-family: Uniform;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.uploadBox_innerdiv img{
  width: 12%;
  height: auto;
}

.pdfUploadRow{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px 25px;
}

.fileInputError {
  font-size: 1rem !important;
  color: #F45B69 !important;
  border: 2px solid #F45B69;
}