.auditChatInfo2ScopesCon{
    display: flex;
    padding: var(--M, 12px);
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    gap: var(--M, 12px);
    align-self: stretch;
    border-radius: 4px;
    border: 1px solid #E8E8E8;
    background: #FAFAFA;
}

.auditChatInfo2ScopesCon .AudInfo2ScopeTitle{
    color: var(--Primary-Aqua-Blue-900, #003A4B);
    font-family: Uniform;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
    -webkit-text-stroke-width: 0.5px;
}

.auditInfo2ScopesValue{
    display: flex;
    flex-direction: row;
    gap: 12px;
    flex-wrap: wrap;
    width: -webkit-fill-available;
}



