.rateRegisOnCon {
    padding: 24px;
    align-items: flex-start;
    display: flex;
    gap: 20px;
    flex-direction: column;
    border-radius: 8px;
    border: 1px solid #E8E8E8;
}

.rateRegisOnRow{
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.rateRegisOnRow span {
    color: var(--Primary-Aqua-Blue-300, #686866);
    font-family: Uniform;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    margin: 0;
    -webkit-text-stroke-width: 0.2px;
}

.rateRegisOnRow p {
    color: var(--Primary-Aqua-Blue-300, #1D1D1B);
    font-family: Uniform;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
    -webkit-text-stroke-width: 0.3px;
}

.rateUserInvolveCon{
    padding: 24px;
    background-color: #fff;
    align-items: flex-start;
    display: flex;
    gap: 24px;
    flex-direction: column;
    border-radius: 8px;
    background: var(--gradient-light-green, linear-gradient(122deg, #EAF6ED 6.8%, #BDE4C7 100.83%));
    border: 1px solid #E8E8E8;
}


.rateUserInvTitle{
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.rateUserInvTitle h1{
    color: #003A4B;
    font-family: Uniform;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    margin: 0;
    -webkit-text-stroke-width: 0.2px;
}

.rateUserInvTitle p{
    color: #1D1D1B;
    font-family: Uniform;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
    -webkit-text-stroke-width: 0.2px;
}

.ratePeopleInvList{
    display: flex;
    flex-direction: row;
    gap: 16px;
    align-items: center;
    width: -webkit-fill-available;
    justify-content: space-between;
}

.ratePeopleInvCol1{
    display: flex;
    gap: 12px;
    width: 50%;
}
.ratePeopleInvCol2{
    display: flex;
    width: 50%;
}

.ratePeopleInvProf{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
}

.ratePeopleInvProf img{
    height: 36px;
    border: 1px solid #008BB2;
    border-radius: 20px;
}

.nameWithDesignation{
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.nameWithDesignation h1{
    color: #1D1D1B;
    font-family: Uniform;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
    -webkit-text-stroke-width: 0.2px;
}

.nameWithDesignation p{
    color: #1D1D1B;
    font-family: Uniform;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-transform: uppercase;
    margin: 0;
    -webkit-text-stroke-width: 0.2px;
}

.rateEditRow{
    display: flex;
    width: 50%;
}

.rateEditRow h1{
    color: #1D1D1B;
    font-family: Uniform;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
    -webkit-text-stroke-width: 0.2px;
}

.rateEditRow{
    cursor: pointer;
    display: flex;
    gap: 4px;
}

.rateEditRow img{
    width: 15px;
    height: auto;
}


