
.regCancelBtn{
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    margin-right: 2rem;
    padding: 12px;
    gap: 4px;
    background-color: transparent;
   }
   .regCancelBtn div{
    color: var(--Neutral-Charcoal-Black, #1D1D1B);
    text-align: center;
    font-family: Uniform;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
   }
   .regCancelBtn p{
    color: var(--neutral-charcoal-black, #1D1D1B);
    text-align: center;
    font-family: Uniform;
    font-size: 14px;
    font-style: normal;
    line-height: normal;
   }
   .regCancelBtn:hover {
    border-radius: 4px;
    border: 1px solid  #008BB2;
    background: var(--primary-aqua-blue-50, #E6F3F7);
   }