.myProEditLoginCon{
    overflow: au;
    width: -webkit-fill-available;
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 24px;
    border-radius: 8px;
    background: var(--Neutral-Pure-White, #FFF);
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.08);
}

.myProEditLogSec{
    width: -webkit-fill-available;
}

.myProEditLoginCon h1{
    color: var(--Primary-Aqua-Blue-300, #003A4B);
    font-family: Uniform;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
    -webkit-text-stroke-width: 0.5px;
}

.proEditLogRow {
    width: -webkit-fill-available;
    display: flex;
    flex-direction: column;
    gap: 32px;
}

.proEditLogCol1{
    display: flex;
    flex-wrap: wrap;
    gap: 32px;
}

.proEditLogCol1 .passInput{
    width: 31.7%;
}

.editPassRule {
    display: flex;
    width: -webkit-fill-available;
    padding: 12px;
    flex-direction: column;
    gap: 8px;
    background: #FAFAFA;
}

.editPassRule .editPassRuleTitle{
    color: var(--Primary-Aqua-Blue-300, #1D1D1B);
    font-family: Uniform;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
    -webkit-text-stroke-width: 0.5px;
}

.editPassCriteria{
    color: var(--neutral-charcoal-black, #1D1D1B);
    font-family: Uniform;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    display: flex;
    flex-direction: row;
    gap: 5px;
}


.editPassCriteria p{
    color: var(--Primary-Aqua-Blue-300, #1D1D1B);
    font-family: Uniform;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
    -webkit-text-stroke-width: 0.2px;
}