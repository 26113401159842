

.orgDetails_section {
  background: linear-gradient(175deg,#00141a -18.79%,#003a4b 102.02%);
  display: flex;
  flex-direction: row;
  align-items: stretch;
  margin: auto;
  width: -webkit-fill-available; 
  min-height: 100vh;
  overflow: hidden; 
  }

  .orgDetailsSec_Col1 {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 25% ;
    /* padding: 4% 5% 0% 5%; */
    padding: 4% 2% 0% 2%;
    align-items: center;
  }
  .orgDetailsSec_Col2 {
    background-color: #F7F7F7;
    padding: 8% 8% 8% 8%;
    width: 75%;
    display: flex;
    flex-direction: column;
  }
  .orCityDiv{
    width: 33.5%;
  }

  .headSections{
    display: flex;
    gap: 2%;
  }

  .titleRow{
    margin-bottom: 1rem;
  }
  
  .headQuarter_section{
    border-radius: 8px;
    background: #fff;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 10px;
  }
  .mb_1r{
    margin-bottom: 1rem;
  }
  
  .addBranch_div{
    display: flex;
    padding: 0rem 1rem;
    margin-top: 10px ;
  }   
  
  .orgDetailsSec_Col1 h2{
    color: var(--neutral-pure-white, #FFF);
    font-family: Uniform;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
  }
  .orgDetailsSec_Col1 p{
    color: var(--neutral-pure-white, #FFF);
    font-family: Uniform;
    font-size: 14px;
    font-style: normal;
    font-weight: 100;
    line-height: 24px ;
  }   
  .orgDetails_logoDiv{
    height: 8vh;
  }
  .orgDetails_logoDiv img {
    width: 112.678px;
    height: 44px;
  }
  .orgDetails_timeLineContents{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .orgDetailsSec_Col1 .orgDetails_timeLineContents p{
    font-family: Uniform;
    font-size: 16px;
    font-style: normal;
    font-weight: 100;
    line-height: 24px ;
   }
  
  
  .orgDetailsSec_Col2 p {
    font-family: Uniform;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
    margin: 0;
  }
 .orgDetailsSec_Col2 h1 {
    font-family: Uniform;
    font-size: 28px;
    font-weight: 900;
    text-align: left;
    margin: 0 0 10px 0;
    -webkit-text-stroke-width: 1px;
    margin-bottom: 30px;
  }
  
  .form_inputHeading p{
    font-family: Uniform;
    font-size: 15px;
    font-style: normal;
    font-weight: 800;
    line-height: 20px;
  }
  
  .orgDetails_topBg{
    height: 19rem;
    position: absolute;
    right: -181px;
    top: 0%;
    opacity: 0.2;
   }
   .orgDetails_bottomBg{
    height: 9rem;
    position: absolute;
    right: 0px;
    bottom: 60%;
    opacity: 0.2;
   }

   .orgDetails_leftBg{
    height: 6rem;
    position: absolute;
    left: 0%;
    top: 20%;
    opacity: 0.2;
   }

  .cancelBtn_Row{
    display: flex;
    justify-content: flex-end;
  }
  .NextBtn{
    display: contents;
  }
  .od_BtnRow{
    display:flex;
    justify-content: space-between;
  }
  
  
  
    /* Screen For 1440*900 */
    /* @media screen and (min-width: 1281px) and (max-width: 1620px) {
      .orgDetails_timeLineContents {
        width: 80%;
      }  
      .orgDetailsSec_Col1 h1{
        font-size: 52px;
      }
      .orgDetailsSec_Col1 h2{
        font-size: 24px;
      }
      .orgDetailsSec_Col1 p{
        font-size: 16px;
      }
  
    } */
  
   /* Screen For 1280*1024 */
   /* @media screen and (max-width: 1280px) {
    
    .orgDetailsSec_Col1 {
      width: 55%;
    }
    .orgDetailsSec_Col2 {
      width: 45%;
    }
    .orgDetails_timeLineContents {
      width: 93%;
    }  
    .orgDetailsSec_Col1 h1{
      font-size: 52px;
    }
    .orgDetailsSec_Col1 h2{
      font-size: 22px;
    }
    .orgDetailsSec_Col1 p{
      font-size: 14px;
    }
    .orgDetailsSec_Col2 p {
      font-family: Uniform;
      font-size: 14px;
    }
  
    } */
     /* Screen For 1024*600 */
    /* @media screen and (min-width: 992px) and (max-width: 1242px) {
  
    } */
  
  
   
  
  
   