
 .emisSection {
    background: linear-gradient(175deg,#00141a -18.79%,#003a4b 102.02%);
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin: auto;
    width: -webkit-fill-available ; 
    min-height: 100vh ;
    overflow: hidden; 
    padding: 16px 8px;
  }

  .emisTitleHeader{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0px 32px 15px 32px;
   }

   .emiDecLogo{
    width: 40%;
   }

   .emisTitleRightDiv{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 12px;
   }
  
  .emisTitleRightDiv p{
    color:#E6F3F7 !important;
    text-align: center;
    font-family: Uniform;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
   }

  .emisCancelBtn{
    height: fit-content;
   }

   .emisSubmitBtn{
    height: fit-content;
   }

   .emis3DraftBtn{
    height: fit-content;
   }
   
   .emisInnerContainer{
    padding: 2rem 4rem;
    display: flex;
    width: -webkit-fill-available;
    gap: 3%;
    align-items: flex-start;
    border-radius: var(--corner-radius-corner-radius-large, 20px);
    background: #F7F7F7;
    box-shadow: 0px 0px 8px 0px rgba(29, 29, 27, 0.20);
  }

  .emisSecCol1{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 25%;
  }

  .roadmapTitle{
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .roadmapTitle h1{
    font-size: 22px;
    font-family: uniform;
    color: #1D1D1B;
    margin: 0px;
  }

  .stepsNext{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
  }

  .stepsNext p{
    font-size: 12px;
    font-family: uniform;
    color: #1D1D1B;
    margin: 0px;
    -webkit-text-stroke-width: 0.2px;
  }
  
  .stepsNext span{
    font-size: 14px;
    font-family: uniform;
    color: #008BB2;
    margin: 0px;
    -webkit-text-stroke-width: 0.3px;
  }

  .netYearRow{
    display: flex;
    flex-direction: row;
    width: -webkit-fill-available;
    justify-content: space-between;
  }

  .stepsCodewaveLogo{
    gap: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .stepsCodewaveLogo h3{
    font-family: Uniform;
    font-size: 22px;
    color: #003A4B;
  }

  .emisSecCol2 {
    padding: 24px;
    align-items: flex-start;
    width: 75%;
    display: flex;
    flex-direction: column;
    display: flex;
    border-radius:8px;
    gap: 40px;
  }

  .emisLeftTitleDiv{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;
    margin-bottom: 1rem;
  }

  .emisLeftTitleDiv .nextStep{
    width: 100%;
    justify-content: space-between;
    display: flex;
    flex-direction: row;
    margin-bottom: 0.5rem;
  }

  .emisLeftTitleDiv .nextStep p{
    color: #1D1D1B ;
    font-family: Uniform;
    font-size: 12px ;
    font-style: normal;
    font-weight: 500 ;
    line-height: normal;
    margin: 0;
  }

  .emisLeftTitleDiv .roadmapSub{
    width: 100%;
    justify-content: space-between;
    display: flex;
    flex-direction: row;
  }

  .emisLeftTitleDiv .roadmapSub p{
    color: #1D1D1B;
    font-family: Uniform;
    font-size: 14px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    margin: 0;
  }

  .checkListSec{
    background: var(--gradient-light-green, linear-gradient(122deg, #EAF6ED 6.8%, #BDE4C7 100.83%));
    display: flex;
    padding: 24px 24px 24px 24px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    align-self: stretch;
    border-radius: 8px;
    margin-top: 16px;
  }

  .checkListTitle{
    display: flex;
    justify-content: space-between;
    width: -webkit-fill-available;
    margin-bottom: 5px;
  }

  .leftBoxTitle {
    color:#003A4B !important;
    font-family: Uniform;
    font-size: 16px !important;
    font-style: normal;
    font-weight: 800 !important;
    line-height: normal;
    margin: 0;
  }

  .ckeckLiPara{
    color: #686866 !important;
    margin: 0 !important;
  }

  .goodPractSec{
    display: flex;
    border: 1px solid #008BB2;
    padding: 24px 24px 24px 24px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    align-self: stretch;
    border-radius: 8px;
    margin-top: 16px;
  }

  .goodPractTitle{
    display: flex;
    margin-bottom: 16px !important;
    justify-content: space-between;
    width: -webkit-fill-available;
  }

   .checkLiDiv{
    margin-top: 16px;
   }

   .checkLists{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
   }

   .checkLists img {
    margin-right: 8px;
    margin-top: 2px;
  }

  .checkLiDiv .checkLists p{
    color: #1D1D1B;
    font-family: Uniform;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin:0;
   }

   .pdfSec {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-bottom: 1rem;
    margin-top: 1.5rem;
  }

   .CodewaveLogoSec img{
     width: 15%;
     margin-right: 1rem;
   }

  .CodewaveLogoSec h3{
   color: #003A4B;
    font-size: 22px;
    font-weight: 600;
    margin: 0;
  }

  .cancelBtn_Row{
    display: flex;
    justify-content: flex-end;
  }
 
 .emissionCopy p{
    color: #FFF;
    font-size: 14px;
    font-weight: 400;
    opacity: 0.4;
  }

  .emis3DraftBtn button{
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 4px;
    border: 1px solid var(--Neutral-Pure-White, #FFF);
  }
