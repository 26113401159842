       /* FrameWork section */
       .frameWork_section {
          display: flex;
          flex-direction: column;
          padding-top: 60px;
          position: relative;
        }
        .frameworkBackground-text {
            bottom: 0;
            font-family: 'Woodman-HeavyRounded';
            font-size: 202px;
            left: -175px;
            line-height: 200px;
            opacity: 0.09;
            pointer-events: none;
            position: absolute;
            right: 0;
            text-align: left;
            top: 44px;
            z-index: -1;
            color: transparent;
            -webkit-text-stroke-width: 1px;
            -webkit-text-stroke-color: black;
        }
          .frameRight_watermark{
            position: absolute;
            right: 0;
            top: 74px;
            margin-right: -5.5%;
          }
          .frameWorkSec_Col1 {
              width: 60%;
          }
          .frameWorkSec_Col2 {
              width: 40%;
          }
          .frameWorkSec_Col1_p{
            margin: 0px;
          }
          .frameWork_section h1{
            color: var(--neutral-charcoal-black, #1D1D1B);
            margin: 0;
            margin-bottom: 32px;
            font-family: Woodman-HeavyRounded;
            font-size: 40px;
            font-weight: 400;
            text-align: justify;
          }
          .frameWork_section p{
            color: var(--neutral-charcoal-black, #1D1D1B);
            margin: 0;
            font-family: Uniform;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            -webkit-text-stroke-width: 0.2px;
          }
          
          .framework_row1{
              width: 100%;
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
          }
          .framework_row2{
              width: 100%;
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
          }
          .framework_Cols{
              width:20%;
          }
          .framework_Cols p{
            font-family: Uniform;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            margin: 0;
            text-align: justify;
          }
          .framework_Cols h2{
              color: #2BA84A;
             font-family: Uniform;
             font-size: 18px;
             font-style: normal;
             font-weight: 800;
             line-height: normal;
             text-align: left;
          }
         .framework_Cols img{
              width: 30px;
          }
          .framework_headSection{
              display: flex;
              flex-direction: row;
              width: 100%;
          }
           .marginBt_1{
              margin-top: 170px;
          }
          .marginBt_2{
              margin-top: 120px;
          }
          .marginBt_3{
              margin-top: 75px;
          } 
          .marginBt_4{
            margin-top: 0px;
          }
          .marginBt_5{
            margin-top: 170px;
        }
        .marginBt_6{
            margin-top: 120px;
        }
        .marginBt_7{
            margin-top: 75px;
        } 
        .marginBt_8{
          margin-top: 0px;
        }
           
        .frameWork_Row{
          width: 100%;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          gap:44px 44px;
          justify-content: flex-start;
          align-items: flex-start;
          padding-top: 40px;
        }
        .frameWork_Cols{
          width: 22%;

        }
        .frameWork_Row .frameWork_Cols h2{
          color: #2BA84A;
          font-family: Uniform;
          font-size: 18px;
          font-style: normal;
          font-weight: 800;
          line-height: normal;
          text-align: left;
          display: flex;
          align-items: center;
        }
        .frameWork_Cols img {
          width: 40px;
          margin-right: 10px;
        }

           /* Screen For 1680*1050 */
    @media screen and (min-width: 1621px) and (max-width: 1820px) {
        .frameWork_Row{
            width: 100%;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            gap:44px 44px;
            justify-content: flex-start;
            align-items: center;
          }
          .frameWork_Cols{
            width: 22%;
        
          }
          .frameWork_Row .frameWork_Cols h2{
            color: #2BA84A;
            font-family: Uniform;
            font-size: 18px;
            font-style: normal;
            font-weight: 800;
            line-height: normal;
            text-align: left;
            display: flex;
            align-items: center;
          }
          .frameworkBackground-text{
            top:67px
          }
    
    }

     /* Screen For 1440*900 */
     @media screen and (min-width: 1281px) and (max-width: 1620px) {
        .frameWork_Row{
            width: 100%;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: flex-start;
            align-items: flex-start;
            padding-top: 40px;
         }
          .frameWork_Cols{
            width: 22%;
        
          }
          .frameWork_Row .frameWork_Cols h2{
            color: #2BA84A;
            font-family: Uniform;
            font-size: 18px;
            font-style: normal;
            font-weight: 800;
            line-height: normal;
            text-align: left;
            display: flex;
            align-items: center;
          }
          .frameworkBackground-text{
            left: -155px;
            font-size: 205px;
          }
          .frameRight_watermark{
            margin-right: -7.5%;
          }
        .marginBt_5{
            margin-top: 61px;
        }
        .marginBt_6{
            margin-top: 22px;
        }
        .marginBt_7{
            margin-top: -15px;
        } 
        .marginBt_8{
          margin-top: -6rem;
        }
        .frameWorkSec_Col1_p{
          width: 80%;
          text-align: left !important;
        }
    }

     /* Screen For 1280*1024 */
     @media screen and (max-width: 1280px) {
        .frameWork_Row{
            width: 100%;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            gap:25px 45px;
            justify-content: flex-start;
            align-items: center;
          }
          .frameWork_Cols{
            width: 21%;
    
          }
          .frameWork_Row .frameWork_Cols h2{
            color: #2BA84A;
            font-family: Uniform;
            font-size: 18px;
            font-style: normal;
            font-weight: 800;
            line-height: normal;
            text-align: left;
            display: flex;
            align-items: center;
          }
          .frameworkBackground-text {
            font-size: 172px;
            top: 45px;
            left: -140px;
          }
          .frameRight_watermark {
            margin-right: -9.5%;
          }
    }

    /* Screen For 1024*600 */
    @media screen and (min-width: 992px) and (max-width: 1242px) {
    
        .container {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: auto;
            width: 90%;
        }
        .frameWork_section {
            padding-top: 60px;
            display: flex !important;
            flex-direction: column ! important;
        }

        .frameworkBackground-text {
            top: 25px;
            left: -80px;
            font-size: 130px;
        }    
        .frameRight_watermark {
          margin-right: -1.5%;
          width: 15%;
        }
        .frameWork_Row{
            width: 100%;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            gap:25px 45px;
            justify-content: flex-start;
            align-items: center;
          }
          .frameWork_Cols{
            width: 21%;
    
          }
          .frameWork_Row .frameWork_Cols h2{
            color: #2BA84A;
            font-family: Uniform;
            font-size: 18px;
            font-style: normal;
            font-weight: 800;
            line-height: normal;
            text-align: left;
            display: flex;
            align-items: center;
          }
    }

    @media screen and (min-width: 768px) and (max-width: 992px) {
        .frameWork_section {
            padding-top: 60px;
            display: flex !important;
            flex-direction: column ! important;
        }
        .frameWork_Row{
            width: 100%;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            gap:25px 45px;
            justify-content: flex-start;
            align-items: center;
          }
          .frameWork_Cols{
            width: 23%;
    
          }
          .frameWork_Row .frameWork_Cols h2{
            color: #2BA84A;
            font-family: Uniform;
            font-size: 18px;
            font-style: normal;
            font-weight: 800;
            line-height: normal;
            text-align: left;
            display: flex;
            align-items: center;
          }
          .frameworkBackground-text {
            left: -53px;
            top: 15px;
            font-size: 100px;
          }
          .frameRight_watermark {
            margin-right: -3.5%;
            width: 20%;
          }

          .framework_row1 {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
        }
        .framework_row2 {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
        }
        .marginBt_1 {
            margin-top: 0px;
        }
        .marginBt_2 {
            margin-top: 0px;
        }
        .marginBt_3 {
            margin-top: 0px;
        }
        .marginBt_4{
          margin-top: 0px;
        }
        .marginBt_5{
          margin-top: 0px;
        }
        .marginBt_6{
          margin-top: 0px;
        }
        .marginBt_7{
          margin-top: 0px;
        }
        .marginBt_8{
          margin-top: 0px;
        }
        .framework_Cols {
            width: 80%;
        }
        .frameWork_Row{
            width: 100%;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            gap:25px 45px;
            justify-content: flex-start;
            align-items: center;
          }
          .frameWork_Cols{
            width: 100%;
    
          }
          .frameWork_Row .frameWork_Cols h2{
            color: #2BA84A;
            font-family: Uniform;
            font-size: 18px;
            font-style: normal;
            font-weight: 800;
            line-height: normal;
            text-align: left;
            display: flex;
            align-items: center;
          }
    
    }

    @media screen and (max-width: 767px) {
      .frameWork_section {
        padding-top: 60px;
        display: flex !important;
        flex-direction: column ! important;
      }
       .frameWork_Row{
            width: 100%;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            gap:25px 45px;
            justify-content: flex-start;
            align-items: center;
          }
          .frameWork_Cols{
            width: 23%;
    
          }
          .frameWorkSec_Col2 {
            width: 0%;
        }
          .frameWork_Row .frameWork_Cols h2{
            color: #2BA84A;
            font-family: Uniform;
            font-size: 18px;
            font-style: normal;
            font-weight: 800;
            line-height: normal;
            text-align: left;
            display: flex;
            align-items: center;
          }
          .frameworkBackground-text {
            left: -48px;
            top: 15px;
            font-size: 90px;
          }
          .frameRight_watermark {
            margin-right: -3.5%;
            width: 20%;
          }
          .frameWorkSec_Col1 {
            width: 100%;
        }

        .framework_row1 {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
      }
      .framework_row2 {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
      }
      .marginBt_1 {
          margin-top: 0px;
      }
      .marginBt_2 {
          margin-top: 0px;
      }
      .marginBt_3 {
          margin-top: 0px;
      }
      .marginBt_4{
        margin-top: 0px;
      }
      .marginBt_5{
        margin-top: 0px;
      }
      .marginBt_6{
        margin-top: 0px;
      }
      .marginBt_7{
        margin-top: 0px;
      }
      .marginBt_8{
        margin-top: 0px;
      }
      .framework_Cols {
          width: 80%;
      }
      .frameWork_Row{
          width: 100%;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          gap:25px 45px;
          justify-content: flex-start;
          align-items: center;
        }
        .frameWork_Cols{
          width: 100%;
  
        }
        .frameWork_Row .frameWork_Cols h2{
          color: #2BA84A;
          font-family: Uniform;
          font-size: 18px;
          font-style: normal;
          font-weight: 800;
          line-height: normal;
          text-align: left;
          display: flex;
          align-items: center;
        }
    
    }

    @media screen and (max-width: 575px) {
        body {
            display: block;
            margin: 0px;
        }
        .frameworkBackground-text {
            display: none;
        }
        .container.frameWork_section{
          width: 90%;
        }
        /* Our Framework */
       .framework_headSection {
            display: flex;
            width: 100%;
            justify-content: center;
            align-items: center;
            flex-direction: column;
        }
        .frameWorkSec_Col1 {
            width: 100%;
        }
        .frameWorkSec_Col2 {
            width: 100%;
        }
        .frameWork_section {
            padding-top: 30px;
            display: flex !important;
            flex-direction: column ! important;
        }
        .frameWork_section h1 {
            font-size: 23px;
            font-weight: 400;
        }
        .frameworkBackground-text {
            text-align: center;
            position: absolute;
            top: 82px;
            left: 0;
            right: 0;
            bottom: 0;
            font-size: 65px;
        }
        .frameRight_watermark {
            display: none;
        }
        .framework_row1 {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
        }
        .framework_row2 {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
        }
        
        .framework_Cols {
            width: 80%;
        }
        .frameWork_Row{
            width: 100%;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            gap:25px 45px;
            justify-content: flex-start;
            align-items: center;
          }
          .frameWork_Cols{
            width: 100%;
    
          }
          .frameWork_Row .frameWork_Cols h2{
            color: #2BA84A;
            font-family: Uniform;
            font-size: 18px;
            font-style: normal;
            font-weight: 800;
            line-height: normal;
            text-align: left;
            display: flex;
            align-items: center;
          }
    
    }

        /* ----------------------------------------------400----------------------------------------- */
    
        @media (min-width: 300px) and (max-width: 400px) {
            body {
                display: block;
                margin: 0px;
            }
            /* Our Framework */
           .framework_headSection {
                display: flex;
                width: 100%;
                justify-content: center;
                align-items: center;
                flex-direction: column;
            }
            .frameWorkSec_Col1 {
                width: 100%;
            }
            .frameWorkSec_Col2 {
                width: 100%;
            }
        
            .frameWork_section {
                padding-top: 30px;
                display: flex !important;
                flex-direction: column ! important;
            }
            .frameWork_section h1 {
                font-size: 23px;
                font-weight: 400;
            }
            .frameworkBackground-text {
                text-align: center;
                position: absolute;
                top: 82px;
                left: 0;
                right: 0;
                bottom: 0;
                font-size: 65px;
            }
            .frameRight_watermark {
                display: none;
            }
            .framework_row1 {
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
            }
            .framework_row2 {
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
            }
            .framework_Cols {
                width: 80%;
            }
            .frameWork_Row{
                width: 100%;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                gap:25px 45px;
                justify-content: flex-start;
                align-items: center;
              }
              .frameWork_Cols{
                width: 100%;
        
              }
              .frameWork_Row .frameWork_Cols h2{
                color: #2BA84A;
                font-family: Uniform;
                font-size: 18px;
                font-style: normal;
                font-weight: 800;
                line-height: normal;
                text-align: left;
                display: flex;
                align-items: center;
              }
        
        
        }