/* Mobile Tab Section */

.climateTabs img{
    width: 32px;
    height: auto;
  }

.climateMobileSec{
  display: flex;
  flex-direction: column;
}
.climateMobileSec TabPanel img{
  display: flex;
  flex-direction: column;
  gap: var(--XL, 24px);
}

.climateMobileSec .css-13xfq8m-MuiTabPanel-root{
  padding: 0;
  display: flex !important;
  flex-direction: column;
  align-items: flex-start !important;
  gap: 24px !important;
  flex: 1 0 0;
  width: 90% !important;
  margin-left: 30px;
}

.climateTabs .css-aym9vq-MuiButtonBase-root-MuiTab-root.Mui-selected {
    color: var(--neutral-charcoal-black, #1D1D1B) !important;
    text-align: center !important;
    font-family: Uniform !important;
    font-size: 12px !important;
    font-style: normal;
    font-weight: 400 !important;
    line-height: normal;
    text-transform: uppercase !important;
    -webkit-text-stroke-width: 1px !important;
    width: 133px !important;
    display: flex;
    flex-direction: column !important;
    align-items: center !important;
    gap: var(--M, 12px) !important;
}

.climateTabs .css-aym9vq-MuiButtonBase-root-MuiTab-root{
  text-align: center !important;
  font-family: Uniform !important;
  font-size: 12px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: normal;
  text-transform: uppercase !important;
  -webkit-text-stroke-width: 1px !important;
  width: 133px !important;
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  gap: var(--M, 12px) !important;
  color: #bfbfbf !important;
}

.MuiTabs-scroller.MuiTabs-fixed::-webkit-scrollbar{
  display: none !important;
}
.MuiTabs-flexContainer.css-heg063-MuiTabs-flexContainer::-webkit-scrollbar{
  display: none !important;
}
.climateMobileSec .css-1gsv261{
  border: none !important;
  margin-bottom: 24px;
}

.css-heg063-MuiTabs-flexContainer{
  overflow: scroll !important;
}
.css-heg063-MuiTabs-scrollButtons {
  border: none !important;
  background-color: transparent !important;
}

.mobTabSec{
  display: flex;
  flex-direction: row;
  margin-bottom: 24px;
}

.mobileDegreeSec {
  display: flex;
  align-items: center;
}

.mobileDegreeSec img {
  width: 50px;
}

.mobTabSec .degreeSpan{
  padding-left: 10px;
  padding-right: 24px;
  font-family: Uniform;
  font-size: 19.5px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  -webkit-text-stroke-width: 1px;
}

.mobTabSec p{
  color: var(--neutral-charcoal-black, #1D1D1B) !important;
  font-family: Uniform !important;
  font-size: 11px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 16px !important;
  -webkit-text-stroke-width: 0.2px !important;
  margin: 0 !important;
}

.mobFifteenHeading{
  color: var(--Amber-Orange, #F3A738);
  font-family: Uniform;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  -webkit-text-stroke-width: 0.5px;
  margin: 0;
}
.mobTwoHeading{
  color: var(--Amber-Orange, #F45B69);
  font-family: Uniform;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  -webkit-text-stroke-width: 0.5px;
  margin: 0;
}
.mobThreeHeading{
  color: var(--Amber-Orange, #79368E);
  font-family: Uniform;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  -webkit-text-stroke-width: 0.5px;
  margin: 0;
}
.mobFourHeading{
  color: var(--Amber-Orange, #0012B2);
  font-family: Uniform;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  -webkit-text-stroke-width: 0.5px;
  margin: 0;
}

.mobFifteen{
  color: var(--Amber-Orange, #F3A738);
}

.mobTwoDegree{
  color: var(--Amber-Orange, #F45B69);
}

.mobThreeDegree{
  color: var(--Amber-Orange, #79368E);
}

.mobFourDegree{
  color: var(--Amber-Orange, #0012B2);
}

 .css-1aquho2-MuiTabs-indicator{
  background-color: transparent !important;
}

.css-heg063-MuiTabs-flexContainer::-webkit-scrollbar {
  display: none !important;
}

.css-heg063-MuiTabs-flexContainer {
  -ms-overflow-style: none !important;  
  scrollbar-width: none !important;  
}

.climateTabs  .css-1tnnsql{
  width: 133px !important;
  text-align: center !important;
  font-family: Uniform !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  text-transform: uppercase !important;
  -webkit-text-stroke-width: 1px !important;
  margin: 0 !important;
  gap: 5px !important;
  color: #bfbfbf !important;
}

.css-1tnnsql.Mui-selected{
  width: 133px !important;
  color: #1D1D1B !important;
  text-align: center !important;
  font-family: Uniform !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  text-transform: uppercase !important;
  -webkit-text-stroke-width: 1px !important;
  margin: 0 !important;
  gap: 5px !important;
}


.climateTabs  .css-ttwr4n{
background-color: none !important;
}

.css-1anid1y{
  overflow: scroll !important;
}

.css-19kzrtu {
  padding: 0px !important;
}

