.adminBasicCon{
    width: -webkit-fill-available;
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 24px;
    border: 1px solid #E8E8E8;
    border-radius: 8px;
    background: var(--Neutral-Pure-White, #FFF);
}

.adminBasicInfoSec{
    width: -webkit-fill-available;
    display: flex;
    flex-direction: column;
    gap: 32px;
}

.adminBsForm{
    display: flex;
    flex-direction: column;
    gap: 32px;
}

.adminBasicCon h1{
    color: var(--Primary-Aqua-Blue-300, #003A4B);
    font-family: Uniform;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
    -webkit-text-stroke-width: 0.8px;
}

.adminBsInputRow{
    width: -webkit-fill-available;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 32px;
}

.adminBsInpCols{
    width: 31.3%;
}

.adminBsTitle{
    width: 10%;
}

.adminBsFirstN{
    width: 25%;
}

.adminBsLastN{
    width: 25%;
}

.adminBsAddress{
    width: -webkit-fill-available;
}

.adminBsCity{
    width: 32%;
}

.adminHorizontalLine{
    width: 100%;
    border: 1px solid #1D1D1B;
    opacity: 0.1;
}

.adminImgUpload{
    display: flex;
    gap: 32px;
}

.imgUploadBox{
    padding: 20px;
    border: 1px dashed #979796;
}

.imgUploadSec{
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.imgFormate{
    display: flex;
    gap: 12px;

}

.imgFormate span{
    color: var(--Primary-Aqua-Blue-300, #979796);
    font-family: Uniform;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
    -webkit-text-stroke-width: 0.2px;
}

.imgFormate .rightBorder{
    display: flex;
    border-right: 2px solid #1D1D1B;
    height: 4vh;
    margin-left: 0;
    margin-right: 0;
    opacity: 0.2;
}

.adminBsInputRow .adminPassInp{
    width: 31.3%;
}
