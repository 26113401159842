.statusContainer {
  border-radius: 4px;
  padding: 3px 6px 3px 6px;
}

.statusText {
  font: Uniform;
  font-weight: 400;
  font-size: 12px !important;
  text-align: center;
  color: #ffffff !important;
}

.rejected {
  background-color: #f45b69;
}

.pending {
  background-color: #f3a738;
}

.active {
  background-color: #2dce89;
}
