.form_SubmitBtn {
  cursor: pointer;
  /* width: 100% !important; */
  border-radius: 4px;
  transition:
    background-color 0.3s,
    color 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
}
.form_SubmitBtn p {
  padding: 12px 10px;
  font-weight: 800;
  text-align: center !important;
}

.form_SubmitBtn div{
  color: var(--Neutral-Charcoal-Black, #1D1D1B);
  text-align: center;
  font-family: Uniform;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 0;
}

.contained-button {
  display: flex;
  gap: 4px;
  font-family: Uniform;
  font-weight: 400;
  line-height: 16px;
  text-align: center;
  align-items: center;
  color: #ffffff;
  border: none;
  max-width: max-content;
  max-height: max-content;
  padding: 9px 8px;
  border-radius: 4px;
  cursor: pointer;
}

.outlined-button {
  display: flex;
  gap: 4px;
  font-family: Uniform;
  font-weight: 500;
  line-height: 16px;
  text-align: center;
  align-items: center;
  color: #ffffff;
  max-width: max-content;
  max-height: max-content;
  padding: 9px 8px;
  border-radius: 4px;
  cursor: pointer;
  color: #008bb2;
  /* border: 1px solid #008bb2; */
}

.bgBlue {
  background-color: #008bb2;
}

.bgRed {
  background-color: #f45b69;
}

.bgDarkblue {
  background-color: #0300b2;
}

.bgBlack {
  background-color: #1d1d1b;
}

.bgWhite {
  background-color: #ffffff;
}
