.approveDecBtn {
  position: relative;
  cursor: pointer;
  width: 100%;
  font-size: 15px;
  font-weight: 400 !important;
  outline: none;
  outline: none;
  border: none;
  background: #008bb2;
  border-radius: 0.5rem;
}

.approveDecBtn select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 8px;
  background-color: transparent;
  color: #fff;
  width: 100% !important;
  border: none !important;
  outline: none !important;
  padding: 14px 58px 14px 16px;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  position: relative;
  z-index: 10;
}

.approveDecBtn img {
  position: absolute;
  right: 0%;
  bottom: 0%;
  background-color: #00637e;
  padding: 14px;
  border-radius: 0px 8px 8px 0px;
}

.approveDecBtn option {
  text-align: left;
  font-family: Uniform;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
  background-color: #fff;
  color: black;
}
