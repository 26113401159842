.selectProfileCon{
    margin: 0;
}

.selectProfileCon .css-m5hdmq-MuiInputBase-root-MuiInput-root-MuiSelect-root:before{
    border-bottom: none !important;
}

.selectProfileCon .css-1rxz5jq-MuiSelect-select-MuiInputBase-input-MuiInput-input{
    color: #FFF;
    font-family: uniform;
    font-size: 13px;
}

.selectProfileCon .css-pqjvzy-MuiSvgIcon-root-MuiSelect-icon{
    color: #FFF !important;
}

