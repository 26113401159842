.profileEditBtn{
    display: flex;
    align-items: center;
    background-color: #1D1D1B;
    border-radius: 4px;
    padding: 12px;
}

.profileEditBtn .profileEdtBtnText{
    color: var(--Neutral-Charcoal-Black, #FFF);
    font-family: Uniform;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    text-transform: capitalize;
    margin: 0;
    -webkit-text-stroke-width: 0.2px;
}