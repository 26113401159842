.proPreBasicCon{
    overflow: auto;
    width: -webkit-fill-available;
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 24px;
    border-radius: 8px;
    background: var(--Neutral-Pure-White, #FFF);
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.08);
}

.preBasicInfoSec{
    width: -webkit-fill-available;
    display: flex;
    flex-direction: column;
    gap: 32px;
}

.proPreBsForm{
    display: flex;
    flex-direction: column;
    gap: 32px;
}

.proPreBasicCon h1{
    color: var(--Primary-Aqua-Blue-300, #003A4B);
    font-family: Uniform;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
    -webkit-text-stroke-width: 0.5px;
}

.proPreBsInputRow{
    width: -webkit-fill-available;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 32px;
}

.proPreBsInpCols{
    width: 31.7%;
}

.proPreBsTitle{
    width: 10%;
}

.proPreBsFirstN{
    width: 25%;
}

.proPreBsLastN{
    width: 25%;
}

.proPreBsAddress{
    width: 66%;
}

.proPreBsCity{
    width: 31.5%;
}