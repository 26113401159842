    /* Build Section */
        .building_section {
          padding-top: 60px;
          padding-bottom: 80px;
          width: -web;
        }
        .EEETop_sec{
          background: #E6F3F7;
        }
        .building_Col1 {
          width: 72%;
          display: flex;
       }
      .building_Col2 {
        width: 25%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
       }
       .building_section p{
        color: var(--neutral-charcoal-black, #1D1D1B);
        font-family: Uniform;
        font-size: 28px;
        text-align: left;
        font-style: normal;
        font-weight: 400;
        line-height: 48px;
        -webkit-text-stroke-width: 0.2px;
       }
      .building_Col1 img{
        width: 90%;
        height: auto;
        flex-shrink: 0;
      }
      .buildingBtn_row{
        display: flex;
        justify-content: center;
        background-color: #003A4B;
        border-radius: 8px;
        padding: 16px 24px;
        flex-direction: row;
        gap: 22px;
        width: 72%;
      }
      .buildingBtn_row h2{
        font-family: Uniform;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin: 0px;
        color: #ffffff;
      }
      .buildingBtn_row p{
        font-family: Uniform;
        font-size: 15px;
        color: #ffffff;
        font-style: normal;
        font-weight: 100;
        line-height: 30px;
        margin: 0px;
        padding: 0px; 
      }
      .building_span{
        color: #008BB2;
        font-weight: 800;
      }
      .buildBtn_divider{
        border-right: 1px solid #ffffff;
        margin: 20px 0px;
      }
      .building_section  .institutePara{
        color: var(--neutral-charcoal-black, #1D1D1B);
        font-family: Uniform;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px;
        -webkit-text-stroke-width: 0.2px;
        width: 88%;
      }

       /* Screen For 1680*1050 */
    @media screen and (min-width: 1621px) and (max-width: 1820px) { 
      .building_Col2{
        width: 30%;
      }
    }

     /* Screen For 1440*900 */
     @media screen and (min-width: 1281px) and (max-width: 1620px) {
        .buildingBtn_row {
          gap: 22px;
        }
        .building_Col1 {
          width: 66%;
        }
        .building_Col2 {
          width: 34%;
        }
    }

    /* Screen For 1280*1024 */
    @media screen and (max-width: 1280px) {
       .buildingBtn_row {
             gap: 22px;
          }
          .building_Col1 {
            width: 60%;
          }
          .building_Col2 {
            width: 40%;
          }
    }
    
     /* Screen For 1024*600 */
    @media screen and (min-width: 992px) and (max-width: 1242px) {
        .container {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: auto;
            width: 90%;
        }
        .building_Col1 img {
            width: 100%;
            height: auto;
            flex-shrink: 0;
        }
        
        .buildingBtn_row {
            width: 83%;
            display: flex;
            background-color: #003A4B;
            border-radius: 8px;
            padding: 8px 0px;
            flex-direction: row;
            justify-content: center;
        }
    }

    @media screen and (min-width: 768px) and (max-width: 992px) {
        .building_section {
            width: 100% !important;
            display: flex;
            flex-direction: column !important;
            align-items: center;
        }
        .building_Col1 img {
            width: 100%;
            height: auto;
            flex-shrink: 0;
        }
        .buildingBtn_row {
            width: 40%;
            display: flex;
            background-color: #003A4B;
            border-radius: 8px;
            padding: 6px 15px;
            flex-direction: row;
            justify-content: center;
        }
        .buildingBtn_row h2 {
          font-size: 16px;
        }
        .buildingBtn_row p {
          font-size: 14px;
          width: 100%;
        }
        .building_Col1 {
          width: 85%;
       }
       .building_Col2 {
        width: 85%;
       }
       .building_section .institutePara{
        width: 100%;
       }
    
    }

    @media screen and (max-width: 767px) {
      .building_section {
        width: 100% !important;
        display: flex;
        flex-direction: column !important;
        align-items: center;
      }
        .building_Col1 img {
            width: 100%;
            height: auto;
            flex-shrink: 0;
        }
        .buildingBtn_row {
            width: 73%;
            display: flex;
            background-color: #003A4B;
            border-radius: 8px;
            padding: 5px 15px;
            flex-direction: row;
            justify-content: space-evenly;
        }
        .building_section p {
            font-size: 13px;
            font-style: normal;
            line-height: 37px;
        }
        .buildingBtn_row {
            width: 55%;
            display: flex;
            background-color: #003A4B;
            border-radius: 8px;
            padding: 8px 15px;
            flex-direction: row;
            justify-content: space-evenly;
        } 
        .buildingBtn_row h2{
            font-size: 16px;
        }
        .buildingBtn_row p {
          font-size: 14px;
        }
        .building_Col1 {
          width: 85%;
       }
       .building_Col2 {
        width: 85%;
       }
       .building_section .institutePara{
        width: 100%;
       }
       
    }

    @media screen and (max-width: 575px) {
        body {
            display: block;
            margin: 0px;
        }
        .building_section {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: auto;
            width: 90% !important;
            overflow: hidden;
        }
      /* building Section */
       .building_Col1 img {
        width: 95%;
       }
        .building_Col1 {
          width: 100%;
          display: flex;
        }
        .building_Col2 {
          width: 100%;
          display: flex;
          flex-direction: column;
        }
        .buildingBtn_row {
            width: 80%;
        }
        .buildingBtn_row h2 {
            font-family: Uniform;
            font-size: 14px;
        }
       .building_section p {
            font-size: 12px;
            text-align: left;
        }
    }

    /* ----------------------------------------------400----------------------------------------- */
    
    @media (min-width: 300px) and (max-width: 400px) {
        body {
            display: block;
            margin: 0px;
        }
        .building_section {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: auto;
            width: 90%;
        }
       /* building Section */
       .building_Col1 img {
        width: 95%;
       }
        .building_Col1 {
            width: 100%;
            display: flex;
        }
        .buildingBtn_row {
            width: 80%;
        }
        .buildingBtn_row h2 {
            font-family: Uniform;
            font-size: 14px;
        }
       .building_section p {
            font-size: 12px;
            text-align: left;
        }
        .building_Col2 {
          width: 100%;
          display: flex;
          flex-direction: column;
        }
    
    }
    