
.userReqContainer{
    background-color:#F7F7F7;
    display: flex;
    align-items: center;
    align-self: stretch;
    align-items: flex-start;
    min-height: 100vh;
}

.userReqCol1{
    background-color: #003A4B;
    display: flex ;
    flex-direction: column ;
    width: 20%;
    align-items: center;
    gap: 8px;
    height: 100vh;
    overflow: auto;
}

.userReqCol2{
    display: flex ;
    flex-direction: column ;
    width: 80%;
    align-items: flex-start;
    height: 100vh;
    overflow: auto;
}

.registReqSection{
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available;
    gap: 32px;
    padding: 32px;
}




