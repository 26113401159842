
.viewBasDetContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    align-self: stretch;
}

.userBasDetails{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: -webkit-fill-available;
    gap: 3px;
}

.userBasDetails p{
    color: var(--Neutral-Charcoal-Black-300, #686866);
    font-family: Uniform;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    margin: 0;
    -webkit-text-stroke-width: 0.2px;
}

.userBasDetails h1{
    color: var(--Neutral-Charcoal-Black, #1D1D1B);
    font-family: Uniform;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
    -webkit-text-stroke-width: 0.5px;
    overflow-wrap: anywhere;
}

.userProBasDetailsRow{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: -webkit-fill-available;
}