.emisPreviewSection {
  background: linear-gradient(175deg,#00141a -18.79%,#003a4b 102.02%);
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin: auto;
  width: -webkit-fill-available ; 
  min-height: 100vh ;
  overflow: hidden; 
  padding: 16px 8px;
  gap: 20px;
}

.emisPreTitleHeader{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0px 32px;
 }
 
 .emisPreLeftDiv{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 40%;
  gap: 20px;
 }

.emisPreLeftDiv p{
  color:#E6F3F7 !important;
  text-align: center;
  font-family: Uniform;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
 }

 .emisPreCancelBtn{
  height: fit-content;
 }

 .emisPreSubmitBtn{
  height: fit-content;
 }

 .emis4DraftBtn{
  height: fit-content;
 }

 .emisPreInnerCon{
  padding: 2rem 4rem;
  display: flex;
  width: -webkit-fill-available;
  gap: 3%;
  align-items: flex-start;
  border-radius: var(--corner-radius-corner-radius-large, 20px);
  background: #F7F7F7;
  box-shadow: 0px 0px 8px 0px rgba(29, 29, 27, 0.20);
 }

 .emisPreSecCol1{
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 25%;
}

.roadmapTitle{
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.roadmapTitle h1{
  font-size: 22px;
  font-family: uniform;
  color: #1D1D1B;
  margin: 0px;
}

.stepsNext{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}

.stepsNext p{
  font-size: 12px;
  font-family: uniform;
  color: #1D1D1B;
  margin: 0px;
  -webkit-text-stroke-width: 0.2px;
}

.stepsNext span{
  font-size: 14px;
  font-family: uniform;
  color: #008BB2;
  margin: 0px;
  -webkit-text-stroke-width: 0.3px;
}

.emisPreSecCol2{
  padding: 24px;
  align-items: flex-start;
  width: 75%;
  display: flex;
  flex-direction: column;
  display: flex;
  border-radius:8px;
  gap: 40px;
}

.net4YearRow{
  display: flex;
  flex-direction: column;
  width: -webkit-fill-available;
  justify-content: space-between;
}

.stepsCodewaveLogo{
  gap: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.stepsCodewaveLogo h3{
  font-family: Uniform;
  font-size: 22px;
  color: #003A4B;
}

.preNetZeroValue h1{
  color: #2BA84A;
  font-family: Uniform;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  margin: 0;
  -webkit-text-stroke-width: 0.2px;
}
