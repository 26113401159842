.pdfDocContainer{
    display: flex;
    padding: 12px 16px 12px 12px;
    align-items: center;
    gap: 12px;
    border-radius: 8px;
    background: #E6E6F7;
    width:max-content;
    position: relative;
}

.pdfDocCloseBtn{
    position: absolute;
    right: -10%;
    bottom: 70%;
    background: #FBE4C1;
    padding: 0.2rem 0.3rem;
    border-radius: 12px;
  }
  
  .pdfDocInnerdiv{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
  }
  
  .pdfDocInnerdiv p{
      color: #686866 !important;
      white-space: nowrap;
      font-family: Uniform;
      font-size: 14px !important;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      margin: 0;
      -webkit-text-stroke-width: 0.2px !important;
    }

    .pdfDocIcon{
      width:25px;
      height: auto;
      margin-right: 5px;
    }