.feedbackInnerSec {
    padding: 40px 40px 80px 40px !important;
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.haveFeedbackContainer{
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    background: linear-gradient(91.73deg, #E6F3F7 -1.28%, #FBE4C1 148.84%);
    padding: 40px;
}

.feedbackTitle{
    color: var(--neutral-charcoal-black, #1D1D1B);
    font-family: Uniform;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
    -webkit-text-stroke-width: 0.5px;
}

.feedbackInpCol1{
    width: 20%;
}

.feedbackInpCol2{
    width: 80%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.feedbackInpCol2 h1{
    color: var(--neutral-charcoal-black, #003A4B);
    font-family: Uniform;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
    -webkit-text-stroke-width: 1px;
}
.feedbackInpCol2 p{
    color: var(--neutral-charcoal-black, #1D1D1B);
    font-family: Uniform;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
    -webkit-text-stroke-width: 0.2px;
}

.previousfeedbackTable{
    display: flex;
    gap: 20px;
    border-radius: 8px;
    background: #ffffff;
    box-shadow: 1px 0px 8px 5px rgba(0, 0, 0, 0.08);
    padding: 40px;
}

.faqcontainer{
    display: flex;
    gap: 10px;
    border-radius: 8px;
    background: #ffffff;
    box-shadow: 1px 0px 8px 5px rgba(0, 0, 0, 0.08);
    padding: 5px 25px;
}