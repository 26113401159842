
 .commentBoxSec{
    /* width: 97.5%; */
    display: flex;  
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    padding:8px;
    border-radius:4px;
    border: 0.5px solid var(--primary-aqua-blue-100, #B0DBE7);
    background: #F7FDFF;
    margin-bottom: 1rem;
 }

 .commentBoxSec h4{
    color:#008BB2 !important;
    font-family: Uniform;
    font-size: 14px;
    font-style: normal;
    font-weight: 700 !important;
    margin: 8px 0px;
 }
 .commentBoxSec p{
    color: #1D1D1B !important;
    font-family: Uniform;
    font-size: 13px !important;
    font-style: normal;
    font-weight: 500;
    line-height: 20px !important;
    margin: 0;
 }
  