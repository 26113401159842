.timelineYearChatCon{
    width: -webkit-fill-available;
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.timelineYearChat{
    width: 75%;
    display: flex;
    flex-direction: row;
    gap: 20px;
}
