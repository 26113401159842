.removeTimeLineBtn{
    display: flex;
    /* flex-direction: row; */
    border-radius: 4px;
    gap: 4px;
    /* padding: 8px; */
    align-items: flex-start;
    cursor: pointer;
}

.removeTimeLineBtn p{
    color: #DE5360;
    font-family: Uniform;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    margin: 0;
    -webkit-text-stroke-width: 0.1px;
}