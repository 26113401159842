.timelinePlanningCon {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: var(--M, 12px);
  align-self: stretch;
  width: 47vw;
  min-width: 40px;
}

.timelinePlanCol1 {
  width: 20%;
}

.timelinePlanCol1 h1 {
  color: #1d1d1b;
  font-family: Uniform;
  font-size: 12px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  margin: 0;
  -webkit-text-stroke-width: 0.1px;
}

.timelinePlanCol2 {
  width: 100%;
}

.yearCalendarRow1 {
  display: flex;
  flex-direction: row;
  gap: 12px;
  flex-wrap: wrap;
}

.demoContainer {
  display: flex;
  flex-direction: column;
}

div.MuiStack-root .css-4jnixx-MuiStack-root {
  display: flex;
  flex-direction: column;
}

div.yearCalendarRow1 .selectMinWidth .css-4jnixx-MuiStack-root {
  overflow: hidden !important;
  display: flex;
  flex-direction: column !important;
  padding-top: 0px !important;
}

div.yearCalendarRow1 .MuiFormControl-root {
  margin-left: 0 !important;
  min-width: auto !important;
  margin: 0px !important;
  width: -webkit-fill-available !important;
  border-radius: 4px;
  /* border: 0.5px solid var(--Neutral-Charcoal-Black-100, #b9b9b8) !important; */
}

div.yearCalendarRow1 input {
  height: auto !important;
}

.selectMinWidth {
  min-width: 18%;
  max-width: 22%;
}
div.selectMinWidth .css-z3c6am-MuiFormControl-root-MuiTextField-root{
  border-radius: 4px;
  border: 0.5px solid var(--Neutral-Charcoal-Black-100, #b9b9b8) !important;
}

.yearCalendarRow1 .timeSelectBox {
  min-width: 18%;
  max-width: 22%;
}

.selectMinWidth .css-160unip{
  overflow: hidden !important;
  display: flex;
  flex-direction: column !important;
  padding-top: 0px !important;
}

.yearCalendarRow1 .css-160unip > .MuiTextField-root{
  border-radius: 4px !important;
  border: 0.5px solid var(--Neutral-Charcoal-Black-100, #b9b9b8) !important;
}
