.profileInfoHeader{
    width: -webkit-fill-available;
    background: url('../../../assets/bgImages/profileHeaderBG.svg') center/cover no-repeat;
    background-size: contain;
    background-position: top;
}

.profileRateImgDiv {
    display: flex;
    flex-direction: row;
    margin-top: 70px;
    padding: 0 25px;
    gap: 12px;
    align-items: center;
    justify-content: space-between;
}

.profileImgSection{
    display: flex;
    gap: 12px;
    align-items: center;
}

.profileRateImgDiv img{
    margin-top: 10px;
    display: flex;
    width: 184px;
    height: 184px;
    align-items: flex-start;
    gap: 10px;
    border-radius: 200px;
    border: 4px solid #1D1D1B;
}

.profileRateImgDiv h1{
    color: var(--Neutral-Charcoal-Black, #1D1D1B);
    font-family: Uniform;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin: 0;
    margin-top: 10px;
    -webkit-text-stroke-width: 0.5px;
}

.profileRateImgDiv p{
    color: var(--Primary-Aqua-Blue-300, #54B1CB);
    font-family: Uniform;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    margin: 0;
    margin-top: 5px;
    -webkit-text-stroke-width: 0.2px;
    overflow-wrap: anywhere;
}

.OgProfileName{
    /* margin-top: 20px; */
}