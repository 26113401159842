/* Climate Impact Section */

.climate_section {
    padding-top: 80px;
    padding-bottom: 80px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
  }
  .climate_section h1{
    color: var(--neutral-charcoal-black, #1D1D1B);
    font-family:Woodman-HeavyRounded;
    font-size: 40px;
    font-weight: 400;
    margin: 0px;
  }
  .climate_heading_p{
    text-align: left;
    margin: 0;
    color: #1D1D1B;
    font-family: Uniform;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    -webkit-text-stroke-width: 0.2px;
  }
  
  .lastRow_text p{
    color: var(--neutral-charcoal-black, #1D1D1B);
    font-family: Uniform;
    text-align: center !important;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    width: 160px;
    -webkit-text-stroke-width: thin;
  }

  table {
    width: 100%;
    border-collapse: collapse;
  }

  table, th, td {
    /* border: 1px solid black; */
  }

  th, td {
    padding: 10px;
    text-align: center;
  }

  th {
    color: white;
  }
  tr .heading{
    color: #000000;
  }

  tr:nth-child(even) {
  }
  th, table tr:not(:last-child) td {
    width: 20%;
    border-bottom: 1px solid #B9B9B8;
    padding: 35px 65px;
  }
  .tableHeading_sec{
    display: flex;
    align-items: center;
    flex-direction: column;
    /* height: 46px; */
    justify-content: center;
    /* width: 157px; */
    
  }
  .tableHeading_sec span{
    font-family: Uniform;
    font-size: 16px;
    font-weight: bolder;
    color: #1D1D1B;
    text-align: center;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    text-transform: uppercase;
    -webkit-text-stroke-width: 0.5px;
  }
   .tableHeading_sec img{
      width: 50px;
      height: 50px;
      margin-bottom: 13px;
    }
    .threeDegree{
      color: #F3A738;
      font-family: Uniform;
      font-size: 72px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    .fifteenDegree{
      color:#F45B69;
      font-family: Uniform;
      font-size: 72px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    .threeDegree_data{
       color: #F3A738;
       text-align: center;
       font-family: Uniform;
       font-size: 24px;
       font-style: normal;
       font-weight: 400;
       line-height: normal;
    }
    .fifteenDegree_data{
      color: #008BB2;
       text-align: center;
       font-family: Uniform;
       font-size: 24px;
       font-style: normal;
       font-weight: 400;
       line-height: normal;
    }
    .degree_sec{
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
    .degree_sec img{
      position: absolute;
      left: 0;
    }
    .degree_span{
    margin-left: 0px;
    margin-right: 30px;
    }
    .text_sec{
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .fifteenDegree_Block{
    }

    .fifteenDegree_Block p{
      color: var(--neutral-charcoal-black, #1D1D1B);
      text-align: center;
      font-family: Uniform;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      margin: 0;
      -webkit-text-stroke-width: 0.2px;
    }

    .fifteenDegree_Block h3{
      color: var(--Amber-Orange, #F3A738);
      text-align: center;
      font-family: Uniform;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      margin: 0;
      -webkit-text-stroke-width: 0.5px;
    }
    .twoDegree h3{
      color: var(--Amber-Orange, #F45B69);
      text-align: center;
      font-family: Uniform;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      margin: 0;
      -webkit-text-stroke-width: 0.5px;
    }
    .threeDegree h3{
      color: var(--Amber-Orange, #79368E);
      text-align: center;
      font-family: Uniform;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      margin: 0;
      -webkit-text-stroke-width: 0.5px;
    }
    .fourDegree h3{
      color: var(--Amber-Orange, #0012B2);
      text-align: center;
      font-family: Uniform;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      margin: 0;
      -webkit-text-stroke-width: 0.5px;
    }

    .threeDeg{
      color: var(--Amber-Orange, #79368E);
    }
    .fourDeg{
      color: var(--Amber-Orange, #0012B2);
    }




    /* Screen For 1680*1050 */
    @media screen and (min-width: 1621px) and (max-width: 1820px) {
      th, table tr:not(:last-child) td {
        padding: 35px 50px;
      }
     }
    /* Screen For 1440*900 */
    @media screen and (min-width: 1281px) and (max-width: 1620px) {
      .degree_sec img {
        position: absolute;
        left: 0;
     }
     .tableHeading_sec img {
      width: 56px;
      height: 56px;
     }
     th, table tr:not(:last-child) td {
      width: 20%;
      border-bottom: 1px solid #B9B9B8;
      padding: 35px 30px;
    }
     }

    /* Screen For 1280*1024 */
    @media screen and (max-width: 1280px) {
      .degree_sec img {
        width: 8%;
        height: auto;
        position: absolute;
      }
      th, table tr:not(:last-child) td {
        width: 20%;
        border-bottom: 1px solid #B9B9B8;
        padding: 35px 20px;
      }
     }

    /* Screen For 1024*600 */
    @media screen and (min-width: 992px) and (max-width: 1242px) {
    
        .container {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: auto;
            width: 90%;
        }
        .threeDegree {
          font-size: 50px;
        }
        .fifteenDegree {
          font-size: 50px;
        }
        .degree_span {
          margin-left: 0px;
          margin-right: 0px;
      }

        .degree_sec img {
            width: 8%;
            height: auto;
            position: absolute;
            /* margin-right: 130px; */
        }
        th, table tr:not(:last-child) td {
          width: 20%;
          border-bottom: 1px solid #B9B9B8;
          padding: 33px 12px;
        }
        .climate_section {
            padding-top: 0px;
            padding-bottom: 80px;
            display: flex ! important;
            flex-direction: column !important;
            align-items: flex-start ! important;
        }
        .climate_section h1{
          margin-top: 80px;
        }
        .header-logo img {
          margin-right: 20px;
      }
       
    }

    @media screen and (min-width: 768px) and (max-width: 992px) {
        .container {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: auto;
            width: 90%;
        }
        .climate_section {
            padding-top: 0px;
            padding-bottom: 80px;
            display: flex !important;
            flex-direction: column ! important;
            align-items: flex-start !important;
        }
        .lastRow_text p {
          font-size: 12px;
          text-align: center !important;
          width: 100%;
      }
      
      .threeDegree {
        font-size: 35px;
      }
      .fifteenDegree {
        font-size: 35px;
      }
      .degree_span {
        margin-left: 0px;
        margin-right: 0px;
      }
      .tableHeading_sec span {
        font-size: 11px;
      }

      .threeDegree_data{
        font-size: 16px;
      }
      .fifteenDegree_data {
        font-size: 16px;
      }
      
      .degree_span {
        margin-left: 0px;
        margin-right: 0px;
      }

      .degree_sec img {
          width: 8%;
          height: auto;
          position: absolute;
      }
      th, table tr:not(:last-child) td {
        width: 21%;
        border-bottom: 1px solid #B9B9B8;
        padding: 25px 5px;
      }
      .climate_section h1{
        margin-top: 80px;
      }
      .degree_sec {
        margin-right: 14px;
      }
    }

    @media screen and (max-width: 767px) {
      .container {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin: auto;
          width: 90%;
        }
        .lastRow_text p {
          font-size: 12px;
          text-align: center !important;
          width: 100%;
        }
        .climate_section {
          padding-top: 0px;
          padding-bottom: 80px;
          display: flex !important;
          flex-direction: column ! important;
          align-items: flex-start ! important;
        }
        .threeDegree {
          font-size: 30px;
        }
        .fifteenDegree{
          font-size: 30px;
        }
        .threeDegree_data{
          font-size: 16px;
        }
        .fifteenDegree_data {
          font-size: 16px;
        }
        .degree_span {
          margin-left: 0px;
          margin-right: 0px;
        }
        .tableHeading_sec span {
          font-size: 10px;
        }
        .degree_sec img {
            width: 8%;
            height: auto;
            position: absolute;
        }
        th, table tr:not(:last-child) td {
          width: 21%;
          border-bottom: 1px solid #B9B9B8;
          padding: 33px 0px;
        }
        .threeDegree_data{
          font-size: 16px;
        }
        .fifteenDegree_data {
          font-size: 16px;
        }
        .climate_section h1{
          margin-top: 80px;
        }
        .fifteenDegree_Block p{
          font-size: 10px;
        }
        .fifteenDegree_Block  h3{
          font-size: 10px;
        }
        .degree_sec {
          margin-right: 14px;
        }
    }
    
    @media screen and (max-width: 575px) {
        body {
            display: block;
            margin: 0px;
        }
        /* climate section */
        .container.climate_section {
            padding-top: 60px;
            padding-bottom: 60px;
            display: flex !important;
            flex-direction: column ! important;
            align-items: flex-start ! important;
            width: 100%;
        }
        .climate_section h1 {
          /* text-align: center; */
            font-family: Woodman-HeavyRounded;
            font-size: 23px;
            font-weight: 400;
            margin: 0px auto;
            width: 90%;
        }
        .climate_section p{
            font-family: Uniform;
            font-size: 14px;
            font-weight: 400;
            line-height: 28px;
            margin: 0px auto;
            width: 90%;
        }
        
        .css-19kzrtu {
          padding: 0px !important;
        }
        .MuiTabs-indicator.css-ttwr4n{
          display: none;
        }
        table {
            width: 100%;
            border-collapse: collapse;
        }
        th, table tr:not(:last-child) td {
            width:0;
        }
        .tableHeading_sec img {
            width: 40px;
            height: 40px;
            margin-bottom: 13px;
        }
        .tableHeading_sec span {
            font-family: Uniform;
            font-size: 10px;
            font-weight: bolder;
        }
    
        .degree_sec img {
            width: 73px;
            height: 36px;
        }
        .threeDegree {
            color: #F3A738;
            font-size: 33px;
        }
        .fifteenDegree {
            color: #008BB2;
            font-size: 33px;
        }
        .threeDegree_data {
            font-size: 15px;
        }
        .fifteenDegree_data{
            font-size: 15px;
        }
        .lastRow_text p {
            font-size: 12px;
            text-align: center !important;
            width: 100%;
            line-height: 15px;
        } 
        .degree_sec {
          display: none;
      }

    }

    /* ----------------------------------------------400----------------------------------------- */
    
    @media (min-width: 300px) and (max-width: 400px) {
        body {
            display: block;
            margin: 0px;
        }
        
        /* climate section */
        .climate_section h1 {
            font-family: Woodman-HeavyRounded;
            font-size: 23px;
            font-weight: 400;
            margin: 0px;
        }
        .climate_section {
            width: 85%;
            padding-top: 40px;
            padding-bottom: 40px;
            display: flex !important;
            flex-direction: column ! important;
            align-items: flex-start ! important;
        }
        .climate_section p{
            font-family: Uniform;
            font-size: 14px;
            font-weight: 400;
            line-height: 28px;
        }
        table {
            width: 100%;
            border-collapse: collapse;
            margin: 30px -27px;
        }
        .tableHeading_sec img {
            width: 40px;
            height: 40px;
            margin-bottom: 13px;
        }
        .tableHeading_sec span {
            font-family: Uniform;
            font-size: 10px;
            font-weight: bolder;
        }
    
        .degree_sec img {
            width: 73px;
            height: 36px;
        }
        .degree_sec {
          display: none;
        }
        .threeDegree {
            color: #F3A738;
            font-size: 33px;
        }
        .fifteenDegree {
            color: #008BB2;
            font-size: 33px;
        }
        .threeDegree_data {
            font-size: 15px;
        }
        .fifteenDegree_data{
            font-size: 15px;
        }
        .lastRow_text p {
            font-size: 12px;
            text-align: center !important;
            width: 100%;
            line-height: 15px;
        } 
        /* .climate_section{
          display: none !important;
        } */
    }
    