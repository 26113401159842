.priorityPillarCon{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--M, 12px);
    align-self: stretch;
}

.pillarActionCon{
    display: flex;
    align-items: flex-start;
    align-content: flex-start;
    gap: 24px;
    align-self: stretch;
}

.pillarActionCol1{
    width: 50%;
}

.pillarActionCol2{
    width: 50%;
}