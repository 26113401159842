
.regisLogHeader{
    display: flex;
    padding: 16px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-bottom: 1px solid var(--Neutral-Charcoal-Black-50, #E8E8E8);
    background: var(--Neutral-Pure-White, #FFF);
}
.regisLogHeader h1{
    color: var(--Neutral-Charcoal-Black, #1D1D1B);
    text-align: center;
    font-family: Uniform;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
    -webkit-text-stroke-width: 1px;
}

.regisLogDetails{
    display: flex;
    padding: var(--M, 12px) var(--L, 16px) var(--L, 16px) var(--L, 16px);
    flex-direction: column;
    align-items: flex-start;
    gap: var(--L, 16px);
    flex: 1 0 0;
    align-self: stretch;
}

.approveList{
    display: flex;
    padding: 8px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    border-radius: 4px;
    border: 0.5px solid #E8E8E8;
    background: #FAFAFA;
}

.rejectList{
    display: flex;
    padding: 8px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    border-radius: 4px;
    border: 0.5px solid #E8E8E8;
    background: #FAFAFA;
}

.applyOn{
    display: flex;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
}

.applyOn h2{
    color: var(--Neutral-Charcoal-Black, #1D1D1B);
    font-family: Uniform;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
    -webkit-text-stroke-width: 0.2px;
}

.logTime{
    color: var(--Neutral-Charcoal-Black-200, #979796);
    font-family: Uniform;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.approveLog{
    display: flex;
    padding: 3px 6px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 4px;
    border: 0.5px solid #2BA84A;
    background: #EAF6ED;
}
.approveLog p{
    color: var(--Secondary-Emerald-Green-900, #12471F);
    text-align: center;
    font-family: Uniform;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
    -webkit-text-stroke-width: 0.2px;
    text-transform: capitalize;
}

.rejectLog{
    display: flex;
    padding: 3px 6px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 4px;
    border: 0.5px solid #F45B69;
    background: #FEEFF0;
}

.rejectLog p{
    color: var(--Secondary-Crimson-Red-900, #66262C);
    text-align: center;
    font-family: Uniform;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
    -webkit-text-stroke-width: 0.2px;
    text-transform: capitalize;
}

.css-1e6y48t-MuiButtonBase-root-MuiButton-root{
    display: none !important;
}
.css-1ujsas3{
    display: none !important;
}

.css-1hskriy {
    width: 400px !important;
}

.viewFeedbacks{
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin: 12px 16px;
    padding: 16px;
    border: 1px solid #E8E8E8;
    border-radius: 4px;
}

.viewFeedbacks h1{
    color: var(--primary-aqua-blue, #008BB2);
    font-family: Uniform;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    -webkit-text-stroke-width: 0.2px;
    margin: 0;
}

.feedbackData{
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.feedbackData p{
    color: var(--primary-aqua-blue, #1D1D1B);
    font-family: Uniform;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    -webkit-text-stroke-width: 0.2px;
    margin: 0;
}

.quarterTitleYear{
    color: var(--primary-aqua-blue, #1D1D1B);
    font-family: Uniform;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    -webkit-text-stroke-width: 0.1px;
    margin: 0;
}