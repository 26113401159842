
.workDetailsSection {
    background: linear-gradient(175deg,#00141a -18.79%,#003a4b 102.02%);
    display: flex;
    flex-direction: row;
    align-items: stretch;
    margin: auto;
    width: -webkit-fill-available; 
    min-height: 100vh;
    overflow: hidden; 
}

.formInnerDiv{
    display: flex;
    flex-direction: column;
    gap: 32px;
}

p.workDHeadings{
    color: var(--neutral-charcoal-black, #1D1D1B);
    font-family: Uniform;
    font-size: 14px;
    font-style: normal;
    font-weight: 800 !important;
    line-height: 20px;
    margin: 0;
}

.workDelCol1 {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 25% ;
    padding: 4% 2% 0% 2%;
    align-items: center;
}

.workDelCol2 {
    background-color: #F7F7F7;
    padding: 8% 8% 8% 8%;
    width: 75%;
    display: flex;
    flex-direction: column;
}
.workDetails_topBg{
    height: 19rem;
    position: absolute;
    right: -181px;
    top: 0%;
    opacity: 0.2;
}
.workDetails_bottomBg{
    height: 9rem;
    position: absolute;
    right: 0px;
    bottom: 40%;
    opacity: 0.2;
}

.workDetails_leftBg{
    height: 6rem;
    position: absolute;
    left: 0%;
    top: 20%;
    opacity: 0.2;
}

.workDelCol1 h2{
    color: var(--neutral-pure-white, #FFF);
    font-family: Uniform;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
  }

  .workDelCol1 p{
    color: var(--neutral-pure-white, #FFF);
    font-family: Uniform;
    font-size: 14px;
    font-style: normal;
    font-weight: 100;
    line-height: 24px ;
  } 

.workDelCol1 .workDetails_timeLineContents p{
    font-family: Uniform;
    font-size: 16px;
    font-style: normal;
    font-weight: 100;
    line-height: 24px ;
}

.workDetails_timeLineContents{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.orgDetails_logoDiv{
    height: 8vh;
}

.orgDetails_logoDiv img {
    width: 112.678px;
    height: 44px;
}

.expertiseSec{
    margin-bottom: 24px;
}

.css-ahj2mt-MuiTypography-root{
    color: var(--neutral-charcoal-black, #1D1D1B);
    font-family: Uniform !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: normal !important;
    -webkit-text-stroke-width: 0.2px;
}

.workDelCol2 p {
    font-family: Uniform;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
    margin: 0;
    -webkit-text-stroke-width: 0px;
  }
 .workDelCol2 h1 {
    font-family: Uniform;
    font-size: 28px;
    font-weight: 900;
    text-align: left;
    margin: 0 0 10px 0;
    -webkit-text-stroke-width: 1px;
    margin-bottom: 20px;
  }

.headQuarterSection {
    border-radius: 8px;
    background: #fff;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 10px;
}

.headSections{
    display: flex;
    gap: 2%;
}

.orCityDiv{
    width: 33.5%;
  }

.attachmentsPara{
    color: var(--neutral-charcoal-black-200, #979796);
    font-family: Uniform;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
}

.wd_BtnRow{
    display:flex;
    justify-content: space-between;
}

.cancelBtn_Row{
    display: flex;
    justify-content: flex-end;
}

.NextBtn{
    display: contents;
}

.headQuarterBg{
    background-color: #ffffff;
}