.cmntRecieverChatBox{
    width: 70%;
    display: flex;
    flex-direction: row;
    gap: 12px;
    margin-right: auto;
    align-items: flex-end;
    justify-content: flex-start;
}

.recieverChatBoxCon{
    display: flex;
    padding: 8px 8px 8px var(--M, 12px);
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    border-radius: 4px;
    border: 0.5px solid #8B8ADC;
    background: #F6F6FF;
    border-left: 6px solid #8B8ADC;
    min-width: auto;
    max-width: 100%;
}
