
.addUserBtn{
    border: none;
    cursor: pointer;
    display: flex;
    padding: 12px;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background: #008BB2;
    gap: 4px;  
   }

   .addUserBtnText{
    color: #FFF;
    text-align: center;
    font-family: Uniform;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
   }