.GoodSec{
    display: flex;
    border: 1px solid #008BB2;
    padding: 24px 24px 24px 24px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    align-self: stretch;
    border-radius: 8px;
    margin-top: 16px;
  }

  .leftGoodPract{
    display: flex;
    margin-bottom: 16px !important;
    justify-content: space-between;
    width: -webkit-fill-available;
  }

  .secTitle_style {
    color:#003A4B !important;
    font-family: Uniform;
    font-size: 16px !important;
    font-style: normal;
    font-weight: 800 !important;
    line-height: normal;
    margin: 0;
  }

  .documentLists{
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  .goodPractice{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }
  .goodPractice img{
    margin-right: 8px;
    margin-top: 2px;
  }
  .goodPractice p{
    color: #1D1D1B !important;
    margin: 0;
    line-height: 20px !important;
    margin-bottom: 5px;
  }