.cmntSenderChatBox{
    width: 70%;
    display: flex;
    flex-direction: row;
    gap: 12px;
    margin-left: auto;
    align-items: flex-end;
    justify-content: flex-end;

}

.senderChatBoxCon{
    display: flex;
    padding: 8px 12px 8px 8px;
    flex-direction: column;
    align-items: flex-end;
    gap: 8px;
    align-self: stretch;
    border-radius: 4px;
    border: 0.5px solid #8ACADC;
    background: #F6FDFF;
    border-right: 6px solid #8ACADC;
    max-width: 100%;
    min-width: auto;
}