

.basicDetails_section {
    background: linear-gradient(175deg,#00141a -18.79%,#003a4b 102.02%);
    display: flex;
    flex-direction: row;
    align-items: stretch;
    margin: auto;
    width: -webkit-fill-available ; 
    min-height: 100vh ;
    overflow: hidden; 
  }
  .basicDetailsSec_Col1 {
    position: relative;
    display: flex ;
    flex-direction: column ;
    width: 25%  ;
    padding: 4% 2% 0% 2%;
    align-items: center;
  }
  .basicDetailsSec_Col2 {
    background-color: #fff;
    padding: 8% 8% 8% 8%;
    width: 75%;
    display: flex;
    flex-direction: column;
  }
  .basicDetailsSec_Col2 .innerContainer{
    width: -webkit-fill-available;
  }
  .form-row {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 0 2rem;
    flex-direction: row;
    margin-bottom: 1rem;
  }
  .titleRow{
    margin-bottom: 1rem;
  }
  .basicFormCol1{
     /* width: 20%; */
  }
  .basicFormCol2{
    /* width: 40%; */
   }
   .basicFormCol3{
    /* width: 40%; */
  }

  .form-row p{
   color:  #979796;
   font-family: Uniform;
   font-size: 12px;
   font-style: normal;
   font-weight: 400;
   line-height: 20px;
   margin: 0px;
  }

  .NextBtn{
    display: contents;
  }

  .basicDetailsSec_Col1 h2{
    color: var(--neutral-pure-white, #FFF);
    font-family: Uniform;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
  }
  
  /* .basicDetailsSec_Col1 .basicDetails_timeLineContents p{
    font-family: Uniform;
    font-size: 16px;
    font-style: normal;
    font-weight: 100;
    line-height: 24px ;
   } */
  .basicDetailsSec_Col1 p{
    color: var(--neutral-pure-white, #FFF);
    font-family: Uniform;
    font-size: 14px;
    font-style: normal;
    font-weight: 100;
    line-height: 24px ;
  }   
  
  .basicDetails_logoDiv img {
    width: 112.678px;
    height: 44px;
   }
  
  .basicDetailsSec_Col2 p {
    text-wrap: wrap;
    font-family: Uniform;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
  }
  .basicDetails_section .basicDetailsSec_Col2 h1 {
    font-family: Uniform;
    font-size: 28px;
    font-weight: 900;
    text-align: left;
    margin: 0 0 10px 0;
    -webkit-text-stroke-width: 1px;
    margin-bottom: 30px;
  }
  .bs_emailId{
    display:flex;
    align-items: center;
  }
  .bs_emailId p{
    color: var(--neutral-charcoal-black-200, #979796);
    font-family: Uniform;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  .cancelBtn_Row{
    display: flex;
    justify-content: flex-end;
  }
  
  .basicDetails_SubmitBtn {
    background: #008BB2;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(0,139,178,.12);
    justify-content: center;
    width: 100% ;
  }
  .basicDetails_SubmitBtn .basic_BtnText {
    font-family: Uniform;
    color: #fff;
    font-size: 15px;
    font-weight: 500;
    padding: 12px 10px;
    text-align: center;
    margin: 0;
  }
   .signUpalready_HaveAcc {
    display: flex;
    justify-content: center;
  }
  
  .basicDetails_topBg{
    height: 19rem;
    position: absolute;
    right: -181px;
    top: 0%;
    opacity: 0.2;
   }
   .basicDetails_bottomBg{
    position: absolute;
    right: 0px;
    bottom: -30px;
    opacity: 0.2;
   }
   .basicDetails_leftBg{
    height: 6rem ;
    position: absolute;
    left: 0%;
    top: 40%;
    opacity: 0.2;
   }
    .minWidth33{
      min-width: 200px;
    }
  
    .minWidth35{
      min-width: 250px;
    }

    .minWidth40{
      min-width: 280px;
    }
  
    .minWidth50{
      min-width: 400px;
    }

    .minWidth65{
      min-width: 65%;
    }

    .bsEmailInput{
      width: 49%;
    }

    .bsAddressField{
      width: 69.4%;
    }

  
  
   
  
  
   