.acknowContainer{
    display: flex;
    padding: var(--XL, 24px);
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: var(--M, 12px);
    align-self: stretch;
    border-radius: 8px;
    background: #FFF;
}

.acknowContainer h1{
    color: var(--Neutral-Charcoal-Black, #1D1D1B);
    font-family: Uniform;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
    -webkit-text-stroke-width: 0.3px;
}

.quarterRow{
    display: flex;
    flex-direction: row;
    gap: 2px;
}

.quarterCol{
    display: flex;
    flex-direction: row;
}

.quarterCol .css-j204z7-MuiFormControlLabel-root{
    margin-right: 0px !important;
}

.quarterCol .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root{
    padding: 3px 9px !important;
}

.acknowContainer p{
    color: var(--Neutral-Charcoal-Black, #1D1D1B);
    font-family: Uniform;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
    -webkit-text-stroke-width: 0.3px;
}

.acknowContainer .confirmationTitle{
    color: var(--Neutral-Charcoal-Black, #1D1D1B);
    font-family: Uniform;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
    -webkit-text-stroke-width: 0.6px !important;
}

