.ratingCardCon{
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 16px 20px;
    background-color: #00141A;
    border-radius: 8px;
    margin-top: 16px;
    position: relative;
}

.ratingCardCon p{
    color: #B9B9B8;
    font-family: Uniform;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 13px;
    margin: 0;
    -webkit-text-stroke-width: 0.2px;
  }

.ratingCardCon h1{
    color: #FFFFFF;
    font-family: Uniform;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 33px;
    margin: 0;
    -webkit-text-stroke-width: 0.5px;
}

.ratingCardImage img{
    position: absolute;
    left: 48%;
    bottom: 0%;
    width: 115px;
}