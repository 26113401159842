  /*........................ Home Page.......................... */
    .empoweringChange-section{
        display: flex;
        flex-direction: row;
        height: auto;
        margin: auto;
        position: relative;
        width: 84%;
    }
    .empoweringCol1{
        padding-top: 5%;
        margin-bottom: 20%;
        z-index: 2;
    }
    .empoweringChange-section h1{
        font-family: Woodman-HeavyRounded;
        font-weight: 400;
        font-size: 60.5px;
        text-align: left;
        padding-top: 10px;
        margin: 1.5rem 0rem;

    }
    .empoweringChange-section p{
      font-family: Uniform;
      font-size: 22px;
      font-weight: 500;
      text-align: left;
      line-height: 42px;
      margin: 0;
    }
    .empoweringCol2{
        width:0%;
    }
    .empowerimg_bgImg {
        background-image: url('../../assets/homeImages/empoweringSvg.svg');
        background-repeat: no-repeat;
        background-size: contain;
        height: inherit;
        left: 31%;
        position: absolute;
        top: -20%;
        right: 0;
        width: auto;
        height: 95vh;
    }
       .getStarted_btn{
        cursor: pointer;
        margin-top: 30px;
        padding: 1px 0px;
        width: 13%;
        border-radius: 4px;
        background: #00637E;
        box-shadow: 0px 2px 4px 0px rgba(0, 139, 178, 0.12);
       }
       .getStarted_btn h3{
        text-align: center;
        font-family: Uniform;
        font-size: 14px;
        color: white;
       }
       
       /*.........  */
    .empowerSection {
        position: relative;
        width: 100%;
        text-align: right;
        margin-bottom: 40px;
    }

    .empowerSection .empowerContent {
        position: absolute;
        width: 100%;
        left: 0;
        top: 14.5%;
        text-align: left;
    }
    .empowerSection .empowerContent h1 {
        color: var(--neutral-charcoal-black, #1D1D1B);
        font-family: Woodman-HeavyRounded;
        font-size: 3.4vw;
        font-weight: 400;
        font-style: normal;
        line-height: 5vw;
        margin: 0;
    }
    .empowerSection .empowerContent p {
        color: var(--neutral-charcoal-black, #1D1D1B);
        font-family: Uniform;
        font-size: 1.38vw;
        font-weight: 400;
        line-height: 3vw;
        -webkit-text-stroke-width: 0.2px;
        margin: 0;
        margin-bottom: 32px;
        margin-top: 12px;
    }
    .empowerSection .empowerContent .getStart_btn {
        background: #00637e;
        border-radius: 4px;
        box-shadow: 0 2px 4px 0 rgba(0,139,178,.12);
        cursor: pointer;
        padding: 12px;
        color: white;
        width: max-content;
    }
    .empowerContent .getStart_btn p{
        margin: 0;
        color: var(--neutral-pure-white, #FFF);
        text-align: center;
        font-family: Uniform;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .DemostrateText{
        width: 35ch;
    }

    .empowerVideo{
        width: 50%;
        height: 700px;
        object-fit: cover;
        margin-left: 36.5%;
        margin-top: -98px;
    }

    .empowerVideo::after{
        content: "ggggg";
        position: absolute;
        left: 0;
        bottom: 50%;
        height: 200px;
        width: 200px;
        background-size: contain;
        background-image: url(../../assets/homeImages/videoRightImg.svg);
    }

    .videoRightImg{
        position: absolute;
        height: 44%;
        left: 75%;
    }

    .videoBottomImg{
        position: absolute;
        height: 19%;
        bottom: 0%;
        left: 29%;
    }

        /* Screen For 1680*1050 */
    @media screen and (min-width: 1621px) and (max-width: 1820px) {
        .empowerimg_bgImg {
            left: 32.5%;
            height: 53rem;
         } 
        .empoweringChange-section h1 {
            font-family: Woodman-HeavyRounded;
            font-weight: 400;
            font-size: 52.7px;
            text-align: left;
            /* letter-spacing: 2px; */
            padding-top: 10px;
        }
        .empoweringChange-section p {
         color: var(--neutral-charcoal-black, #1D1D1B);
         font-family: Uniform;
         font-size: 20px;
         font-style: normal;
         font-weight: 400;
         line-height: 40px;
        }
        .empoweringChange-section {
            display: flex;
            flex-direction: row;
            margin: auto;
            width: 84%;
            padding-bottom: 40px;
        }
        .getStarted_btn {
            padding: 1px 0px;
            width: 15%;
        }
     }

      /* Screen For 1440*900 */
    @media screen and (min-width: 1281px) and (max-width: 1620px) {
        .empowerimg_bgImg {
            left: 30.7%;
            height: 48rem;
         } 
         .empoweringCol1 {
            padding-top: 5px;
         }
        .empoweringChange-section h1 {
            font-family: Woodman-HeavyRounded;
            font-weight: 400;
            font-size: 47.6px;
            text-align: left;
            padding-top: 10px;
            margin: 1rem 0rem;
        }
        .empoweringChange-section {
            display: flex;
            flex-direction: row;
            margin: auto;
            width: 84% !important;
            height: auto !important;
            padding-bottom: 40px;
          }
        .getStarted_btn {
            padding: 1px 0px;
            width: 18%;
        }
    }

    /* Screen For 1280*1024 */
    @media screen and (max-width: 1280px) {
    .empoweringChange-section {
        display: flex;
        flex-direction: row;
        margin: auto;
        width: 84%;
        padding-bottom: 30px;
    }
    .empowerimg_bgImg {
        left: 30%;
        height: 40rem;
     } 
    .empoweringChange-section h1 {
        font-weight: 400;
        font-size: 37px;
        text-align: left;
        padding-top: 10px;
    }
    .empoweringChange-section p {
        font-family: Uniform;
        font-size: 14px;
        font-weight: 400;
        text-align: left;
        line-height: 40px;
    }
    .getStarted_btn {
        padding: 1px 0px;
        width: 18%;
    }
    .empoweringCol1 {
        padding-top: 20px;
        width: 60%;
    }

    .empowerSection img {
        padding-left: 48px;
    }
   }

    /* Screen For 1024*600 */
    @media screen and (min-width: 992px) and (max-width: 1242px) {
        .empowerVideo{
            height: 680px;
        }
        .empowerimg_bgImg{            
            left: 28%;
            height: 33rem;
            width: 70%;
        }
        .empoweringChange-section {
            display: flex;
            flex-direction: row;
            margin: auto;
            width: 90%;
        }
        .empoweringCol1 {
            padding-top: 4px;
        }
        .empoweringChange-section h1 {
            font-size: 38px;
            text-align: left;
            padding-top: 0px;
            margin: 0;
        }
        .empoweringChange-section p {
            font-size: 16px;
            line-height: 27px;
            text-align: left;
        }
        .empowerSection img {
            padding-left: 38px;
        } 
        .videoRightImg {
            height: 30%;
        }
    }

    /* Screen For 768*1024 */
    @media screen and (min-width: 768px) and (max-width: 992px) {
        .empowerVideo{
            height: 510px;
        }
        .empoweringCol1 {
            padding-top: 0px;
            padding-left: 3rem;
        }
        
        .empoweringChange-section h1 {
            font-size: 20px;
            text-align: left;
            padding-top: 10px;
        }
        .empoweringChange-section p {
            font-size: 12px;
            text-align: left;
            line-height: 22px;
        }
        .empoweringChange-section {
            display: flex;
            flex-direction: row;
            margin: auto;
            width: 90%;
        }
        .empowerimg_bgImg{            
            left: 23%;
            height: 33rem;
            width: 70%;
        }
        .getStarted_btn {
            padding: 1px 0px;
            width: 30%;
        }
        .videoRightImg {
            left: 70%;
            height: 35%;
        }
        .videoBottomImg{
            left: 22%;
        }
    
    }

    /* Screen For 600*1024 */
    @media screen and (max-width: 767px) {
        .empowerVideo{
            height: 435px;
        }
        .empoweringCol1 {
            padding-top: 0px;
            padding-left: 2rem;
            margin-bottom: 15%;
        }
        .empoweringChange-section h1 {
            font-size: 20px;
            text-align: left;
            padding-top: 0px;
            margin: 0;
        }
        .empoweringChange-section p {
            font-size: 10px;
            line-height: 20px;
            text-align: left;
            letter-spacing: 0px;
            padding: 0px;
        }
        .empoweringChange-section {
            display: flex;
            flex-direction: row;
            margin: auto;
            width: 90%;
        }
        .empowerimg_bgImg{            
            left: 25%;
            height: 20rem;
            width: 70%;
        }
        .getStarted_btn {
            padding: 1px 0px;
            width: 30%;
            margin-top: 10px;
        }
        .videoRightImg {
            left: 65%;
            height: 35%;
        }
        .videoBottomImg{
            left: 17%;
            height: 16%;
        }
    }

    @media screen and (max-width: 575px) {
        body {
            display: block;
            margin: 0px;
        }
        .empowerVideo {
            height: 365px;
        }
        .mobTopContainer {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: auto;
            width: 90% !important;
            overflow: hidden;
        }
        .empoweringChange-section {
            display: flex;
            margin: auto;
            width: 90%;
            height: 65vh;
            flex-direction: column;
            align-items: center;
            padding-bottom: 0px;
        } 
         .empoweringCol1 {
            width: 100%;
            z-index: 2;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-top: 20px;
            padding-left:0;
            margin-bottom: 0px;
         }
         .empowerContent .getStart_btn p{
            margin: 0 !important;
         }
         
        .empoweringChange-section h1 {
            font-size: 23px;
            text-align: center;
            letter-spacing: 2px;
            padding-top: 0;
            line-height: 34px;
        }
        .empowerimg_bgImg {
            height: 40vh;
            width: 100%;
            position: revert;
        }
        .empoweringChange-section p {
            font-size: 12px;
            text-align: center;
            letter-spacing: 1px;
            line-height: 1.5;
        }
        .getStarted_btn {
            display: flex;
            padding: 1px 0px;
            width: 30%;
            align-items: center;
            justify-content: center;
            margin-bottom: 40px;
        }

        .empowerSection {
            text-align: center;
            margin-bottom: 0px;
        }
        .empowerSection .empowerContent p{
            font-size: 1.80vw;
        }
        .videoRightImg {
            left: 68%;
            top: 6%;
            height: 30%;
        }
        .videoBottomImg {
            left: 15%;
            height: 16%;
        }
        
    }
 /* ----------------------------------------------400----------------------------------------- */
    
 @media (min-width: 300px) and (max-width: 400px) {
    body {
        display: block;
        margin: 0px;
    }
    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: auto;
        width: 75%;
    }
    .empowerVideo {
        height: 350px;
    }
    .empoweringChange-section {
        display: flex;
        margin: auto;
        width: 90%;
        height: 70vh;
        flex-direction: column;
        align-items: center;
    } 
     .empoweringCol1 {
        width: 100%;
        z-index: 2;
     }
     
    .empoweringChange-section h1 {
        font-size: 20px;
        text-align: center;
        letter-spacing: 2px;
        padding-top: 0;
    }
    .getStarted_btn {
        width: 35%;
    }
    .empowerimg_bgImg {
        background-position: 50%;
        margin-top: 0px;
        height: 40vh;
        width: 100%;
        position: revert;
    }
    .empoweringChange-section p {
        font-size: 13px;
        text-align: center;
        letter-spacing: 0px;
    }
    .videoRightImg {
        left: 65%;
        top: 6%;
        height: 30%;
    }
}