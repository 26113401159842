
.textAreaDiv {
    width: -webkit-fill-available;
    text-align: left;
}

.textAreaLabel {
    color: #686866 !important;
    font-family: Uniform;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 10px;
    text-align: left;
  }

  .textarea {
    color: var(--neutral-charcoal-black, #1D1D1B);
    border-radius: 4px !important;
    border: 2px solid #B9B9B8 !important;
    padding: 10px 20px !important;
    width: -webkit-fill-available !important;
    min-height: 80px !important;
    margin-top: 5px;
    resize: none !important;
    outline: none !important;
    font-family: Uniform;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .textarea::placeholder {
    color: var(--neutral-charcoal-black, #1D1D1B);
    font-family: Uniform;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    opacity: 0.2;
    -webkit-text-stroke-width: 0.2px;
  }

  .textarea:focus {
    border: 2px solid #008BB2 !important;
    box-shadow: 0 0 8px 0 rgba(138, 202, 220, 1) !important;
  }
  
   .textarea.error {
    border: 2px solid #F45B69 !important;
    box-shadow: 0 0 4px 0 rgba(244, 91, 105, 0.5) !important;
    color: #1D1D1B !important;
  }

  .exterPartnerCol2 .textAreaDiv{
    text-align: left;
    width: 14.4vw;
    max-width: 32%;
  }