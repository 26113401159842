
  .login_section {
   background: linear-gradient(175deg,#00141a -18.79%,#003a4b 102.02%);
   display: flex;
   flex-direction: row;
   align-items: stretch;
   margin: auto;
   width: -webkit-fill-available; 
   min-height: 100vh ;
   overflow: hidden; 
  }
  .loginSec_Col1 {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: space-between;
    justify-content: space-between;
    width: 65%;
    padding: 2% 0% 2% 8%;
  }
 .login_Col2 {
  background-color: #fff;
  padding: 0 5%;
  width: 35%;
  display: flex;
  flex-direction: column;
  justify-content: center;
 }
 .loginCopy p{
  margin: 0;
  color: var(--neutral-pure-white, #FFF);
  font-family: Uniform;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  opacity: 0.3;
 }

 .login_fullname{
  width: 100%;
 }
 
.loginSec_Col1 h1{
  color: var(--primary-aqua-blue-200, #8ACADC);
  font-family: Uniform;
  font-size: 64px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}
 .loginSec_Col1 h2{
  color: var(--neutral-pure-white, #FFF);
  font-family: Uniform;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
  -webkit-text-stroke-width: 1px;
 }
 .login_divContents {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  width: 60%;
}

 .loginSec_Col1 .login_divContents p{
   font-family: Uniform;
   font-size: 14px;
   font-style: normal;
   font-weight: 100;
   line-height: 24px;
   opacity: 0.8;
  }
 .loginSec_Col1 p{
   color: var(--neutral-pure-white, #FFF);
   font-family: Uniform;
   font-size: 14px;
   font-style: normal;
   font-weight: 100;
   line-height: 24px;
  }  
  .signIn_password{
    position: relative;
  }
  
  .position{
    position: relative;
    margin-bottom: 1rem;
  }
  .login_logoDiv img {
    width: 112.678px;
    height: 44px;
  }
   .login_divContents{
      width: 60%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
   }

  .login_Col2 p {
   color: var(--neutral-charcoal-black, #1D1D1B);
   font-family: Uniform;
   font-size: 12px;
   font-style: normal;
   font-weight: 400;
   line-height: 25px;
   margin: 0;
  }
  .login_section .login_Col2 h1 {
   color: var(--neutral-charcoal-black, #1D1D1B);
   font-family: Uniform;
   font-size: 28px;
   font-weight: 900;
   text-align: left;
   margin: 0 0 10px 0;
   -webkit-text-stroke-width: 1px;
  }

  .loginBtn {
   background: #008BB2;
   border-radius: 4px;
   box-shadow: 0 2px 4px 0 rgba(0,139,178,.12);
   justify-content: center;
   width: 100%;
  }
 .loginBtn .login_BtnText {
   font-family: Uniform;
   color: #fff;
   font-size: 15px;
   font-weight: 500;
   padding: 12% 10%;
   text-align: center;
   margin: 0;
  }
 
  .login_form{
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .login_topBg{
   position: absolute;
   right: -5%;
   top: 0%;
   opacity: 0.2;
  }
  .login_bottomBg{
   height: 25%;
   position: absolute;
   right: 20%;
   bottom: 0%;
   opacity: 0.2;
  }
  .login_leftBg{
   position: absolute;
   left: 0%;
   top: 40%;
   opacity: 0.2;
  }

  .alreadyHave p{
    color: var(--neutral-charcoal-black, #1D1D1B);
    font-family: Uniform;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: center;
   }
   .alreadyHave{
    display: flex;
    justify-content: space-between;
   }
   .alreadyHave span{
    color: #008BB2;
    font-family: Uniform;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
   }
   .alreadyHave .forgotPassword_text{
    color: #008BB2;
    font-family: Uniform;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
   }

   .loading-spinner-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000; 
  }

  .blur-background {
    filter: blur(5px);
    pointer-events: none; 
  }
  


 /* Screen For 1280*1024 */
  @media screen and (max-width: 1280px) {
    .loginSec_Col1 h1{
      font-size: 60px;
    }
    .login_divContents {
      width: 75%;
    }
  } 

   /* Screen For 1024*600 */
  @media screen and (min-width: 992px) and (max-width: 1242px) {}


  @media screen and (min-width: 600px) and (max-width: 992px) {
    .login_section {
      display: flex;
      flex-direction: column;
    }
    .loginSec_Col1 {
      width: -webkit-fill-available;
      padding: 40px 40px;
    }
    .login_Col2 {
      width: -webkit-fill-available;
      padding: 40px 40px;
      display: block;
      min-height: 55vh;
    }
    .loginCopy{
      display: none;
    }
  }

  @media screen and (max-width: 575px) {
    .login_section {
      display: flex;
      flex-direction: column;
    }
    .login_logoDiv img {
      width: 100px;
      height: 44px;
    }
    .loginSec_Col1 h1{
      font-size: 35px;
      padding-top: 20px;
    }
    .login_divContents {
      width: 100%;
    }
    .loginSec_Col1 {
      width: -webkit-fill-available;
      padding: 30px 30px;
    }
    .login_Col2 {
      width: -webkit-fill-available;
      padding: 30px 30px;
      display: block;
      min-height: 55vh;
    }
    .loginCopy{
      display: none;
    }
    .login_Eyeicon_position {
      padding: 7px;
    }
  }



 