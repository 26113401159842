
.reqUserDetailContainer th{
    color: #686866;
    font-family: Uniform;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    -webkit-text-stroke-width: 0.3px;
    border: none;
    text-align: left;
    padding: 9px 12px;
}

.reqUserDetailContainer td{
    overflow: hidden;
    color: var(--neutral-charcoal-black, #1D1D1B);
    text-overflow: ellipsis;
    font-family: Uniform;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 9px 12px;
}

.reqUserDetailContainer th, table tr:not(:last-child) td {
    width: -webkit-fill-available;
    border: none;
    padding: 9px 12px;
}

.reqUserDetailTitle{
    border-radius: 4px;
    background: #EEE;
}

.listedUser{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 7px;
}

.listedUser img{
    width: 28px;
    height: 28px;
}

.userGmailData{
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    text-align: left;
}

.listedUser h1{
    overflow: hidden;
    color: var(--neutral-charcoal-black, #1D1D1B);
    text-overflow: ellipsis;
    font-family: Uniform;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    -webkit-text-stroke-width: 0.2px;
    margin: 0;
}

.listedUser h3{
    overflow: hidden;
    color: var(--primary-aqua-blue, #008BB2);
    text-overflow: ellipsis;
    font-family: Uniform;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    -webkit-text-stroke-width: 0.2px;
    margin: 0;
}

.reqUserList{
    border-radius: 4px;
    border-bottom: 1px solid #b9b8b824;
    background: #FFF;
}

.reqUserList td p{
    overflow: hidden;
    color: var(--neutral-charcoal-black, #1D1D1B);
    text-overflow: ellipsis;
    font-family: Uniform;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    -webkit-text-stroke-width: 0.2px;
    margin: 0;
    text-align: left;
    width: 110px;
    overflow-wrap: anywhere;
}

.reviewProfile p{
    color: var(--Primary-Aqua-Blue, #008BB2) !important;
    font-family: Uniform;
    font-size: 12px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    -webkit-text-stroke-width: 0.2px;
    margin: 0;
    text-align: left;
}

