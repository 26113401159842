.currentRatingCon{
    display: flex;
    flex-direction: row;
    padding: 16px 20px;
    gap: 24px;
    background: #1D1D1B;
    border-radius: 10px;
}

.currentRatSec{
    display: flex;
    flex-direction: column;
    gap: 23px;
}

.currentRatSec h1{
    color: #FFF;
    font-family: Uniform;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
    -webkit-text-stroke-width: 0.5px;
}

.currentRatSec h2{
    color: #71C586;
    font-family: Uniform;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
}

.currentRatSec p{
    color: #8ACADC;
    font-family: Uniform;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
}