 
  .selectItem {
    text-align: left;
   }
   .selectItem .input_label {
    color: #686866;
    font-family: Uniform;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 10px;
    text-align: left;
  }
  .selectItem input {
    width: -webkit-fill-available;
    border-radius: 4px;
    padding: 10px 11px;
    margin-top: 5px;
    outline: none;
    border: none;
  }

  .css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
    padding-right: 32px;
    color: var(--neutral-charcoal-black, #1D1D1B);
    font-family: Uniform;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

  .css-z532jj-InputComponent {
    height: 0px !important;
  }

  .css-wmy1p7-ReactDropdownSelect {
    padding: 8px 11px !important;
    border: none !important;
  }

  .css-87f87s:hover .MuiOutlinedInput-notchedOutline {
    border: none !important;
   }

   .css-igs3ac{
    border: none !important;
   }

  .Home_selectBox { 
    position: relative;
    background-color: #ffffff;
    border-radius: 4px;
    margin-top: 5px;
    font-size: 15px;
    font-weight: 400!important;
    outline: none;
    border: 2px solid #B9B9B8;
    height: 43px;
  }

  .Home_selectBox img{
    position: absolute;
    right: 5%;
    bottom: 33%;
  }

  .Home_selectBox select{
    -moz-appearance:none;
    -webkit-appearance:none;
    appearance:none;
    width: 100% !important;
    border: none !important;
    outline: none !important;
    padding: 13px 16px;
  }

  .Home_selectBox:focus {
    border: 2px solid #008BB2;
    box-shadow: 0 0 8px 0 rgba(138, 202, 220, 1);
  }
  
  .Home_selectBox.error {
    border: 2px solid #F45B69;
    box-shadow: 0 0 4px 0 rgba(244, 91, 105, 0.5);
  }
  
.Home_selectBox select option {
  color: #1D1D1B; 
  width: 100%;
  padding: 20px;
  margin-top: 20px;
}

.Home_selectBox option .selectColor{
  color: #b9b8b84f; 
}

select option:first-child {
  color: #1D1D1B;
   opacity: 0.2;
}

.dropdown-list {
  position: absolute;
  width: -webkit-fill-available;
  list-style: none;
  padding: 0;
  border: 2px solid #B9B9B8;
  border-top: none;
  border-radius: 0 0 4px 4px;
  margin: 0;
  top: 42px;
  z-index: 3;
}

.dropdown-list li {
  background-color: #ffffff;
  padding: 10px;
  cursor: pointer;
}

.dropdown-container input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.dropdown-container ul {
  display: none;
  position: absolute;
  width: 100%;
  border: 1px solid #ccc;
  background-color: #fff;
  border-top: none;
  list-style: none;
  padding: 0;
  margin: 0;
}

.dropdown-container ul li {
  padding: 10px;
  cursor: pointer;
}

.dropdown-container input:focus + ul,
.dropdown-container ul:hover {
  display: block;
}

.css-5sivsg-MuiButtonBase-root-MuiMenuItem-root{
  color: var(--neutral-charcoal-black, #1D1D1B) !important;
  font-family: Uniform !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
}


