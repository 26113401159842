.creditEmissionCon{
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: -webkit-fill-available;
}

.creditEmissTitle{
    color: #1D1D1B;
    font-family: Uniform;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
    -webkit-text-stroke-width: 0.5px;
}

.creditEmissRow{
    display: flex;
    gap: 40px;
}