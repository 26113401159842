.leadAndSupportSec{
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-self: stretch;
    border-radius: 8px;
    background: #FAFAFA;
}

.leadAndSupportSec .leadSupportText{
    color: #1D1D1B;
    font-family: Uniform;
    font-size: 12px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    margin: 0;
    -webkit-text-stroke-width: 0.1px;
}

.leadSupportRow{
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.leadRow{
    display: flex;
    flex-direction: column;
}

.leadCol1{

}