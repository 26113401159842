
.dashboardSection {
    display: flex;
    background: linear-gradient(175deg,#00141a -18.79%,#003a4b 102.02%);
    height: 100%;
    width: 100%;
    color: white;
    flex-direction: column;
    justify-content: center;
  }
  .dashtitleDiv{
   position: relative;
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: center;
   width: 90%;
   margin-bottom: 1.5rem;
  }
  .dashUl{
   display: flex;
   justify-content: space-around;
  }
  .dashUl li{
   color: var(--neutral-pure-white, #FFF);
   text-align: center;
   font-family: Uniform;
   font-size: 13px;
   font-weight: 400;
   line-height: 20px;
   list-style: none;
   margin-left: 1rem;
  }
  .logoSec{
   display: flex;
   flex-direction: row;
  }
  .logoSec img{
   width: 93px;
  }
  .dashProfil{
   display: flex;
   flex-direction: row;
   justify-content: flex-end;
   align-items: center;
}

.dashProfil p{
   color: var(--primary-aqua-blue-100, #B0DBE7);
   text-align: center;
   font-family: Uniform;
   font-size: 12px;
   font-style: normal;
   font-weight: 400;
   line-height: normal;
}
.city{
   display: flex;
   align-items: center;
}
.rightBorder{
   display: flex;
   border-right: 1px solid #ffffff;
   height: 3vh;
   margin-left: 1rem;
   margin-right: 1rem;
   opacity: 0.2;
}
.city p{
   margin-right: 10px;
}
.city img{
   width: 100%;
}
.profileImg img{
   height: 36px;
   border: 1px solid #008BB2;
   border-radius: 20px;
}
  .dashboardInner{
   border-radius: 8px;
   background-color: #ffffff;
   /* height: 80vh; */
   /* height: 72vh; */
   width: 90%;
   padding: 40px 40px;
  }

   .welcome{
      color:#4A4A49 ;
      font-family: Uniform;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin: 0;
   }
   .welcomeDiv{
      padding: 0;
      margin-bottom: 32px;
   }
   
   .userName{
      color:#003A4B;
      font-family: Uniform;
      font-size: 30px;
      font-style: normal;
      font-weight: 800;
      line-height: normal;
      margin: 0;
   }
   .dashTimeContainer{
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 8px;
    background: #FFF;
    box-shadow: 0px 4px 40px 0px #E8E8E8;
    padding: 24px 0;
   }
   .ratingStatus{
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0 24px;
      width: 97%;
   }
   .ratingCo1{
      width: 50%;
      display: flex;
      align-items: center;
   }
   .ratingCo2{
      width: 50%;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
   }

   .ratingCo1 p{
      color: #003A4B;
      text-align: center;
      font-family: Uniform;
      font-size: 18px;
      font-style: normal;
      font-weight: 800;
      line-height: 20px;
      margin: 0;
   }
   .updateReqBtn:hover {
      background-color: none;
      color:none;
    }

   .updateReqBtn{
      height: 3vh;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
   }
   .updateReqBtn p{
      font-size: 13px;
   } 

   .updateReqBtn button{
      padding:8px 6px;;
   }

   .updateBtn{
      display: flex;
      padding: 8px 6px;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      background: #008BB2;
      z-index: 10;
   }
   .updateBtn p {
      padding: 0px;
      font-size: 13px;
      font-weight: 800;
      text-align: center ! important;
  }
  .updateBtn img{
   width: 10%;
  }

 .topbg{
   position: absolute;
   top: 66px;
   right: 42%;
  }
  .whteTest button{
   color: white !important;
  }

  .rightbg{
   position: absolute;
   top: 11%;
   right: 0;
  }
  .declaration{
   color: #1D1D1B;
   font-size: 15px;
   font-weight:400;
   padding: 0 24px;
   margin: 0;
   margin-top: 8px;
   margin-bottom: 24px;
  }
  .dashboardCopy{
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   padding: 1rem;
   width: 90%;
}

.dashboardCopy p{
   color: #FFF;
   font-size: 14px;
   font-weight: 400;
   opacity: 0.4;
 }

 .LogOutContainer{
   cursor: pointer;
   background-color: #003a4b;
   position: absolute;
   border-radius: 8px;
   padding: 0px 19px;
   width: 12%;
   top: 10%;
   right: 6%;
 }

.dashCurrentStatus{
   margin-top: 32px;
   display: flex;
   gap: 32px;
}

.addConSection{
   margin-bottom: 32px;
}





