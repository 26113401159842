.timelineData{
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available;
}

.trakingDataRow{
    display: flex;
    flex-direction: row;
    width: -webkit-fill-available;
    gap: 8px;
}

.trakingDataCol1{
    width: 25%;
    display: flex;
    justify-content: flex-start;
}

.trakingDataCol2{
   width: 75%; 
}

.trakingDataCol1 h1{
    margin: 0;
    color: #4A4A49;
    font-family: Uniform;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    -webkit-text-stroke-width: 0.3px;
}

.trakingDataCol2 p{
    margin: 0;
    color: #4A4A49;
    font-family: Uniform;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    -webkit-text-stroke-width: 0.5px;
}