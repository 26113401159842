.regTimeTitle{
  color: #fff !important;
}

.timlineActiveTitle{
  color: #2BA84A;
}
.css-ha3bif-MuiTimelineItem-root {
  list-style: none;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  min-height: 7rem !important;
}

.greenText{
  color: #2BA84A;
}

.css-v6mo1y-MuiTimelineDot-root {
  padding: 6px !important;
  background-color: #ffffff !important;
}
.css-18y2uza-MuiTypography-root-MuiTimelineContent-root {
  color: #ffffff;
  margin: 0;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  /* -webkit-flex: 1; */
  -ms-flex: 1;
  flex: 1;
  padding: 6px 16px;
  text-align: left;
  width: 20%;
}
.css-ha3bif-MuiTimelineItem-root:before {
  padding: 0px !important;
}
.activeLine {
  background-color: #008BB2 !important;
}

.form-timeline {
  background-color: transparent;
  padding-top: 2rem;
  width: 100%;
}

.timeline::before {
  content: "";
  position: absolute;
  display: block;
  left: 9px;
  top: 22px;
  height:78px;
  width: 2px;
  background: white;
  /* background: linear-gradient(to top, white 0%, white 58%, blue 58%, blue 100%); */
}

.complete::before {
  content: "";
  position: absolute;
  display: block;
  left: 9px;
  top: 22px;
  height: 78px;
  width: 2px;
  background: #008BB2;
}
/* .complete::before p{
   color: red;
} */
.green_CheckBox{
  /* content: "";
  position: absolute;
  display: block;
  left: 9px;
  top: 22px;
  height: 110px;
  width: 2px;
  background: #ffffff; */
}

.green_CheckBox{
  position: relative;
  width: 20px;
  height: 20px;
  background-color: #2BA84A;
  border-radius: 100%;
  color: #ffffff;
}

.nonActiveTimeLine{
  columns: #DDDDDD;
  opacity: 0.5px;
}

.form-timeline ul {
  width: 205px;
  list-style: none;
  margin: 0;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 25px 1px;
  padding: 0;
}
/* .form-timeline ul li:first-child input[type="radio"]:before {
    background-color: blue;
} */
.form-timeline ul li:nth-child(1) input[type="radio"]:checked:before {
  background-color: blue;
}
.form-timeline ul li:nth-child(2) input[type="radio"]:checked:before {
  background-color: blue;
}
.form-timeline ul li:nth-child(3) input[type="radio"]:checked:before {
  background-color: blue;
}
.form-timeline ul li:nth-child(4) input[type="radio"]:checked:before {
  background-color: blue;
}
/* .form-timeline ul input[type="radio"]:before {
  content: "";
  position: absolute;
  display: block;
  width: 14px;
  height: 14px;
  background-color: white;
  border: 4px solid white;
  outline: 2px solid #0000ffa3;
  border-radius: 50%;
  left: 0;
  z-index: 10;
} */
.form-timeline li {
  display: flex;
  align-items: center;
  gap: 12px;
  width: max-content !important;
}

.timLineText{
  font-family: Uniform !important;
  font-size: 18px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
}
