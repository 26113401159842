.emiDecl_section {
  background: linear-gradient(175deg,#00141a -18.79%,#003a4b 102.02%);
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin: auto;
  width: -webkit-fill-available ; 
  min-height: 100vh ;
  overflow: hidden;
  padding: 16px 8px;
  gap: 20px;
  }
  .emissionDec_titleDiv{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 32px;
   }
   
   .emissionDecform {
    width: 100%;
   }  
   .emiDecLogo{
    width: 40%;
   }
   .emisDec_titleDiv{
    width: 20%;
   }
   .emisDec_buttonDiv{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 40%;
    gap:20px;
   }
  
  .emisDec_titleDiv p{
    color:#E6F3F7;
    text-align: center;
    font-family: Uniform;
    font-size: 22px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
   }

   .datefield{
    display: flex;
    flex-direction: row;
    gap: 2.8%;
   }
   .dateCalender{
    width: 24%;
   }
   .genEmail{
    width: 35%;
   }
   .genPhone{
    width: 35%;
   }
   
   

 

    .doesReportForm{
      gap:2%;
    }
    .doesReportForm1{
      width: 49%;
    }
    .doesReportForm2{
      display: flex;
      flex-direction: row;
    }

    .doesReportFormp{
      font-size: 12px;
    }

    .orgEquityShare{
      margin-bottom: 10px;
      gap:50px;
      display: flex;
     }
     .operScopeCheck{
      margin-top: 10px;
      margin-bottom: 10px;
      gap:50px;
      display: flex;
     }
     .typeIncluded{
      margin-bottom: 10px;
     }

     .determindPolicy{
      margin-top:10px;
     }
     .bsYrHeading{
      margin-top: 10px;
     }
     .methHeading{
      margin-bottom: 10px;
     }
     .repoComHeading{
      margin-top: 20px;
      margin-bottom: 20px;
     }
  
     .emisdisagHeading{
      margin-top: 10px;
     }
     .emisConHeading{
      margin-top: 20px;
     }
     .ghgHeading{
      margin-bottom: 10px;
     }
     .suppPdf{
      margin-bottom: 20px !important;
     }

   .emisDec_buttonDiv p{
     color:#E6F3F7 !important;
     text-align: center;
     font-family: Uniform;
     font-size: 15px;
     font-style: normal;
     font-weight: 400;
     line-height: normal;
   }
   .emisCancelBtn{
    height: fit-content;
   }
   .emisSaveBtn{
    height: fit-content;
   }
   .emisSaveBtn p{
    border: 1px solid #ffffff;
    border-radius: 5px;
    }
   .emisSubmitBtn{
    height: fit-content;
   }

  .emiDecl_innerContainer{
    padding: 2rem 4rem;
    display: flex;
    width: -webkit-fill-available;
    gap: 32px;
    border-radius: var(--corner-radius-corner-radius-large, 20px);
    background: #F7F7F7;
    box-shadow: 0px 0px 8px 0px rgba(29, 29, 27, 0.20);
    align-items: stretch;
    align-items: flex-start;
  }
  .emissionDecSec_Col1 {
    display: flex ;
    flex-direction: column ;
    justify-content: center ;
    width: 25%;
  }
  .emissionDecSec_Col2 {
    padding: 24px;
    align-items: flex-start;
    width: 75%;
    display: flex;
    flex-direction: column;
    border-radius:8px;
    gap: 40px;
    flex: 1 0 0;
  }

  .emisDec_NowNextStep{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;
    margin-bottom: 1rem;
  }

  .emisDec_NowNextStep .nextStep{
    width: 100%;
    justify-content: space-between;
    display: flex;
    flex-direction: row;
    margin-bottom: 0.5rem;
  }
  .emisDec_NowNextStep .nextStep p{
    color: #1D1D1B;
    font-family: Uniform;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 0;
    -webkit-text-stroke-width: 0.2px;
  }

  .emisDec_NowNextStep .emisAudit{
    width: 100%;
    justify-content: space-between;
    display: flex;
    flex-direction: row;
  }
  .emisDec_NowNextStep .emisAudit p{
    color: #1D1D1B;
    font-family: Uniform;
    font-size: 14px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    margin: 0;
  }



  .leftSecP{
    color: #1D1D1B;
    font-weight: 800;
  }
  .mandatoryRow{
    display: flex;
    flex-direction: row;
  }
  .leftSecFrst{
    width: 100% !important;
    display: flex !important;
    justify-content: space-between !important;
  }

  .NextBtn{
    display: contents;
  }

  .emissionDecSec_Col1 h2{
    color: var(--neutral-pure-white, #FFF);
    font-family: Uniform;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
  }
  .emissionDecSec_Col1 .basicDetails_timeLineContents p{
    font-family: Uniform;
    font-size: 16px;
    font-style: normal;
    font-weight: 100;
    line-height: 24px ;
   }
  
  .basicDetails_logoDiv img {
    width: 112.678px;
    height: 44px;
   }

  .haveAnyfacility{
    display: flex;
    gap:50px
  }

  .emiDecl_section .emissionDecSec_Col2 h1 {
    color: var(--Neutral-Charcoal-Black, #1D1D1B);
    font-family: Uniform;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
    -webkit-text-stroke-width: 0.6px;
  }
  .bs_emailId{
    display:flex;
    align-items: center;
  }
  .bs_BtnRow{
    display:flex;
     justify-content: space-between;
  }
  .cancelBtn_Row{
    display: flex;
    justify-content: flex-end;
  }
  
  .basicDetails_SubmitBtn {
    background: #008BB2;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(0,139,178,.12);
    justify-content: center;
    width: 100% ;
  }
  .basicDetails_SubmitBtn .basic_BtnText {
    font-family: Uniform;
    color: #fff;
    font-size: 15px;
    font-weight: 500;
    padding: 12px 10px;
    text-align: center;
    margin: 0;
  }
   .signUpalready_HaveAcc {
    display: flex;
    justify-content: center;
  }
  
  .basicDetails_topBg{
    height: 19rem;
    position: absolute;
    right: -181px;
    top: 0%;
    opacity: 0.2;
   }
   .basicDetails_bottomBg{
    position: absolute;
    right: 0px;
    bottom: -30px;
    opacity: 0.2;
    /* transform: rotate(-3deg); */
   }
   .basicDetails_leftBg{
    position: absolute;
    left: 0%;
    top: 40%;
    opacity: 0.2;
   }

   .CodewaveTitleDiv{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    }

    .CodeCol1{
     width: 65%;
     display: flex;
    }

    .CodeCol2{
     width: 20%;
    }

    .CodeCol1 img{
        width: 15%;
        margin-right: 1rem;
    }

   .CodeCol1 h3{
    color: #003A4B;
     font-size: 22px;
     font-weight: 600;
     margin: 0;
   }

   .calenderMt{
    margin-top: 4px;
   }
   .calenderIcon{
    width: 25px;
    position: absolute;
    left:95%;
    top: 50%; 
   }

  .nofacility{
    margin-left: 3rem;
  }

  

  .textMb{
    margin-bottom: 1rem;
  }

  .textMt{
    margin-top: 2rem;
  }
  .repTo{
    margin-left: 3rem;
  }
  .equityColumn{
    display: flex;
    flex-direction: column;
    width: 90%;
  }


  .infoSec{
    width: 90%;
  }

  .buttonRow{
    width: 100% ;
  }

.directCo2{
  margin-top: 8px;
  margin-bottom:8px;
}
.selectBase{
  margin-bottom: 10px;
}

.scopeInput{
    width: 25%;
}


.flexColumn{
    display: flex;
    flex-direction: column;
}

.pdf-link {
  text-decoration: none;
  color: inherit;
}

.rowDiv {
    display: flex;
    flex-direction: row;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .disaggregated{
    width: 50%;
 }

 .attText{
    font-size: 16px !important;
    font-weight: 800 !important;
    color: #1D1D1B !important;
    margin-right: 1rem;
 }
 .offsetIp{
    width: 50%;
 }

 
 .emissionCopy p{
    color: #FFF;
    font-size: 14px;
    font-weight: 400;
    opacity: 0.4;
  }

  .emisDecleftTitle h1{
    color: var(--Neutral-Charcoal-Black, #1D1D1B);
    font-family: Uniform;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    margin: 0;
    -webkit-text-stroke-width: 1px;
  }
  .nextStep h3{
    color: var(--Primary-Aqua-Blue, #008BB2);
    font-family: Uniform;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
    -webkit-text-stroke-width: 0.5px;
  }

  .EmissInnerMainBlock{
    display: flex;
    padding: 0;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    border-radius: 8px;
  }


  .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded {
    min-height: 0 !important;
    margin: 0 !important;
  }

  .css-15v22id-MuiAccordionDetails-root {
    padding: 8px 16px 16px;
    display: flex !important;
    flex-direction: column !important;
    gap: 15px !important;
}

.css-1elwnq4-MuiPaper-root-MuiAccordion-root{
  display: flex !important;
  flex-direction: column !important;
  align-items: stretch !important;
  align-self: stretch !important;
  box-shadow: none !important;
  padding: 10px !important;
  border-radius: 8px !important;
}

.bottomSaveBtnRow{
  display: flex;
  align-items: center;
  gap: 20px;
}

.saveDraftButton{
  display: flex;
  padding: 12px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  border: 1px solid var(--Primary-Aqua-Blue, #008BB2);
}

.saveDraftButton p{
  color: var(--Primary-Aqua-Blue, #008BB2);
  text-align: center;
  font-family: Uniform;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
  -webkit-text-stroke-width: 0.2px;
}
   
/* .css-o4b71y-MuiAccordionSummary-content.Mui-expanded {
  margin: 20px 0;
}
   */
  
   