.peopleInvolveCon{
    padding: 24px;
    background-color: #fff;
    align-items: flex-start;
    display: flex;
    gap: 24px;
    flex-direction: column;
    border-radius: 8px;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.08);
    position: relative;
}

.peopleInvTitle{
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.peopleInvTitle h1{
    color: #003A4B;
    font-family: Uniform;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    margin: 0;
    -webkit-text-stroke-width: 0.2px;
}

.peopleInvTitle p{
    color: #1D1D1B;
    font-family: Uniform;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
    -webkit-text-stroke-width: 0.2px;
}

.peopleInvList{
    display: flex;
    flex-direction: row;
    gap: 16px;
    align-items: center;
    width: -webkit-fill-available;
    justify-content: space-between;
}

.peopleInvProfile{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
}

.peopleInvProfile img{
    height: 36px;
    border: 1px solid #008BB2;
    border-radius: 20px;
}

.nameAndDesignation{
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.nameAndDesignation h1{
    color: #1D1D1B;
    font-family: Uniform;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
    -webkit-text-stroke-width: 0.2px;
}

.nameAndDesignation p{
    color: #1D1D1B;
    font-family: Uniform;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-transform: uppercase;
    margin: 0;
    -webkit-text-stroke-width: 0.2px;
}

.peopleInvListCol1{
    display: flex;
    gap: 12px;
}

.ratingProfileMenuCon{
    position: absolute;
    left: 50%;
    bottom: -15%;
    display: flex;
    flex-direction: column;
    background: var(--Neutral-Pure-White, #FFF);
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.08);
    padding: 1px;
    border-radius: 4px;
  }
  
.ratingProfileMenuRow{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    padding: 12px 10px;
}

.ratingProfileMenuRow p{
    color: #1D1D1B;
    font-family: Uniform;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    margin: 0;
    -webkit-text-stroke-width: 0.2px;
}



