
   .regSubmitBtn{
    border: none;
    cursor: pointer;
    border-radius: 4px;
    display: flex;
    padding: 2px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    background: var(--primary-aqua-blue, #008BB2);    
   }

   .subBtnText{
      padding: 12px 10px;
      color: #FFF;
      text-align: center;
      font-family: Uniform;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
   }