    /* Who Is It For */
       .whoIs_section {
         padding-top: 60px;
         display: flex;
         flex-direction: column;
         padding-bottom: 60px;
         position: relative;
         }
         .whoIsItBackground-text{
          bottom: 0;
          font-family: 'Woodman-HeavyRounded';
          font-size: 202px;
          left: -155px;
          line-height: 200px;
          opacity: 0.09;
          pointer-events: none;
          position: absolute;
          right: 0;
          text-align: left;
          top: 5%;
          z-index: -1;
          color: transparent;
          -webkit-text-stroke-width: 1px;
          -webkit-text-stroke-color: black;
          z-index: 3;
       }
       .whoRightBg{
        position: absolute;
        right: -100px;
        bottom: 75%;
       }
       .leftLeftBg{
        position: absolute;
        left: -124px;
        bottom: 14%;    
       }
        .whoIsSec_Col1 {
            width: 60%;
        }
        .whoIsSec_Col2 {
            width: 40%;
        }
        .whoIs_section h1{
          color: var(--neutral-charcoal-black, #1D1D1B);
          margin: 0;
          margin-bottom: 32px;
          font-family:Woodman-HeavyRounded;
          font-size: 40px;
          font-weight: 400;
          text-align: justify;
        }
        .whoIs_section p{
         color: var(--neutral-charcoal-black, #1D1D1B);
          margin: 0;
          font-family: Uniform;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 28px;
          /* text-align: justify; */
          -webkit-text-stroke-width: 0.2px;
        }
        
        .whoIs_row1{
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }
        .whoIs_row2{
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }
        .whoIs_Rows{
          width: 100%;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          gap: 60px;
          justify-content: space-between;
          margin-top: 34px;
        }
        .whoIs_Cols{
          width: 30%;
          display: flex;
          flex-direction: column;
          gap: 16px;
        }
        .whoIs_Cols p{
          color: var(--neutral-charcoal-black, #1D1D1B);
          font-family: Uniform;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          text-align: justify;
          -webkit-text-stroke-width: 0.2px;
        }
        .whoIs_Cols h2{
          color: #008BB2;
          font-family: Uniform;
          font-size: 24px;
          font-style: normal;
          font-weight: 800;
          line-height: normal;
          text-align: left;
          margin: 0;
          -webkit-text-stroke-width: 0.2px;
        }
       .whoIs_Cols img{
        width: 80px;
        height: 80px;
        }
        .whoIs_headSection{
            display: flex;
            flex-direction: row;
            width: 100%;
        }
        .solutiionImage{
            padding-top: 40px;
        }
        .whoIsItMainSecion{
            margin-top: 60px;
            margin-bottom: 60px;
            background-color: #EAF2F5;
        }


    /* Screen For 1680*1050 */
    @media screen and (min-width: 1621px) and (max-width: 1820px) { }
    /* Screen For 1440*900 */
    @media screen and (min-width: 1281px) and (max-width: 1620px) { 
        .whoIs_Rows {
            gap: 55px;
        }
     }

    /* Screen For 1280*1024 */
    @media screen and (max-width: 1280px) {
        .whoIs_Rows {
            gap: 50px;
        }
        .whoIsItBackground-text {
            top: 4%;
        }
     }

    /* Screen For 1024*600 */
    @media screen and (min-width: 992px) and (max-width: 1242px) {
      
        .whoIs_section {
            padding-top: 60px;
            display: flex !important;
            flex-direction: column !important;
        }

        .whoIs_Rows {
            gap: 45px;
        }
        .whoRightBg {
            right: -40px;
            bottom: 68%;
        }

        .leftLeftBg {
            position: absolute;
            left: -54px;
            bottom: 14%;
        }

        .whoIsItBackground-text {
            top: 2%;
            left: -62px;
            font-size: 130px;
        }
        .solutiionImage{
          width: 100%;
        }
    }

    @media screen and (min-width: 768px) and (max-width: 992px) {   
        .whoIs_section {
            padding-top: 60px;
            display: flex !important;
            flex-direction: column ! important;
            padding-bottom: 35px;
        }
        .whoIsItBackground-text {
            top: 1.8%;
            left: -62px;
            font-size: 130px;
        }
        .whoIsSec_Col1 {
            width: 100%;
        }
        .whoIsSec_Col2 {
            width: 0%;
        }
        .whoIs_Rows {
            gap: 22px;
        }
        .whoRightBg {
            right: -18px;
            bottom: 69%;
            width: 29%;
        }
        .leftLeftBg {
            left: -42px;
            bottom: 14%;
            width: 17%;
        }
        .whoIs_Cols h2 {
            font-size: 18px;
        }
        .solutiionImage{
            width: 100%;
          }

    }

    @media screen and (max-width: 767px) { 
        .whoIsItMainSecion{
            margin-top: 40px;
            margin-bottom: 40px;
        }
        .whoIs_section {
            padding-top: 60px;
            display: flex !important;
            flex-direction: column !important;
        }
        .whoIsSec_Col1 {
            width: 100%;
        }
        .whoIsSec_Col2 {
            width: 0%;
        }
        .whoIsItBackground-text {
            top: 1.8%;
            left: -11px;
            font-size: 130px;
        }
        .whoIs_Rows {
            gap: 22px;
        }
        .whoIs_Cols h2 {
            font-size: 15px;
        }
        .whoRightBg {
            right: -18px;
            bottom: 77%;
            width: 29%;
        }
        .leftLeftBg {
            left: -42px;
            bottom: 14%;
            width: 17%;
        }
        .solutiionImage{
            width: 100%;
          }
    }

    @media screen and (max-width: 575px) {
        body {
            display: block;
            margin: 0px;
        }
        .whoIsItBackground-text{
            display: none;
        }
        .container.whoIs_section{
            width: 90%;
        }
    
        /* who is it */
         .whoIs_Rows {
            width: 100%;
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            gap: 0px;
            justify-content: center;
            margin-top: 34px;
            align-items: center;
        }
        .whoIs_Cols {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }
        .whoIsSec_Col1 {
            width: 100% !important;
        }
        .whoIsSec_Col2 {
            width: 0;
        }
        .whoIs_section {
            padding-bottom: 25px;
            padding-top: 15px;
        }
        .whoIs_section h1 {
            font-size: 23px;
        }
        .leftLeftBg {
            left: -3px;
        }
        .whoRightBg {
            bottom: 66%;
        }
        
    }

    /* ----------------------------------------------400----------------------------------------- */
    
    @media (min-width: 300px) and (max-width: 400px) {
        body {
            display: block;
            margin: 0px;
        }
            
      /* who is it */
        .whoIs_Rows {
            width: 100%;
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            gap: 0px;
            justify-content: center;
            margin-top: 34px;
            align-items: center;
        }
        .whoIs_Cols {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }
        .whoIsSec_Col1 {
            width: 100% !important;
        }
        .whoIsSec_Col2 {
            width: 0;
        }
        .whoIs_section {
            padding-bottom: 25px;
            padding-top: 15px;
        }
        .whoIs_section h1 {
            font-size: 23px;
        }
    
    
    }
