.creditEmissPreCon{
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: -webkit-fill-available;
}

.creditEmissPreTitle{
    color: #1D1D1B;
    font-family: Uniform;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
    -webkit-text-stroke-width: 0.5px;
}

.ghgEmissCreditPreCol{
    width: -webkit-fill-available;
    display: flex;
    flex-direction: row;
    gap: 20px;
    background-color: #FAFAFA;
}

.resultPreCredit{
    width: 25%;
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.resultPreCredit2{
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.resultCreditValue{
    display: flex;
    flex-direction: column;
    gap: 2px;
    border-radius: 8px;
    padding: 8px;
    border: 1px solid #B9B9B8;
    background-color: #FFFFFF;
}

.resultCreditTitle{
    color: #4A4A49;
    font-family: Uniform;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-transform: uppercase;
    margin: 0;
    -webkit-text-stroke-width: 0.2px;
}

.resultCreditValue h1{
    color: #1D1D1B;
    font-family: Uniform;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-transform: uppercase;
    margin: 0;
    -webkit-text-stroke-width: 0.5px;
}

.resultCreditValue p{
    color: #1D1D1B;
    font-family: Uniform;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
    -webkit-text-stroke-width: 0.5px;
}

.resultDisrip{
    color: #1D1D1B;
    font-family: Uniform;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
    -webkit-text-stroke-width: 0.3px;
}