.timelineExecutionCon{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: var(--M, 12px);
    align-self: stretch;
}

.timelineExeCol1{
    width: 20%;
}

.timelineExeCol1 h1{
    color: #1D1D1B;
    font-family: Uniform;
    font-size: 12px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    margin: 0;
    -webkit-text-stroke-width: 0.1px;
}

.timelineExeCol2{
    width: 80%;
    display: flex;
    flex-direction: row;
    gap: 60px;
    align-items: end;
}

.yearCalendarRow2{
    display: flex;
    flex-direction: row;
    gap: 12px;
}

 .timelineRightArraow{
    margin-top: 12px ;
}

