.auditInfoEmiss2Container{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--XL, 24px);
    align-self: stretch;
    border-radius: 8px;
    background: #FFF;
}

.auditInfoEmiss2Container h2{
    color: var(--Neutral-Charcoal-Black, #1D1D1B);
    font-family: Uniform;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
    -webkit-text-stroke-width: 0.5px;
}

.auditDiaggreFacilSec{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--M, 12px);
    align-self: stretch;
}

.facility2Font{
    -webkit-text-stroke-width: 0.5px !important;
}

.auditDiaggreFacilSec .auditEmisViewFacility1 h1{
    color: var(--Neutral-Charcoal-Black, #003A4B);
    font-family: Uniform;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
    -webkit-text-stroke-width: 0.3px;
}

.auditDiaggreFacilSec .auditEmisFacilRow p{
    color: var(--Neutral-Charcoal-Black, #1D1D1B);
    font-family: Uniform;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
    -webkit-text-stroke-width: 0.3px;
}

.auditDiaggreFacilSec h1{
    color: var(--Neutral-Charcoal-Black, #1D1D1B);
    font-family: Uniform;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
    -webkit-text-stroke-width: 0.5px;
}

.auditEmisViewFacility1{
    display: flex;
    padding: var(--L, 16px);
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    gap: var(--M, 12px);
    align-self: stretch;
    border-radius: 4px;
    background: #FAFAFA;
}

.auditEmisFacilRow{
    display: flex;
    flex-direction: row;
    width: 100%;
}

.auditEmisFacilCol1{
    width: 30%;
}

.auditEmisFacilCol2{
    width: 70%;
}

.auditOwnAttributSec{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--M, 12px);
    align-self: stretch;
}

.auditInfoEmiss2Container .auditOwnAttributSec h1{
    color: var(--Neutral-Charcoal-Black-500, #1D1D1B);
    font-family: Uniform;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-transform: uppercase;
    margin: 0;
}

.auditInfoEmiss2Container .auditOwnAttributSec p{
    color: var(--Neutral-Charcoal-Black, #1D1D1B);
    font-family: Uniform;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
    -webkit-text-stroke-width: 0.5px;
}

.auditEmissScope2row{
    display: flex;
    flex-direction: row;
    width: -webkit-fill-available;
}

.auditEmissScope2Col1{
    width: 50%;
}

.auditOwnAttributSec h1{
    color: var(--Neutral-Charcoal-Black, #1D1D1B);
    font-family: Uniform;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-transform: uppercase;
    margin: 0;
    -webkit-text-stroke-width: 0.3px;
}