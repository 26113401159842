.selectoffsetCon{
    width: 98% !important;
} 

.defalutSelect{
    color: #686866 !important;
    font-family: Uniform !important;
}

.selectoffsetCon .css-1rxz5jq-MuiSelect-select-MuiInputBase-input-MuiInput-input {
    padding: 10px 0px 10px 10px;
    border: 2px solid #B9B9B8;
    border-radius: 4px;
} 
.selectoffsetCon .css-m5hdmq-MuiInputBase-root-MuiInput-root-MuiSelect-root:before{
    border: none !important;
} 
.selectoffsetCon .css-m5hdmq-MuiInputBase-root-MuiInput-root-MuiSelect-root:after{
    border: none !important;
} 

.selectoffsetCon .css-1cccqvr.css-1cccqvr.css-1cccqvr {
    min-width: 16px;
    padding: 10px 0px 10px 10px !important;
    border: 2px solid #B9B9B8 !important;
    border-radius: 4px !important;
}

.selectoffsetLabel{
    color: #686866 !important;
    font-family: Uniform;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: left;
    margin-bottom: 5px;
}

.offsetEmis2 label+.css-m5hdmq-MuiInputBase-root-MuiInput-root-MuiSelect-root{
  margin: 0px !important;
}

.offsetEmis2 .css-1cccqvr.css-1cccqvr.css-1cccqvr {
    min-width: 16px !important;
    padding: 10px 0px 10px 10px !important;
    border: 2px solid #B9B9B8 !important;
    border-radius: 4px !important;
}

.offsetEmis2 .css-o3ukdw::before {
    border-bottom: none !important;
}

.offsetEmis2 .css-o3ukdw::after {
    border-bottom: none !important;
}

.offsetEmis2 label + .css-o3ukdw {
    margin-top: 0 !important;
}