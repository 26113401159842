.cmtDrawHeader{
    padding: var(--L, 16px);
    border-radius: 8px 0px 0px 0px;
    border-bottom: 1px solid #E8E8E8;
    background: var(--Neutral-Pure-White, #FFF);
}

.drawHeadRow{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
}

.drawHeadCol1{
    width: 50%;
    display: flex;
    flex-direction: row;
    gap: 8px;
}

.drawHeadCol2{
    width: 50%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;
}

.drawHeadCol1 h1{
    color: var(--Neutral-Charcoal-Black, #1D1D1B);
    text-align: center;
    font-family: Uniform;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
    -webkit-text-stroke-width: 0.5px;
 }

.ongoingBtn{
    display: flex;
    padding: 3px 6px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 4px;
    background: #F3A738;
}

.ongoingBtn p{
    color: var(--Neutral-Pure-White, #FFF);
    text-align: center;
    font-family: Uniform;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
}

.correctionBtn{
    display: flex;
    padding: 3px 6px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 4px;
    border: 0.5px solid #F3A738;
    background: #FEF6EB;
}

.correctionBtn p{
    color: var(--Secondary-Amber-Orange-900, #664618);
    text-align: center;
    font-family: Uniform;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
}

.nextBtnRow{ 
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.nextBtnCol{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
}

.nextBtnCol p{
    color: var(--Neutral-Charcoal-Black, #1D1D1B);
    text-align: center;
    font-family: Uniform;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    margin: 0;
    -webkit-text-stroke-width: 0.5px;
}

.commentDrawClose{
    display: flex;
    flex-direction: row;
}

