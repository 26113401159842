
.proAddiDetContainer{
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-self: stretch;
}

.proRegisDetails{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: -webkit-fill-available;
    gap: 3px;
}

.proRegisDetails h1{
    color: var(--Neutral-Charcoal-Black-300, #686866);
    font-family: Uniform;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    margin: 0;
    -webkit-text-stroke-width: 0.2px;
}

.profilePdfSection{
    display: flex;
    align-items: flex-start;
    align-content: flex-start;
    gap: 20px 20px;
    align-self: stretch;
    flex-wrap: wrap;
}

.proAddiDetails{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
}

.relevantInfoTitle{
    color: var(--Neutral-Charcoal-Black-300, #686866);
    font-family: Uniform;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    margin: 0;
    -webkit-text-stroke-width: 0.2px;
}

.proAddiDetSection{
    display: flex;
    padding: 12px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    border-radius: 8px;
    border: 1px solid #E8E8E8;
}

.proOtherLinks{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: -webkit-fill-available;
    gap: 3px;
}

.proOtherLinks p{
    color: var(--Neutral-Charcoal-Black-300, #686866);
    font-family: Uniform;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    margin: 0;
    -webkit-text-stroke-width: 0.2px;
}

.proOtherLinks h1{
    color: var(--Neutral-Charcoal-Black, #1D1D1B);
    font-family: Uniform;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
    -webkit-text-stroke-width: 0.5px;
}

.proLinkSection{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    gap: var(--L, 16px);
    align-self: stretch;
}

.addDetPdfRow{
    display: flex;
    align-items: flex-start;
    align-content: flex-start;
    gap: 20px 20px;
    align-self: stretch;
    flex-wrap: wrap;
}