
.addUserBreadcrumbs .adminManagText{
    color: #686866 !important;
    font-family: Uniform !important;
    font-size: 12px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: normal !important;
    text-decoration-line: none !important;
}

.addUserBreadcrumbs .adminAllUser {
    color: #008BB2 !important;
    font-family: Uniform !important;
    font-size: 12px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: normal !important;
}