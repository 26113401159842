.inProgressCon {
  width: fit-content;
  height: -webkit-fill-available;
  display: flex;
  gap: 4px;
  padding: 3px 6px;
  border: none;
  border-radius: 4px;
  background-color: #f3a738;
  outline: none;
}

.inProgressCon p {
  color: var(--neutral-pure-white, #ffffff);
  text-align: center;
  font-family: Uniform;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  list-style: none;
  margin: 0;
  -webkit-text-stroke-width: 0.2px;
}

.rejectedCon {
  width: fit-content;
  height: -webkit-fill-available;
  display: flex;
  gap: 4px;
  padding: 3px 6px;
  border: none;
  border-radius: 4px;
  background-color: #f45b69;
  outline: none;
}

.rejectedCon p {
  color: var(--neutral-pure-white, #ffffff);
  text-align: center;
  font-family: Uniform;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  list-style: none;
  margin: 0;
  -webkit-text-stroke-width: 0.2px;
}

.viewAllDetailCon {
  width: fit-content;
  height: -webkit-fill-available;
  display: flex;
  gap: 4px;
  padding: 8px;
  border: none;
  border-radius: 4px;
  border: 1px solid #008bb2;
  background-color: #ffffff;
  outline: none;
}

.viewAllDetailCon p {
  color: var(--neutral-pure-white, #008bb2);
  text-align: center;
  font-family: Uniform;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  list-style: none;
  margin: 0;
  -webkit-text-stroke-width: 0.2px;
}

.continueDecBtn {
  width: fit-content;
  height: -webkit-fill-available;
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 8px;
  border: none;
  border-radius: 4px;
  border: none;
  background-color: #008bb2;
  outline: none;
}

.continueDecBtn p {
  color: var(--neutral-pure-white, #ffffff);
  text-align: center;
  font-family: Uniform;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  list-style: none;
  margin: 0;
  -webkit-text-stroke-width: 0.2px;
}
