.yearCalendarCon{
    width: 22%;
}

.yearCalendarCon .css-4jnixx-MuiStack-root{
    overflow: hidden;
    padding-top: 1px !important;
    display: flex;
    flex-direction: column;
}

.yearCalendarCon input::placeholder{
    color: #686866 !important;
}

.yearCalendarCon .css-4jnixx-MuiStack-root>.MuiTextField-root {
    min-width: auto !important;
    margin-left: 0px !important;
}

.yearCalendarCon .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
   height: auto !important;
}

.yearCalendarLabel{
    color: #686866 !important;
    font-family: Uniform;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: left;
    margin-bottom: 5px !important;
}
