

.css-140puxv-MuiTypography-root-MuiDialogTitle-root {
    padding: 40px 40px 3px 40px !important;
}

.css-153eyq5-MuiModal-root-MuiDialog-root .MuiDialogContent-root {
    padding: 0px 40px 0px 40px !important;
    border: none !important;
}

.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    border-radius: 8px !important;
}

.popUpTitle{
    color: var(--neutral-charcoal-black, #1D1D1B);
    font-family: Uniform !important;
    font-size: 28px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: normal !important;
    -webkit-text-stroke-width: 2px;
}

.MuiDialogContent-root .MuiDialogContent-dividers .css-1t4vnk2-MuiDialogContent-root{
    display: flex;
    flex-direction: column;
    gap: 40px;
}
.popUpPara{
    color: var(--neutral-charcoal-black, #1D1D1B);
    font-family: Uniform !important;
    font-size: 11px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 20px !important;
    margin: 0 !important;
    -webkit-text-stroke-width: 0.2px;
}

.infoConsSec{
    display: flex;
    flex-direction: row;
    padding: 12px;
    align-items: flex-start;
    gap: var(--corner-radius-corner-radius-medium, 8px);
    align-self: stretch;
    border-radius: 4px ;
    background: linear-gradient(92deg, #E6F3F7 -1.28%, #FBE4C1 148.84%);
    margin-top: 5px;
}

.popUpForm{
    display: flex;
    gap: 32px;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 40px 0px 40px 0px
}

.popUpInput{
    width: 100%;
    display: flex;
    grid-gap: 30px 12px;
}

.popUpInput > .input-item {
    width: 100%;
}

.popUpInput2{
    width: 100%;
    display: flex;
    grid-gap: 30px 12px;
    flex-direction: column;
}

/* .popUpInput2 > .input-item{
    width: 46%;
}

.popUpInput2 > .input-item > .containerPhoneCountryCode {
    width: 100% !important;
}
.popUpInput2 > .input-item > .containerPhoneCountryCode > .inputPhoneContainerPhoneCountryCode {
    width: 100% !important;
}
.popUpInput2 > .input-item > .containerPhoneCountryCode > .inputPhoneContainerPhoneCountryCode > input {
    width: 80% !important;
} */

.mobileNoInput{
    width: 27%;
}

.popUpBtnRow{
    display:flex;
     justify-content: space-between;
     margin-bottom: 40px;
  }

  .popUpCancel{
    cursor: pointer;
    border: 1px solid #008BB2;
    border-radius: 4px;
    transition: background-color 0.3s, color 0.3s;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 2rem;
    padding: 12px;
    gap: 4px;
    background-color: #ffffff;
   }
   .popUpCancel p{
    color: #008BB2;
    text-align: center;
    font-family: Uniform;
    font-size: 14px !important;
    font-style: normal;
    font-weight:400;
    -webkit-text-stroke-width: 0.3px;
    line-height: normal;
    margin: 0;
   }
   .popUpCancel:hover {
    border-radius: 4px;
    border: 1px solid  #008BB2;
    background: var(--primary-aqua-blue-50, #E6F3F7);
   }

   /* CSS for country code component with just flag without country code starts here */
/* .popUpInput2 .PhoneInputCountry{
    position: relative !important;
    width: max-content;
}

.popUpInput2 .PhoneInputInput{
    display: none;
}

.popUpInput2 .PhoneInputCountrySelect{
    width: 40px;
} */
   /* CSS for country code component with just flag without country code ends here */
