.issueBtn{
    display: flex;
    align-items: center;
    background-color: #e6e6f7;
    border: 1px solid #7473d4;
    border-radius: 12px;
    padding: 2px 8px;
}

.issueBtn p{
    color: var(--Neutral-Charcoal-Black, #1D1D1B);
    font-family: Uniform;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
    -webkit-text-stroke-width: 0.2px;
}