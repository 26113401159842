

.emiDecl_section {
  background: linear-gradient(175deg,#00141a -18.79%,#003a4b 102.02%);
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin: auto;
  width: -webkit-fill-available ; 
  min-height: 100vh ;
  overflow: hidden;
  padding: 16px 8px;
  gap: 20px;
}

.emissionDec_titleDiv{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 32px;
 }

 .approveBtn {
  background-color: #00637E;
  border-radius: 4px;
  padding: 14px 16px;
  color: #ffffff;
}

.emisDec_titleDiv p{
  color:#E6F3F7;
  text-align: center;
  font-family: Uniform;
  font-size: 22px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
 }

 .appSelectBox {
  color: #ffffff;
  width: 100%;
  border: none;
  font-size: 15px;
  font-weight: 400!important;
  outline: none;
  background-color: #00637E;
}

.emisDec_buttonDiv{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 40%;
  gap:20px;
 }

.emisDec_titleDiv p{
  color:#E6F3F7;
  text-align: center;
  font-family: Uniform;
  font-size: 22px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
 }

 .codwaveLogo{
  display: flex;
  flex-direction: row;
  margin-bottom: 12px;
 }

 .InventoryYear{
  display: flex;
  flex-direction: column;
  width: 100%;
 }
 .InventoryYear p{
  margin: 0;
 }
 .InventoryYear h3{
  margin-bottom: 0.5rem;
 }

 .mandatoryRow{
  width: 100%;
 }

.addCommentDiv{
display: flex;
/* justify-content: center; */
/* margin-right: 16rem; */
}
.addComment{
color: #008BB2;
/* text-align: right; */
font-family: Uniform;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.addCommentOrg{
display: flex;
}

.finacText{
margin-top: 1rem;
}

.ghgTableSec{
width: 100%;
}
.ghgTable {
border-radius: 8px;
background: #FAFAFA;
padding: 1rem 1rem;
margin-top: 1rem;
}
.gasRow{
  display: flex;
  justify-content: center;
  align-items: center;
}

.subsiTest{
margin-top: 1rem;
}

.pdfDiv{
margin-top: 1rem;
}

.pdfComment{
display: flex;
}

.emisAttribute{
margin-top: 1rem;
}

.attriComment{
display: flex;
padding: 0rem 1rem;
}

.attriPara{
margin:0;
margin-top: 1.1rem;
}
.attriComment p{
margin:0;
margin-top: 1.1rem;
}

.emisChangesSec{
  width: 100%;
}

.revertBtn button{
  margin-right: 2rem !important;
}

.mandatoryRow{
  width: 100%;
}

.addCommentDiv{
  display: flex;
}

.addComment{
  color: #008BB2;
  font-family: Uniform;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.addCommentOrg{
  display: flex;
}

.finacText{
  margin-top: 1rem;
}

 .emisCancelBtn{
  height: fit-content;
 }
 .emisSaveBtn{
  height: fit-content;
 }
 .emisSaveBtn p{
  border: 1px solid #ffffff;
  border-radius: 5px;
  }
 .emisSubmitBtn{
  height: fit-content;
 }

.emiDecl_innerContainer{
  padding: 2rem 4rem;
  display: flex;
  width: -webkit-fill-available;
  gap: 32px;
  border-radius: var(--corner-radius-corner-radius-large, 20px);
  background: #F7F7F7;
  box-shadow: 0px 0px 8px 0px rgba(29, 29, 27, 0.20);
  align-items: stretch;
  align-items: flex-start;
}

.emissionDecSec_Col1 {
  display: flex ;
  flex-direction: column ;
  justify-content: center ;
  width: 25%  ;
}
.emissionDecSec_Col2 {
  padding: 24px;
  align-items: flex-start;
  width: 75%;
  display: flex;
  flex-direction: column;
  border-radius:8px;
  gap: 40px;
  flex: 1 0 0;
}

.emisDec_NowNextStep{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  margin-bottom: 1rem;
}

.emisDec_NowNextStep .nextStep{
  width: 100%;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  margin-bottom: 0.5rem;
}
.emisDec_NowNextStep .nextStep p{
  color: #1D1D1B ;
  font-family: Uniform;
  font-size: 12px ;
  font-style: normal;
  font-weight: 500 ;
  line-height: normal;
  margin: 0;
}

.emisDec_NowNextStep .emisAudit{
  width: 100%;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
}
.emisDec_NowNextStep .emisAudit p{
  color: #1D1D1B;
  font-family: Uniform;
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  margin: 0;
}
.leftSecP{
  color: #1D1D1B;
  font-weight: 800;
}
.Left_SectionDiv{
  background-color: #ffffff;
  display: flex;
  padding: 24px 12px 12px 12px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  align-self: stretch;
  border-radius: 8px;
}
.leftSecDiv{
  padding: 6px 0;
}
.mandatoryRow{
  display: flex;
  flex-direction: row;
}



.NextBtn{
  display: contents;
}



.emissionDecSec_Col1 h2{
  color: var(--neutral-pure-white, #FFF);
  font-family: Uniform;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
}
.emissionDecSec_Col1 .basicDetails_timeLineContents p{
  font-family: Uniform;
  font-size: 16px;
  font-style: normal;
  font-weight: 100;
  line-height: 24px ;
 }

.basicDetails_logoDiv img {
  width: 112.678px;
  height: 44px;   
 }

.bs_emailId{
  display:flex;
  align-items: center;
}
.bs_BtnRow{
  display:flex;
   justify-content: space-between;
}
.cancelBtn_Row{
  display: flex;
  justify-content: flex-end;
}

.basicDetails_SubmitBtn {
  background: #008BB2;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0,139,178,.12);
  justify-content: center;
  width: 100% ;
}
.basicDetails_SubmitBtn .basic_BtnText {
  font-family: Uniform;
  color: #fff;
  font-size: 15px;
  font-weight: 500;
  padding: 12px 10px;
  text-align: center;
  margin: 0;
}
 .signUpalready_HaveAcc {
  display: flex;
  justify-content: center;
}

.basicDetails_topBg{
  position: absolute;
  top: 0%;
  opacity: 0.2;
 }
 .basicDetails_bottomBg{
  position: absolute;
  opacity: 0.2;
  /* transform: rotate(-3deg); */
 }
 .basicDetails_leftBg{
  position: absolute;
  left: 0%;
  top: 40%;
  opacity: 0.2;
 }

 .CodewaveTitleDiv{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  }

  .CodeCol1{
   width: 65%;
   display: flex;
  }

  .CodeCol2{
   width: 20%;
  }

  .CodeCol1 img{
      width: 15%;
      margin-right: 1rem;
  }

 .CodeCol1 h3{
  color: #003A4B;
   font-size: 22px;
   font-weight: 600;
   margin: 0;
 }


 .calenderIcon{
  width: 25px;
  position: absolute;
  left:95%;
  top: 50%; 
 }

.nofacility{
  margin-left: 3rem;
}

.textMb{
  margin-bottom: 1rem;
}

.textMt{
  margin-top: 2rem;
}
.repTo{
  margin-left: 3rem;
}


.scopeInput{
  width: 25%;
}


.baseYr{
  width: 20%;
}

.flexColumn{
  display: flex;
  flex-direction: column;
}

.rowDiv {
  display: flex;
  flex-direction: row;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.disaggregated{
  width: 50%;
}

.attText{
  font-size: 16px !important;
  font-weight: 800 !important;
  color: #1D1D1B !important;
}
.offsetIp{
  width: 50%;
}

.emissionCopy p{
  color: #FFF;
  font-size: 14px;
  font-weight: 400;
  opacity: 0.4;
}

/* ------------- */

.full_width{
  width: -webkit-fill-available;
}
.wd-40 {
  width: 40%;
}
.ml-1 {
  margin-left: 1rem;
}
.gap-2{
  gap:2%;
}

.editDeclarBtn{
  display: flex;
  padding: 12px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  border: 1px solid var(--Neutral-Pure-White, #FFF);
}

.editDeclarBtn p{
  color: var(--Neutral-Pure-White, #FFF);
  text-align: center;
  font-family: Uniform;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
  -webkit-text-stroke-width: 0.2px;
}

.emisDecleftTitle h1{
  color: var(--Neutral-Charcoal-Black, #1D1D1B);
  font-family: Uniform;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  margin: 0;
  -webkit-text-stroke-width: 1px;
}

.EmissInnerMainBlock{
  display: flex;
  padding: 0;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
}




 