div.timeSelectBox{
    width: 22%;
}

div.timeSelectBox .css-vamk89-MuiFormControl-root{
    margin: 0px !important;
    width: -webkit-fill-available !important;
}

div.timeSelectBox  .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input{
    width: 160px !important;
    border: 1px solid #B9B9B8 !important;
    margin: 0 !important;
    padding: 8px !important;
    margin: 0 !important;
    padding: 0px !important;
    padding: 12px 12px !important;
    height: 0px !important;
}

.selectPlaceholder{
    color: #B9B9B8;
    font-family: Uniform;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
}

.selectLabel{
    color: #686866 !important;
    font-family: Uniform;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: left;
    margin-bottom: 5px;
}

div.timeSelectBox .css-qiwgdb.css-qiwgdb.css-qiwgdb{
    width: 160px !important;
    border: 1px solid #B9B9B8 !important;
    margin: 0 !important;
    padding: 0px !important;
    padding: 12px 12px !important;
    height: 0px !important;
    min-height: 18px !important;
}
