.timelinePreCon{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--XL, 24px);
    align-self: stretch;
    border-radius: 8px;
    background: #FFF;
}

.timelineTitle{
    color: var(--Neutral-Charcoal-Black, #1D1D1B);
    font-family: Uniform;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 13.98px;
    margin: 0;
    -webkit-text-stroke-width: 0.2px;
}

.timelinePreRowCon{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--M, 12px);
    align-self: stretch;
}

.timelinePreRow{
    display: flex;
    align-items: flex-start;
    gap:20px;
    align-self: stretch;
}

.timelinePreRow h2{
    margin: 0;
    color: #4A4A49;
    font-family: Uniform;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-transform: uppercase;
    -webkit-text-stroke-width: 0.3px;
}

.timelinePreRow p{
    color: var(--Neutral-Charcoal-Black, #1D1D1B);
    font-family: Uniform;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
    -webkit-text-stroke-width: 0.3px;
}

.timelinePreCol1{
    width: 25%;
}

.timelinePreCol2{
    display: flex;
    gap: 12px;
    width: 75%;
}

