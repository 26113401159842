.viewCommentBtnCon{
    width: fit-content;
    height: -webkit-fill-available;
    display: flex;
    gap: 4px;
    padding: 8px;
    border: 1px solid #0300B2;
    border-radius: 4px;
}

.viewCommentBtnCon p{
    color: var(--neutral-pure-white, #0300B2);
    text-align: center;
    font-family: Uniform;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    list-style: none;
    margin: 0;
    -webkit-text-stroke-width: 0.3px;
}