
.adminMainContainer{
    background-color:#F7F7F7;
    display: flex;
    align-items: center;
    align-self: stretch;
    align-items: flex-start;
    min-height: 100vh;
}

.adminDashCol1{
    background-color: #003A4B;
    display: flex ;
    flex-direction: column;
    width: 20%;
    align-items: center;
    gap: 8px;
    height: 100vh;
    overflow: auto;
}

.adminDashCol2{
    display: flex ;
    flex-direction: column ;
    width: 80%;
    align-items: flex-start;
    height: 100vh;
    overflow: auto;
}
  
.adminUsersConatiner{
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available;
    gap: 32px;
    padding: 32px;
    overflow: auto;
}

.registOnRow{
    display: flex;
    gap: 32px;
}

.registOnCol1{
    width: 30%;
}

.registOnCol2{
    width: 70%;
}

.saveBtnRow{
    display: flex;
    justify-content: space-between;
}

.feedbackTitle{
    color: var(--neutral-charcoal-black, #1D1D1B);
    text-align: left;
    font-family: Uniform;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
    -webkit-text-stroke-width: 1px;
}

.userProgressRow{
    display: flex;
    width: -webkit-fill-available;
    gap: 32px;
}

.userProgressCol1{
    width: 65%;
}

.userProgressCol2{
    width: 35%;
    height: -webkit-fill-available;
}

.dashboardRatingCol1{
    width: 50%;
}

.dashboardRatingCol2{
    width: 50%;
}

.repotedCardSec{
    display: flex;
    flex-wrap: wrap;
    gap: 28px;
}

.avgRatingTimeCol1{
    width: 35%;
    height: 400px;
}

.avgRatingTimeCol2{
    width: 65%;
}