.yearQuarterCard{
    width: 150px;
    border: 0.5px solid #33A2C1;
    border-radius: 10px;
    padding: 8px 20px;
    gap: 2px;
    opacity: 0.5px;
}

.yearQuartCardRow{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.yearQuartCardRow h2{
    margin: 0;
    color: #4A4A49;
    font-family: Uniform;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    -webkit-text-stroke-width: 0.3px;
}

.yearQuartCardRow p{
    margin: 0;
    color: #4A4A49;
    font-family: Uniform;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    -webkit-text-stroke-width: 0.3px;
}

.yearQuartCardCol1{
    display: flex;
    flex-direction: column;
}

.yearQuartCardCol2{
    display: flex;
    flex-direction: column;
}

.yearQuartCardRow .dashBorder{
    display: flex;
    border-right: 2px dashed #008BB2;
    height: 5vh;
    margin-left: 1rem;
    margin-right: 1rem;
    opacity: 0.2;
  }