
.viewProBreadcrumbs .viewProManagBread{
    color: #686866 !important;
    font-family: Uniform !important;
    font-size: 12px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: normal !important;
}

.viewProBreadcrumbs .viewProListBread {
    color: #686866 !important;
    font-family: Uniform !important;
    font-size: 12px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: normal !important;
    text-decoration-line: underline !important;
}


.viewProBreadcrumbs .viewProTextBread{
    color: #008BB2 !important;
    font-family: Uniform !important;
    font-size: 12px !important;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}