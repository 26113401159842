.auditInveBoundariesCon{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--M, 12px);
    align-self: stretch;
}

.auditInvBoundInnerCon{
    display: flex;
    padding: var(--L, 16px);
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    gap: var(--M, 12px);
    align-self: stretch;
    border-radius: 4px;
    background: #FAFAFA;
}

.auditInvBoundRow{
    display: flex;
    flex-direction: row;
    width: 100%;
}

.auditInvBoundInnerCon .auditInvBoundRow .auditInvBoundCol1 h1{
    color: var(--Neutral-Charcoal-Black-500, #1D1D1B);
    font-family: Uniform;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-transform: uppercase;
    margin: 0;
    -webkit-text-stroke-width: 0.3px;
}

.auditInvBoundCol1{
    width: 50%;
}

.auditInvBoundCol2{
    width: 50%;
}

.auditInvBoundCol2 p{
    color: var(--Neutral-Charcoal-Black-500, #1D1D1B);
    font-family: Uniform;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
    -webkit-text-stroke-width: 0.3px;
}

.offset2Title{
    color: var(--Neutral-Charcoal-Black-500, #003A4B) !important;
}

.auditEmissOffset1row{
    display: flex;
    flex-direction: row;
    width: -webkit-fill-available;
}

.auditEmissOffset1Col1{
    width: 50%;
}

.auditEmissOffset1Col2{
    width: 50%;
    display: flex;
    justify-content: flex-end;
}