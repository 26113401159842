.input-item {
    margin-bottom: 0 !important;
    text-align: left;
   }
   .input-item .input_label {
    color: #686866;
    font-family: Uniform;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 10px;
    text-align: left;
  }
  .input-item input {
    color: var(--neutral-charcoal-black, #1D1D1B);
    width: -webkit-fill-available !important;
    border-radius: 4px;
    padding: 0 12px;
    height: 40px;
    margin-top: 5px;
    outline: none;
    font-family: Uniform;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .input-item input::placeholder{
    color: var(--neutral-charcoal-black, #1D1D1B);
    font-family: Uniform;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    opacity: 0.2;
    -webkit-text-stroke-width: 0.2px;
  }



.custom-input {
  border: 2px solid #B9B9B8;
}

.custom-input:focus {
  border: 2px solid #008BB2;
  box-shadow: 0 0 8px 0 rgba(138, 202, 220, 1);
}

.custom-input.error {
  border: 2px solid #F45B69;
  box-shadow: 0 0 4px 0 rgba(244, 91, 105, 0.5);
  color: #1D1D1B !important;
}
  
.error {
  font-size: 1rem !important;
  color: #F45B69 !important;
}
.PhoneInput{
  margin: auto;
  padding-left: 0.5rem;
  position: relative;
  padding-right: 8px;
  position: relative;
}
.PhoneInputCountry{
  position: absolute !important;
  width: 100px;
  height: 40px;
}

input.PhoneInputInput{
  width: 35px !important;
  border: none !important;
  height: 100%;
  margin: 0px !important;
  padding: 0px;
  padding-left: 30px;
}
.PhoneInputCountrySelectArrow{
  display: none !important;
}
.PhoneInput::after{
  content: '\25BC';
  font-size: 12px;
}

.PhoneInputCountrySelect{
  border: 2px solid #B9B9B8 !important;
  font-family: Uniform;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.PhoneInputCountrySelect::-webkit-scrollbar{
  display: none;
}

.mobileInputAlign{
  display: flex;
}