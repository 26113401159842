
.profileTitleContainer{
    display: flex;
    flex-direction: column;
    gap: 4px;
    justify-content: space-between;
    width: -webkit-fill-available;
}

.proViewTitleSec{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: -webkit-fill-available;
}

.viewProTitleCol1 h1{
    color: var(--neutral-charcoal-black, #1D1D1B);
    text-align: center;
    font-family: Uniform;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
    -webkit-text-stroke-width: 1px;
}

.viewProTitleCol2{
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.rejectBtn button{
    display: flex;
    padding: 12px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 4px;
    border: 1px solid #F45B69;
    background: #FEEFF0;
}

.rejectBtn button p{
    color: #F45B69;
    text-align: center;
    font-family: Uniform;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
    -webkit-text-stroke-width: 0.2px;
}

.approveProfileBtn button{
    display: flex;
    padding: 12px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 4px;
    border: 1px solid #2BA84A;
    background: #EAF6ED;
}

.approveProfileBtn button p{
    color: #2BA84A;
    text-align: center;
    font-family: Uniform;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
    -webkit-text-stroke-width: 0.2px;
}