.auditOperaInvenContainer{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--XL, 24px);
    align-self: stretch;
    border-radius: 8px;
    background: #FFF;
}

.auditOperatScopeRow{
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: flex-start;
    align-self: stretch;
}

.auditOperatScopeCol1{
    width: 27%;
}

.auditOperatScopeCol2{
    width: 70%;
}

.auditOperatScopeRow .auditOperatScopeCol1 h1{
    color: var(--Neutral-Charcoal-Black-500, #1D1D1B);
    font-family: Uniform;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-transform: uppercase;
    margin: 0;
    -webkit-text-stroke-width: 0.3px;
}

.auditOperatScopeRow .auditOperatScopeCol2 p{
    color: var(--Neutral-Charcoal-Black, #1D1D1B);
    font-family: Uniform;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
    -webkit-text-stroke-width: 0.3px;
}