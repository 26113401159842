.InfoEmiss2Container{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--XL, 24px);
    align-self: stretch;
    border-radius: 8px;
    background: #FFF;
}

.diaggreFacilSec{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--M, 12px);
    align-self: stretch;
}

.diaggreFacilSec .emisViewFacility1 h1{
    color: var(--Neutral-Charcoal-Black, #1D1D1B);
    font-family: Uniform;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
    -webkit-text-stroke-width: 0.3px;
}

.facilityFont{
    -webkit-text-stroke-width: 0.5px !important;
}

.diaggreFacilSec .emisFacilRow p{
    color: var(--Neutral-Charcoal-Black, #1D1D1B);
    font-family: Uniform;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
    -webkit-text-stroke-width: 0.5px;
}

.emisViewFacility1{
    display: flex;
    padding: var(--L, 16px);
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    gap: var(--M, 12px);
    align-self: stretch;
    border-radius: 4px;
    background: #FAFAFA;
}

.emisFacilRow{
    display: flex;
    flex-direction: row;
    width: 100%;
}

.emisFacilCol1{
    width: 30%;
}

.emisFacilCol2{
    width: 70%;
}

.ownAttributSec{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--M, 12px);
    align-self: stretch;
}

.InfoEmiss2Container .ownAttributSec h1{
    color: var(--Neutral-Charcoal-Black-500, #1D1D1B);
    font-family: Uniform;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-transform: uppercase;
    margin: 0;
}

.InfoEmiss2Container .ownAttributSec p{
    color: var(--Neutral-Charcoal-Black, #1D1D1B);
    font-family: Uniform;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
    -webkit-text-stroke-width: 0.5px;
}

.emissScope2row{
    display: flex;
    flex-direction: row;
    width: -webkit-fill-available;
}

.emissScope2Col1{
    width: 50%;
}

.emissScope2Col2{
    width: 50%;
    display: flex;
    justify-content: flex-end;
}

.InfoEmiss2Container h2{
    color: var(--Neutral-Charcoal-Black, #1D1D1B);
    font-family: Uniform;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
    -webkit-text-stroke-width: 0.5px;
}