.chatBoxSubmitBtnCon{
    height: 48px;
    width: 200px;
    display: flex;
    padding: 12px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 4px;
    background: var(--Neutral-Charcoal-Black, #1D1D1B);
}
.chatBoxSubmitBtnCon h1{
    color: var(--Neutral-Pure-White, #FFF);
    text-align: center;
    font-family: Uniform;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
    -webkit-text-stroke-width: 0.2px;
}