.Left_SectionDiv{
  background-color: #ffffff;
  display: flex;
  padding: 24px 12px 12px 12px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  align-self: stretch;
  border-radius: 8px;
}

.sectionTitle{
  gap: 12px;
  width: -webkit-fill-available;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 12px;
  border-left: 2px solid white;
  text-decoration: none;
}

.secTitle_style {
  color:#003A4B !important;
  font-family: Uniform;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 800 !important;
  line-height: normal;
  margin: 0;
}

.sectionTitle .sectionTdivext{
  color:#1D1D1B;
  font-family: Uniform;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
  width: 63%;
  -webkit-text-stroke-width: 0.2px;
}

.sectionTitle .mandatoryValue{
  color: #686866;
  text-align: right;
  font-family: Uniform;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  margin: 0;
}

.sectionTitle.activeBlueStyle{
  border-radius: 2px;
  border-left: 3px solid #008BB2;
  background: #F7FDFF;
}

.activeBlueStyle .sectionText{
  font-weight: 800;
}

.activetitle {
  color:#1D1D1B;
  border-radius: 2px;
  border-left: 3px solid #008BB2;
  background: #F7FDFF;
  padding: 12px 10px;
  -webkit-text-stroke-width: 0.5px;
}