  /* Company logoo Section */
  .companyLogo{
    display: flex;
    background: #E6F3F7;
  }
  .companyLogo_section{
    background: #E6F3F7;
  }
  .company_LogoCol{
    width: 20%;
  }
  .company_LogoCol img{
    width: 90%;
    height: 120px; 
    opacity: 10;
  }
/* Company Logo */

.slick-initialized .slick-slide {
  display: flex ! important;
  justify-content: center;
  width: 250px !important;
  align-items: center;
  height: 170px;
}
.slick-next {
  display: none ! important;
  color: black;
  right: -25px;
}

.slider_section{
  width: 100% ! important;
  background: #F5FAFC;
  display: flex ! important;
  justify-content: center;
  align-items: center;
}

.logoSlider_container{
  padding-top: 60px;
  padding-bottom: 0px;
}

.companyLogoSection img{
  width: 100%;
  height: auto;
  margin-right: 0px;
 } 

 .companyLogoSection:focus-visible{
  outline: none !important;
  border: none !important;
 }

  /* Screen For 1680*1050 */
  @media screen and (min-width: 1621px) and (max-width: 1820px) {
     .logoSlider_container {
        padding-top: 0px;
        padding-bottom: 0px;
    }
   }
    /* Screen For 1440*900 */
    @media screen and (min-width: 1281px) and (max-width: 1620px) {
        .logoSlider_container {
          margin-top: 25px;
          padding-top: 0px;
          padding-bottom: 15px;
        }
        .slick-initialized .slick-slide{
          height: 200px;
        } 
     }

     /* Screen For 1280*1024 */
    @media screen and (max-width: 1280px) {
      .logoSlider_container {
        padding-top: 0px;
        padding-bottom: 20px;
    }
    }
     /* Screen For 1024*600 */
     @media screen and (min-width: 992px) and (max-width: 1242px) {
      
      .slick-initialized .slick-slide {
        height: 170px !important;
        width: auto !important;
       }
        .logoSlider_container {
            padding-top: 60px;
            padding-bottom: 20px;
        }
        .companyLogoSection img{
          width: 80% !important;
         }
    }

    @media screen and (max-width: 767px) {
      .slider_section {
          height: 13vh;
          width: 100% ! important;
          display: flex ! important;
          justify-content: center;
          align-items:center;
      }
      .logoSlider_container {
          margin-top: 3rem;
          padding-top: 10px;
          padding-bottom: 0px;
      }
      .slick-initialized .slick-slide {
        display: flex ! important;
        justify-content: center;
        width: auto !important;
        align-items: center;
       }
       .companyLogoSection img{
        width: 80% !important;
       }
    }

    @media screen and (max-width: 575px) {
        body {
            display: block;
            margin: 0px;
        }
        .container {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: auto;
            width: 80%;
            overflow: hidden;
        }
       /* Logo Section */
        .companyLogo {
            display: none;
            background: #E6F3F7;
        }
        .company_LogoCol img {
            width: auto;
            height: 95px;
            opacity: 10;
         }
         .slider_section {
            height: 15vh ;
          }
    
        .logoSlider_container {
          padding-top: 10px !important;
          padding-bottom: 0px;
        }
    }

     /* ----------------------------------------------400----------------------------------------- */
    
     @media (min-width: 300px) and (max-width: 400px) {
        body {
            display: block;
            margin: 0px;
        }
        .container {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: auto;
            width: 75%;
        }
         /* Logo Section */
        .companyLogo {
            display: none;
            background: #E6F3F7;
        }
        .company_LogoCol img {
            width: auto;
            height: 95px;
            opacity: 10;
         }
         .slider_section {
            height: 15vh ;
          }
    
        .logoSlider_container {
          padding-top: 0px !important;
          padding-bottom: 0px;
        }
     
    }
    
   
   


