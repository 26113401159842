
.forgotPasSection {
    background: linear-gradient(175deg,#00141a -18.79%,#003a4b 102.02%);
    display: flex;
    flex-direction: row;
    align-items: stretch;
    margin: auto;
    width: -webkit-fill-available; 
    min-height: 100vh ;
    overflow: hidden; 
   }
   .forgotPassCol1 {
     position: relative;
     display: flex;
     flex-direction: column;
     justify-content: center;
     align-content: space-between;
     justify-content: space-between;
     width: 65%;
     padding: 2% 0% 2% 8%;
   }
  .forgotPassCol2 {
   background-color: #fff;
   padding: 0 5%;
   width: 35%;
   display: flex;
   flex-direction: column;
   justify-content: center;
  }
  .copyRightSec p{
   margin: 0;
   color: var(--neutral-pure-white, #FFF);
   font-family: Uniform;
   font-size: 12px;
   font-style: normal;
   font-weight: 400;
   line-height: normal;
   opacity: 0.3;
  }
 
  .emailIdField{
   width: 100%;
  }
  .login_password{
  }
  .forgotPassCol1 h1{
    color: var(--primary-aqua-blue-200, #8ACADC);
    font-family: Uniform;
    font-size: 64px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
  }
  .forgotPassCol1 h2{
   color: var(--neutral-pure-white, #FFF);
   font-family: Uniform;
   font-size: 24px;
   font-style: normal;
   font-weight: 400;
   line-height: normal;
   margin: 0;
   -webkit-text-stroke-width: 1px;
  }
  .welcomSection {
   align-items: flex-start;
   display: flex;
   flex-direction: column;
   width: 60%;
 }
 
  .forgotPassCol1 .welcomSection p{
    font-family: Uniform;
    font-size: 14px;
    font-style: normal;
    font-weight: 100;
    line-height: 24px;
    opacity: 0.8;
   }
  .forgotPassCol1 p{
    color: var(--neutral-pure-white, #FFF);
    font-family: Uniform;
    font-size: 14px;
    font-style: normal;
    font-weight: 100;
    line-height: 24px;
   }  
   .passwordField{
     position: relative;
   }
   
   .position{
     position: relative;
     margin-bottom: 1rem;
   }
   .logoImgDiv img {
    width: 112.678px;
    height: 44px; 
   }
    .welcomSection{
       width: 60%;
       display: flex;
       flex-direction: column;
       align-items: flex-start;
    }
 
   .forgotPassCol2 p {
    color: var(--neutral-charcoal-black, #1D1D1B);
    font-family: Uniform;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
   }
   .forgotPasSection .forgotPassCol2 h1 {
    font-family: Uniform;
    font-size: 28px;
    font-weight: 900;
    text-align: left;
    margin: 0 0 10px 0;
    -webkit-text-stroke-width: 1px;
   }
 
   .loginBtn {
    background: #008BB2;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(0,139,178,.12);
    justify-content: center;
    width: 100%;
   }
  .loginBtn .login_BtnText {
    font-family: Uniform;
    color: #fff;
    font-size: 15px;
    font-weight: 500;
    padding: 12% 10%;
    text-align: center;
    margin: 0;
   }
  
   .formContainer{
     margin-top: 24px;
     display: flex;
     flex-direction: column;
     gap: 24px;
   }
 
   .forgotPass_topBg{
    position: absolute;
    right: -5%;
    top: 0%;
    opacity: 0.2;
   }
   .forgotPass_bottomBg{
    height: 25%;
    position: absolute;
    right: 20%;
    bottom: 0%;
    opacity: 0.2;
   }
   .forgotPass_leftBg{
    position: absolute;
    left: 0%;
    top: 40%;
    opacity: 0.2;
   }
  
.passRulSec{
    display: flex;
    flex-direction: column;
    padding: 12px;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 4px;
    background:#E6F3F7;
}

.passRulSec p,li{
    color: var(--neutral-charcoal-black, #1D1D1B);
    font-family: Uniform;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    -webkit-text-stroke-width: 0.2px;
}

.passRulSec ul{
    margin: 0;
}

.resetPassFailedSec{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
.resetPassFailedSec h1{
    text-align: center;
    text-wrap: nowrap;
  }

  .resetPassBtn{
    display: flex;
    margin-top: 24px;
    justify-content: center;
   }

   .resetPassFailedSec p{
    width: 80%;
    color: var(--neutral-charcoal-black, #1D1D1B);
    text-align: center;
    font-family: Uniform;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    -webkit-text-stroke-width: 0.2px;
   }


    /* Screen For 1280*1024 */
  @media screen and (max-width: 1280px) {
    .forgotPasSection h1{
      font-size: 60px;
    }
    .welcomSection {
      width: 75%;
    }
  } 

  @media screen and (min-width: 600px) and (max-width: 992px) {
    .forgotPasSection {
      display: flex;
      flex-direction: column;
    }
    .forgotPassCol1 {
      width: -webkit-fill-available !important;
      padding: 40px 40px !important;
    }
    .forgotPassCol2 {
      width: -webkit-fill-available;
      padding: 40px 40px;
      display: block;
      min-height: 55vh;
    }
    .copyRightSec{
      display: none;
    }
  }

  @media screen and (max-width: 575px) {
    .forgotPasSection {
      display: flex;
      flex-direction: column;
    }
    .logoImgDiv img {
      width: 100px;
      height: 44px;
    }
    .forgotPassCol1 h1{
      font-size: 35px;
      padding-top: 20px;
    }
    .welcomSection {
      width: 100%;
    }
    .forgotPassCol1 {
      width: -webkit-fill-available !important;
      padding: 30px 30px !important;
    }
    .forgotPassCol2 {
      width: -webkit-fill-available;
      padding: 30px 30px;
      display: block;
      min-height: 55vh;
    }
    .copyRightSec{
      display: none;
    }
   
  }
