.emisDisggCon{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--M, 12px);
    align-self: stretch;
}

.directOwned{
    display: flex;
    padding: var(--L, 16px);
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    gap: var(--M, 12px);
    align-self: stretch;
    border-radius: 4px;
    background: #FAFAFA;
}

.directOwnedRow{
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}

.directOwnedCol1{
    width: 50%;
}

.directOwnedCol2{
    width: 50%;
    display: flex;
    justify-content: flex-end;
}

.directOwned .directOwnedCol1 h1{
    color: var(--Primary-Aqua-Blue-900, #003A4B);
    font-family: Uniform;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
    -webkit-text-stroke-width: 0.5px;
}

.infoEmisRow{
    width: 100%;
    display: flex;
    flex-direction: column;
    /* flex-wrap: wrap; */
    justify-content: space-between;
    gap: 2%;
}

.infoEmis{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding:8px;
    border-radius:4px;
    background: #F0F0F0;
    margin-bottom: 15px;
    gap: 5px;
}

.directEmisRow{
    display: flex;
    flex-direction: row;
    gap: 8px;
}

.infoEmisRow .infoEmis h1{
    color: var(--Neutral-Charcoal-Black-500, #1D1D1B);
    font-family: Uniform;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-transform: uppercase;
    margin: 0;
    -webkit-text-stroke-width: 0.5px;
}

.infoEmisRow .infoEmis p{
    color: var(--Neutral-Charcoal-Black, #1D1D1B);
    font-family: Uniform;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
    -webkit-text-stroke-width: 0.3px;
}

.scopeCooling1{
    width: 50%;
}

.scopeCooling1 h1{
    color: var(--Primary-Aqua-Blue-900, #003A4B) !important;
    font-family: Uniform;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
    -webkit-text-stroke-width: 0.5px;
}

.scopeCooling2{
    width: 50%;
    display: flex;
    justify-content: flex-end;
}

.directOwned .scopeCooling2 h1{
    color: var(--Primary-Aqua-Blue-900, #003A4B);
    font-family: Uniform;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
}
    
