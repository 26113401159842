 /* Our Process Slider section */
       
 .ourProcess_section {
    padding-top: 0px;
    padding-bottom: 60px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    gap: 32px;
    }
    .ourProcessBackground-text{
      bottom: 0;
      font-family: 'Woodman-HeavyRounded';
      font-size: 202px;
      left:-165px;
      top: -2%;
      line-height: 200px;
      opacity: 0.09;
      pointer-events: none;
      position: absolute;
      right: 0;
      text-align: left;
      z-index: -1;
      color: transparent;
      -webkit-text-stroke-width: 1px;
      -webkit-text-stroke-color: black;
   }
   
   .scrollContainer::-webkit-scrollbar{
    display: none;
   }

    .cardTextDiv{
     display: flex;
     flex-direction: column;
     gap: 24px;
     /* height: 60vh; */
    }

   div.ProcessSlider_Section{
    width: 18rem ! important;
    display: flex ! important;
    padding: 35px 40px 15px 40px;
    flex-direction: column;
    align-items: flex-start;
    justify-content:space-between;
    gap: 44px;
   }
   .ourProcess_section .ourProcessMain_heading{
    color: var(--neutral-charcoal-black, #1D1D1B);
    text-align: left;
    margin: 0;
    font-family: Woodman-HeavyRounded;
    font-size: 40px;
    font-weight: 400;
   }
   .ourProcess_section_p{
    color: var(--neutral-charcoal-black, #1D1D1B);
    margin: 0;
    font-family: Uniform;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; 
    -webkit-text-stroke-width: 0.2px;
   }
  
 .processBgColor1 {
    background: linear-gradient(180deg, #E6F3F7 0%, #B0DBE7 100%);
  }
   .processBgColor2 {
    background: linear-gradient(180deg, #EAF6ED 0%, #BDE4C7 100%);
  }
  
  .processBgColor3 {
    background: linear-gradient(180deg, #FEF6EB 0%, #FBE4C1 100%);
  }
  
  .processBgColor4 {
    background: linear-gradient(180deg, #FEEFF0 0%, #FCCCD1 100%);
  }
  .processBgColor5 {
      background: linear-gradient(180deg, #EBEBFE 0%, #C2C1FB 100%);
    }
    .processBgColor6 {
        background: linear-gradient(180deg, #F6EAF3 0%, #E4BDD9 100%);
    }
    .processBgColor7 {
        background-color: #007EA2;
    }

    .processCardWhiteText{
        color: #ffffff !important;
    }

  .headingColor{
    color: #ffffff;
  }
  .listColor{
    color: #ffffff !important;
  }
   .processInner_Row{
    width: 100%;
    display: flex;
    align-items: center;
   }
   .processInner_Col1{
    width:50%;
   }
   .processInner_Col2{
    width:50%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
   }
   .ProcessSlider_Section h1{
    color: var(--neutral-charcoal-black, #003A4B);
    margin: 0px;
    font-family: Uniform;
    font-size: 32px;
    font-style: normal;
    font-weight: bolder;
    line-height: 40px;
    text-align: left;
    -webkit-text-stroke-width: 0.2px;
   }
   .ProcessSlider_Section p{
    color: var(--neutral-charcoal-black, #003A4B);
    font-family: Uniform;
    font-size: 16px; 
    font-style: normal;
    font-weight: 400;
    line-height: 25px; 
    text-align: justify;
    margin: 0;
    -webkit-text-stroke-width: 0.2px;
   }
 
    .processDigit_sec{
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      margin: 0px;
     }
    .ourProcess_digit{
      color: #fff;
      font-size: 110px;
      opacity: .5;
      font-family: Woodman-HeavyRounded;
    }

    .processCardUl{
        margin: 0;
        padding: 0px 0px 0px 15px;
    }
    .processCardUl li{
        color: var(--Neutral-Charcoal-Black, #1D1D1B);
        font-family: Uniform;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
    }

    .scrollContainer .slick-slider{
        display: contents !important;
    }

   .scrollContainer .slick-initialized .slick-slide{
        width: 367px !important;
        display: block !important;
    }
   .scrollContainer .slick-slider .slick-list{
     height: 92vh !important;
    }

    .scrollContainer .slick-prev{
        left: -90px;
    }

    .scrollContainer .slick-prev:before {
        font-family: 'slick';
        font-size: 86px;
        line-height: 1;
        opacity: .75;
        color: #007ea2;
        -webkit-font-smoothing: antialiased;
    }

    .ourProcessGridContainer{
        display: flex;
        width: -webkit-fill-available;
        align-items: flex-start;
        align-content: flex-start;
        justify-content: center;
        gap: 68px;
        flex-wrap: wrap;
    }

    .ourProCard{
        position: relative;
        display: flex;
        height: 460px;
        padding: var(--XL, 24px);
        flex-direction: column;
        align-items: flex-start;
        gap: 44px;
        width: 27%;
    }

    .processCardInner{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
    }

    .processInner_Col1 h1{
        color: var(--Neutral-Charcoal-Black, #1D1D1B);
        font-family: Uniform;
        font-size: 32px;
        font-style: normal;
        font-weight: 800;
        line-height: 40px;
        margin: 0;
        -webkit-text-stroke: 1px;
    }

    .processInner_Col2 p{
        color: var(--Neutral-Pure-White, #FFF);
        text-align: right;
        font-family: 'Woodman-HeavyRounded';
        font-size: 80px;
        font-style: normal;
        font-weight: 900;
        line-height: normal;
        opacity: 0.05;
        mix-blend-mode: plus-lighter;
        margin: 0;
     }

     .processInner_Col2 img{
        width: 32px;
        height: 32px;
        position: absolute;
        right: 0px;
        top: 0px;
        fill: var(--Primary-Aqua-Blue-600, #007EA2);
        opacity: 0.4;
      }

    /* Screen For 1680*1050 */
    @media screen and (min-width: 1621px) and (max-width: 1820px) {
        .ourProcessGridContainer{
            display: flex;
            flex-wrap: wrap;
            width: -webkit-fill-available;
            align-items: flex-start;
            align-content: flex-start;
            gap: 47px;
        }
    }

    /* Screen For 1440*900 */
    @media screen and (min-width: 1281px) and (max-width: 1620px) {
        .processDigit_sec {
            width: 100%;
        }
        .processInner_Col1{
            width:80%;
        }
        .processInner_Col2{
         width:20%;
        }
       .ourProCard{
        width: 26%;
       }
       .ourProcessGridContainer{
        gap: 49px;
       }
     }

    /* Screen For 1280*1024 */
    @media screen and (max-width: 1280px) {
        .ourProCard{
            width: 25%;
        }
        .processInner_Col1 h1{
            font-size: 23px;
        }
        .ourProcessGridContainer{
            gap: 54px;
        }
    }

    /* Screen For 1024*600 */
    @media screen and (min-width: 992px) and (max-width: 1242px) {

        .ourProcessBackground-text{
            top: -4%;
            left: -75px;
            font-size: 130px;
        }

        .ourProcess_section {
            display: flex;
            flex-direction: column !important;
            align-items: flex-start !important;
            margin: auto;
            width: 90%;
        }
        .processcard_sec {
            width: 300px !important;
        }
        .processDigit_sec {
            width: 100%;
        }
        .ourProCard{
            width: 24%;
            height: 530px;
        }
        .processInner_Col1 h1{
            font-size: 19px;
        }
        .processInner_Col2 p {
            font-size: 60px;
        }
        .ourProcessGridContainer{
            gap: 54px;
        }
    }

    @media screen and (min-width: 768px) and (max-width: 992px) {
        .ourProcess_section {
            padding-top: 0px;
            padding-bottom: 90px;
            display: flex ! important;
            flex-direction: column ! important;
            align-items: flex-start ! important;
        }
        .ourProcess_section_p{
            text-align: left;
        }
        .ourProcessBackground-text{
            top: -7%;
            left: -75px;
            font-size: 130px;
        }
        .ourProCard{
            width: 38%;
        }
        .processInner_Col1 h1 {
            line-height: 26px;
        }

       
    }

    @media screen and (max-width: 767px) {
        .ourProcess_section {
            padding-top: 0px;
            padding-bottom: 90px;
            display: flex ! important;
            flex-direction: column ! important;
            align-items: flex-start ! important;
        }
        .ourProcessBackground-text{
            top: -4%;
            left: -48px;
            font-size: 130px;
        }
        .ourProCard{
            width: 35%;
            height: 560px;
        }
        .processInner_Col1 h1{
            font-size: 15px;
        }
        .processInner_Col2 p {
            font-size: 55px;
        }
        .processCardUl li{
            font-size: 13px;
        }
        .processInner_Col1 h1 {
            line-height: 26px;
        }
    }

    @media screen and (max-width: 575px) {
        body {
            display: block;
            margin: 0px;
        }
        .ourProcessBackground-text{
            display: none;
        }
        .container.ourProcess_section{
            width: 90%;
        }
        /* Process */
        .ourProcess_section .ourProcessMain_heading {
            font-size: 23px;
            text-align: left;
        }
        .processcard_sec {
            width: 253px   !important;
        }
        .processDigit_sec {
            width: 100%;
            height: 10vh;
        }
        .ProcessSlider_Section h1 {
          font-size: 25px;
        }
        .ourProcess_section {
            padding-top: 0px;
            padding-bottom: 70px;
            display: flex !important;
        }

        .ourProCard {
            width: -webkit-fill-available;
            height: 345px;
        }

        .processInner_Col1 h1{
            font-size: 20px;
        }
    }

    /* ----------------------------------------------400----------------------------------------- */
    
    @media (min-width: 300px) and (max-width: 400px) {
        body {
            display: block;
            margin: 0px;
        }
        /* Process */
        .ourProcess_section .ourProcessMain_heading {
            font-size: 23px;
            text-align: left;
        }
        .processcard_sec {
            width: 225px !important;
        }
        .ProcessSlider_Section h1 {
          font-size: 25px;
        }
        .processDigit_sec {
            width: 100%;
            height: 10vh;
        }
        .ourProcess_section {
            padding-top: 0px;
            padding-bottom: 70px;
            display: flex !important;
        }

        .ourProCard {
            width: -webkit-fill-available;
            height: 365px;
        }

        .processInner_Col1 h1{
            font-size: 15px;
        }
    
    }

