
         /* ... Emission Section ... */
         .emission_section {
            width: 100%;
            padding-top: 60px;
            padding-bottom: 60px;
            background: #E6F3F7;
            display: flex;
            justify-content: space-between;
        }
        .emissionSec_Col1 {
            display: flex;
            width: 45%;
            flex-direction: column;
        }
        .emissionSec_Col2 {
            width: 55%;
            display: flex;
            justify-content: center;
        }
        .emission_section p{
          color: var(--neutral-charcoal-black, #1D1D1B);
          font-family: Uniform;
          font-size: 24px;
          font-style: normal;
          font-weight: 400;
          line-height: 40px;
          margin: 0px;
          text-align: left;
          width: 67%;
        }
        .carbonTitle{
            font-weight: 800;
        }
        .emission_title{
          color: #F3A738;
          font-family: Uniform;
          font-size: 40px;
          font-style: normal;
          font-weight: 800;
          line-height: 60px;
        }
        .reduction_title{
          color: #2BA84A;
          font-family: Uniform;
          font-size: 40px;
          font-style: normal;
          font-weight: 800;
          line-height: 60px;
        }

        /* Screen For 1680*1050 */
    @media screen and (min-width: 1621px) and (max-width: 1820px) {
        .emissionSec_Col1 {
          display: flex;
          width: 50%;
          flex-direction: column;
        }
        .emissionSec_Col2{
            width: 50%;
           display: flex;
           justify-content: center;
        }
    }

    /* Screen For 1440*900 */
    @media screen and (min-width: 1281px) and (max-width: 1620px) {  
        .empoweringChange-section {
            display: flex;
            flex-direction: row;
            height: 70vh;
            margin: auto;
            width: 84%;
        }
        .emissionSec_Col1 {
            display: flex;
            width: 60%;
            flex-direction: column;
        }
        .emissionSec_Col2 {
            width: 40%;
            display: flex;
            justify-content: center;
        }
        .emission_section p{
            width: 72%;
        }
    }
     /* Screen For 1280*1024 */
     @media screen and (max-width: 1280px) {
        .emissionSec_Col1 {
            display: flex;
            width: 50%;
            flex-direction: column;
        }
        .emissionSec_Col2 {
            width: 50%;
            display: flex;
            justify-content: center;
        }
        .emission_section p {
            font-family: Uniform;
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            line-height: 40px;
            margin: 0px;
            text-align: left;
            width: 90%;
        }
    }

     /* Screen For 1024*600 */
     @media screen and (min-width: 992px) and (max-width: 1242px) {    
       .emission_section {
         width: 100% !important;
         padding-top: 60px;
         padding-bottom: 100px;
         background: #E6F3F7;
         display: flex;
        }
        .emissionSec_Col1{
            width: 50%;
        }
        .emissionSec_Col2{
            width: 50%;
        }
        .emission_section p {
            font-family: Uniform;
            font-size: 17px;
            width: 77%;
        }
        .emission_section img {
            width: 80%;
        }
        .emission_title {
            color: #F3A738;
            font-family: Uniform;
            font-size: 30px;
        }
        .reduction_title {
            color: #2BA84A;
            font-family: Uniform;
            font-size: 32px;
        }
    
     }

     @media screen and (min-width: 768px) and (max-width: 992px) {
       
        .emisInnerSec{
            display: flex;
            flex-direction: column !important;
            align-items: center !important;
            width: 100%;
        }
        .emissionSec_Col1{
            width: 100%;
            display: flex;
            align-items: center;
        }
        .emissionSec_Col2{
            width: 100%;
            display: flex;
            
        }
        .emission_section img {
            width: 70%;
        }
        .emission_section p {
            width: 80%;
        }
    }

    @media screen and (max-width: 767px) {
        .emisInnerSec{
         display: flex;
         flex-direction: column !important;
         align-items: center !important;
         width: 100%;
        }
        .emissionSec_Col1{
            width: 100%;
            display: flex;
            align-items: center;
        }
        .emissionSec_Col2{
            width: 100%;
        }
        .emission_section img { 
            width: 70%;
        }
        .emission_section p {
            width: 90%;
        }
        .emission_title {
            color: #F3A738;
            font-size: 38px;
            font-style: normal;
            line-height: 60px;
        }  
        .reduction_title {
            color: #2BA84A;
            font-size: 38px;
            font-style: normal;
            line-height: 60px;
        }
    }

    @media screen and (max-width: 575px) {
        body {
            display: block;
            margin: 0px;
        }
        .container.emisInnerSec{
            width: 90%;
        }
    
        /* Emission Aim */
        .emissionSec_Col1 {
            display: flex;
            width: 100%;
            flex-direction: column;
        }
        .emissionSec_Col2 {
            width: 100%;
            margin-top: 15px;
            display: flex;
            /* justify-content: center; */
            justify-content: flex-start;
        }
        .emission_section p {
            font-size: 15px;
            /* text-align: center; */
            width: 100%;
        }
        .emission_title {
            color: #F3A738;
            font-size: 22px;
            line-height: 35px;
        }
        .reduction_title {
            color: #2BA84A;
            font-size: 22px;
            font-style: normal;
            line-height: 35px;
        }
    
    }

     /* ----------------------------------------------400----------------------------------------- */
    
     @media (min-width: 300px) and (max-width: 400px) {       
        body {
            display: block;
            margin: 0px;
        }
        /* Emission Aim */
        .emissionSec_Col1 {
            display: flex;
            width: 100%;
            flex-direction: column;
        }
        .emissionSec_Col2 {
            width: 100%;
            margin-top: 15px;
            justify-content: flex-start;
        }
        .emission_section p {
            font-size: 15px;
            /* text-align: center; */
        }
        .emission_title {
            color: #F3A738;
            font-size: 22px;
            line-height: 35px;
        }
        .reduction_title {
            color: #2BA84A;
            font-size: 22px;
            font-style: normal;
            line-height: 35px;
        }
    
     }
