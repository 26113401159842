
.pdfUpload{
  width: max-content;
  background-color: #E8E8E8;
  padding: 20px;
  display: flex;
  justify-content: center;
  border-radius: 8px;
  position: relative;
}

.pdfUpload_closeBtn{
  position: absolute;
  right: -2%;
  bottom: 90%;
  background: #F7F7F7;
  padding: 0.2rem 0.3rem;
  border-radius: 12px;
}

.pdfUpload_innerdiv{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.pdfIcon{
  width:25px;
  height: auto;
  margin-right: 5px;
}

.pdfUpload_innerdiv p{
  color: #686866 !important;
  white-space: nowrap;
  font-family: Uniform;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin: 0;
  -webkit-text-stroke-width: 0.2px !important;
}

