/* Where We Are */
 .whereAreWe_section{
    padding-top: 100px;
    padding-bottom: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 32px;
   }
   .whereAreWe_h1{
       width: 100%;
       display: flex;
   }
   .whereAreWe_section h1{
    color: var(--neutral-charcoal-black, #1D1D1B);
     margin: 0;
     font-family: Woodman-HeavyRounded;
     font-size: 40px;
     font-style: normal;
     font-weight: 400;
     line-height: 56px;
   }
   .whereAreWe_section p{
    color: var(--neutral-charcoal-black, #1D1D1B);
    margin: 0;
    font-family: Uniform;
    font-size: 14px;
    font-weight: 400;
    line-height: 28px;
    text-align: justify;
    -webkit-text-stroke-width: 0.2px;
   }
   .whereWe_img{
      display: flex;
      justify-content: center;
      align-items: center;
   }
   .whereWe_img img{
      width: 1620px;
   }

   /* Screen For 1680*1050 */
   @media screen and (min-width: 1621px) and (max-width: 1820px) {
    .whereWe_img img {
        width: 88rem;
    }
   }
    /* Screen For 1440*900 */
    @media screen and (min-width: 1281px) and (max-width: 1620px) {
        .whereWe_img img {
            width: 76rem;
        }
   }
   /* Screen For 1280*1024 */
   @media screen and (max-width: 1280px) {
     .whereWe_img img {
        width: 100%;
    }
  }
    /* Screen For 1024*600 */
    @media screen and (min-width: 992px) and (max-width: 1242px) {
        .container {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: auto;
            width: 90%;
        }
        .whereWe_img img {
            width: 100%;
        }
        .whereAreWe_section {
            padding-top: 60px;
            padding-bottom: 100px;
            display: flex;
            flex-direction: column !important;
            align-items: flex-start !important;
        }
     
    }

    @media screen and (min-width: 768px) and (max-width: 991px) {
        .container {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: auto;
            width: 90%;
        }
        .whereAreWe_section {
            display: flex;
            flex-direction: column !important;
            align-items: flex-start !important;
        }
        .whereWe_img img {
            width: 100%;
        }
        .whereAreWe_section {
            padding-top: 60px;
            padding-bottom: 100px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }
      
    
    }
    @media screen and (max-width: 767px) {
        .container {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: auto;
            width: 90%;
        }
        .whereAreWe_section {
            display: flex;
            flex-direction: column !important;
            align-items: flex-start !important;
        }
        .whereWe_img img {
            width: 100%;
        }
        .whereAreWe_section {
            padding-top: 60px;
            padding-bottom: 100px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }
    }
    @media screen and (max-width: 575px) {
        body {
            display: block;
            margin: 0px;
        }
        .container {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: auto;
            width: 80%;
            overflow: hidden;
        }
        /* Where Are We Section */
        .whereAreWe_section {
            padding-top: 25px !important;
            padding-bottom: 50px !important;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .whereAreWe_section h1 {
            text-align: center;
            font-size: 28px;
            font-style: normal;
            line-height: 42px;
        }
        .whereWe_img img {
            width: 100%;
            height: 8rem;
        }
        
    }
    /* ----------------------------------------------400----------------------------------------- */
    
    @media (min-width: 300px) and (max-width: 400px) {
        body {
            display: block;
            margin: 0px;
        }
        .container {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: auto;
            width: 75%;
        }
        /* Where Are We Section */
        .whereAreWe_section {
            padding-top: 25px !important;
            padding-bottom: 50px !important;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .whereAreWe_section h1 {
            font-size: 25px;
            font-style: normal;
            line-height: 42px;
        }
        .whereWe_img img {
            width: 100%;
        }
        
    }