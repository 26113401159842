.addPriorityCon{
    display: flex;
    flex-direction: row;
    width: -webkit-fill-available;
    background-color: #fff;
    border-radius: 8px;
    padding: 24px;
    gap: 24px;
    align-items: center;
}

.addPriorityCon p{
    color: #1D1D1B ;
    font-family: Uniform;
    font-size: 14px ;
    font-style: normal;
    font-weight: 400 ;
    line-height: 20px;
    margin: 0;
    -webkit-text-stroke-width: 0.3px;
}