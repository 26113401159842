.auditEquityTableRow{
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 12px;
}

.auditEquityTableCol1{
    width: 40%;
}

.auditEquityTableCol2{
    width: 60%;
}

.auditEquityTableCol1 h1{
    color: var(--Neutral-Charcoal-Black-500, #1D1D1B);
    font-family: Uniform;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-transform: uppercase;
    margin: 0;
    -webkit-text-stroke-width: 0.3px;
}

.auditEquityTableCol2 p{
    color: var(--Neutral-Charcoal-Black-500, #1D1D1B);
    font-family: Uniform;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-transform: uppercase;
    margin: 0;
    -webkit-text-stroke-width: 0.3px;
}