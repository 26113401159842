.orgRatingCo1 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
}
.orgRatingCo2 {
  width: 30%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 8px;
}

.orgRatingCo1 p {
  color: #003a4b;
  text-align: center;
  font-family: Uniform;
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: 20px;
  margin: 0;
}
.orgRatingStatus {
  display: flex;
  flex-direction: row;
  padding: 0 24px;
  align-items: baseline;
}

p.orgDeclarText {
  color: #1d1d1b;
  font-family: Uniform;
  font-size: 12px;
  font-weight: 400;
  margin: 0;
  margin-top: 8px;
  margin-bottom: 24px;
  text-align: left;
}

.orgDashTimeContainer {
  display: flex;
  width: 100%;
  flex-direction: column;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 4px 40px 0px #e8e8e8;
  padding: 24px 0;
  position: relative;
}

.orgUpdateBtn {
  display: flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: #f3a738;
  z-index: 10;
  margin-left: 8px;
}
.orgUpdateBtn button {
  color: var(--neutral-pure-white, #fff);
  text-align: center;
  font-family: Uniform;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  background-color: transparent;
}

.updateBtnSec {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.declarePadd {
  padding: 0 24px;
}

.addConsBtn {
  display: flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: #008bb2;
  z-index: 10;
}

.reminderBtn {
  display: flex;
  gap: 4px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  z-index: 10;
  border-radius: 4px;
  background: #1d1d1b;
}

.reminderBtn button {
  color: var(--neutral-pure-white, #fff);
  text-align: center;
  font-family: Uniform;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  background-color: transparent !important;
}

.reminderBtn button div {
  background-color: transparent !important;
}

.regDoneSec {
  display: flex;
  flex-direction: row;
  padding: 0 24px;
  gap: 2rem;
}

.conRegText {
  color: var(--secondary-emerald-green, #2ba84a);
  font-family: Uniform;
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  margin: 0;
  margin-left: 35px;
}

.audRegText {
  color: var(--secondary-amber-orange-500, #f3a738);
  font-family: Uniform;
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  margin: 0;
  margin-left: 35px;
}

.conReg {
  position: relative;
}

.conReg img {
  display: flex;
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: flex-start;
  border-radius: 8px;
  background: #2ba84a;
  position: absolute;
  left: 2px;
  top: -3px;
}

.audReg {
  position: relative;
}

.audReg img {
  display: flex;
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: flex-start;
  border-radius: 8px;
  background: #f3a738;
  position: absolute;
  left: 2px;
  top: -3px;
}



/* Current Rating Component */

.orgsCurrentRatingCon{
  width: calc(25% - 10.6px);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.orgsCurrentRatingInner{
  display: flex;
  flex-direction: column;
  /* padding: 24px 24px 0px 24px; */
  box-sizing: border-box;
  background: #ffffff;
  box-shadow: 0px 4px 40px 0px #E8E8E8;
  border-radius: 8px;
  gap: 20px; 
}

.currentRatTitle{
  display: flex;
  flex-direction: column;
  gap: 2px;
  padding: 24px;
}

.currentRatTitle h1{
  color: var(--neutral-charcoal-black, #979796);
  text-align: left;
  font-family: Uniform;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  margin: 0;
  -webkit-text-stroke-width: 0.2px;
}

.currentRatTitle p{
  color: var(--neutral-charcoal-black, #1D1D1B);
  text-align: left;
  font-family: Uniform;
  font-size: 60px;
  font-style: normal;
  font-weight: 800;
  margin: 0;
}

.currentRatTitle img{
  width: 184px;
  height: auto;
}

.orgsReductionsRateCon{
  display: flex;
  padding: 24px;
  box-sizing: border-box;
  background: #ffffff;
  box-shadow: 0px 4px 40px 0px #E8E8E8;
  border-radius: 8px;
  gap: 8px; 
  justify-content: space-between;
}

.reductionsRateCol1 h1{
  color: var(--neutral-charcoal-black, #979796);
  text-align: left;
  font-family: Uniform;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  margin: 0;
  -webkit-text-stroke-width: 0.2px;
}

.reductionsRateCol1 p{
  color: var(--neutral-charcoal-black, #1D1D1B);
  text-align: left;
  font-family: Uniform;
  font-size: 60px;
  font-style: normal;
  font-weight: 800;
  margin: 0;
}

.orgsReductionsRateCon img{
  width: 56px;
  height: auto;
}

.currentRatTitle h2{
  color: var(--neutral-charcoal-black, #1D1D1B);
  text-align: left;
  font-family: Uniform;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin: 0;
}

.blueBoarderLine{
  border-top: 5px solid #008BB2;
  width: 100%;
  box-sizing: border-box;
  background-image: linear-gradient(
  0deg,
  hsl(0deg 0% 100%) 0%,
  hsl(201deg 43% 87%) 25%,
  hsl(200deg 44% 73%) 50%,
  hsl(199deg 45% 58%) 75%,
  hsl(193deg 100% 35%) 100%
);
  height: 25px;
}

.orgsDashboardBottomImg{
  display: flex;
  justify-content: flex-end;
  padding: 0 24px;
}

.orgsDashboardBottomImg img{
  width: 184px;
  height: auto;
}

/* user previous feedback */

.feedbacktable{
  width:  -webkit-fill-available;
  
}

/* FAQ */
.faqInfo{
  display: flex;
  align-items: center;
  width: 100%;
  align-items: stretch;
 }

.faqInfo h3{
  color: var(--primary-aqua-blue, #1D1D1B);
  text-align: center;
  font-family: Uniform;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin: 0;
}

#faq .css-1elwnq4-MuiPaper-root-MuiAccordion-root{
  padding: 0px !important;
}