.selectNetYearCon{
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.selectNetYearCon .css-1869usk-MuiFormControl-root{
    margin: 0;
}

.selectNetYearCon .css-19ygod6-MuiNativeSelect-select-MuiInputBase-input-MuiInput-input{
    width: 160px !important;
    border: 1px solid #B9B9B8 !important;
    margin: 0 !important;
    padding: 8px !important;
    margin: 0 !important;
}

.selectNetYearCon .css-v4u5dn-MuiInputBase-root-MuiInput-root:before{
    display: none !important;
}

.selectNetYearCon .css-v4u5dn-MuiInputBase-root-MuiInput-root:after{
    display: none !important;
}

.selectNetYear MenuItem{
    color: #1D1D1B;
    font-size: 12px;
    font-family: Uniform;
}

.netZeroLabel{
    color: #686866;
    font-size: 12px;
    font-family: Uniform;
}

.SelectPlaceholder{
    color: #686866;
}

.selectNetYearCon .css-1gtikml.css-1gtikml.css-1gtikml{
    width: 160px !important;
    border: 1px solid #B9B9B8 !important;
    padding: 8px !important;
    margin: 0 !important;
}

.selectNetYearCon .css-zrqpq1::before {
    border-bottom: none !important;
}

.selectNetYearCon .css-b1884d{
    margin: 0px !important;
}

.selectNetYearCon .css-zrqpq1::after{
    border-bottom: none !important
}
