.otpContainer{
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
}

.otpContainer>div{
  width: 100%;
  justify-content: space-between;
}

.otp_code_container{
	display: flex;
	align-items: center;
	justify-content: center;
}

.EditNameDiv .nameEdit{
  text-decoration-line: underline;
}

input[type=number]:hover {
    -moz-appearance: textfield !important;
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}
input[type="number"] {
  -moz-appearance: textfield !important;
}