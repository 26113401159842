.addTimeLineBtn{
    display: flex;
    flex-direction: row;
    border-radius: 4px;
    gap: 4px;
    padding: 8px;
    align-items: center;
    cursor: pointer;
}

.addTimeLineBtn p{
    color: #1D1D1B;
    font-family: Uniform;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    margin: 0;
    -webkit-text-stroke-width: 0.1px;
}