.emissOneSec{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--XL, 24px);
    align-self: stretch;
}

.emissOneInnerSec{
    width: -webkit-fill-available;
}

.infoGasSec{
    display: flex;
    flex-direction: row;
    width: -webkit-fill-available;
    align-items: flex-start;
    gap: var(--CornerRadius-CornerRadius_Large, 20px);
    align-self: stretch;
}

.infoGasCol1{
    display: flex;
    width: 80%;
    flex-direction: row;
    gap: 22px;
    flex-wrap: wrap;
    border-right: 2px solid #b9b9b8;
}

.infoGasCol2{
    width: 20%;
}

.infoEmisWidth{
    width: 30%;
}

.infoEmisTotal{
    width: 50%;
    position: relative;
}

.infoEmisTotal .input-item .input_label{
    color: #686866;
    font-family: Uniform;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    -webkit-text-stroke-width: 0.5px;
}

.infoScope{
    gap:2%;
}

.directCo2Row{
    width: -webkit-fill-available;
    display: flex;
    flex-direction: column;
}
   
.infoScopeTwo{
     gap:2%
}
.infoScopeTwo1{
     width: 23.5%;
}
    .infoScopeTwo2{
     width: 23.5%;
}
.infoScopeTwo3{
     width: 23.5%;
}
.infoScopeTwo4{
     width: 23.5%;
}
.infoScopeTwo5{
     width: 23.5%;
}
.infoScopeTwo6{
     width: 23.5%;
}
.infoScopeTwo7{
     width: 23.5%;
}

.directInput {
        width: 100%;
}