.ratingTimeline{
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    border-radius: 8px;
    background: var(--Neutral-Pure-White, #FFF);
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.08);
}

.ratingProcessSec{
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.ratingProcessSec h1{
    color: var(--neutral-pure-white, #003A4B);
    font-family: Uniform;
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    list-style: none;
    margin: 0;
    -webkit-text-stroke-width: 0.5px;
    text-align: left;
}

.ratingProcessSec p{
    text-align: left;
    color: var(--neutral-pure-white, #1D1D1B);
    font-family: Uniform;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    list-style: none;
    margin: 0;
    -webkit-text-stroke-width: 0.3px;
}
.ratTimelineTitle{
    width: -webkit-fill-available;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
}

.ratTimelineTitle h1{
    color: var(--neutral-pure-white, #2BA84A);
    text-align: center;
    font-family: Uniform;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    list-style: none;
    margin: 0;
    -webkit-text-stroke-width: 0.3px;
}

.timelineUnactiveTitle{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: -webkit-fill-available;
    gap: 12px;
}

.timelineUnactiveTitle h1{
    color: var(--neutral-pure-white, #008BB2);
    text-align: center;
    font-family: Uniform;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    list-style: none;
    margin: 0;
    -webkit-text-stroke-width: 0.3px;
}
.timelineUnactiveTitle p{
    margin: 0;
    color: #4A4A49;
    font-family: Uniform;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    -webkit-text-stroke-width: 0.5px;
}

.timelineTitleRow{
    display: flex;
    flex-direction: row;
    width: -webkit-fill-available;
    justify-content: space-between;
}

.timelineTitleCol1{
    display: flex;
    flex-direction: row;
    width: 80%;
    align-items: center;
    gap: 25px;
    align-items: flex-start;
}

.timelineTitleCol1 img{
    margin-top: -6px;
    z-index: 2;
}

.ratingTimelineScale{
    position: absolute;
    width: 2px;
    height: 100%;
    background-color: #B0DBE7;
    top: 0;
    left: 15px;
}

.ratingActiveScale{
    width: 100%;
    background-color: #2BA84A;
}




