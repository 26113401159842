
  .otherDetails_section {
    background: linear-gradient(175deg,#00141a -18.79%,#003a4b 102.02%);
    display: flex;
    flex-direction: row;
    align-items: stretch;
    margin: auto;
    width: -webkit-fill-available; 
    min-height: 100vh;
    overflow: hidden; 
   }
  
    .otherDetailsSec_Col1 {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      width: 25% ;
      padding: 4% 2% 0% 2%;
      align-items: center;
    }
    .otherDetailsSec_Col2 {
      background-color: #fff;
      padding: 8% 8% 8% 8%;
      width: 75%;
      display: flex;
      flex-direction: column;
    }
    .rowDiv {
        display: flex;
        flex-direction: row;
        margin-top: 2rem;
        margin-bottom: 2rem;
      }

    .titleRow{
      margin-bottom: 1rem;
    }
    
     .upload_regDetails {
    font-family: Uniform !important;
    font-size: 16px !important;
    font-weight: 800 !important;
    line-height: 20px !important;
    }
    
    .mb_1r{
      margin-bottom: 1rem;
    }
    
    .removeOtherDetails_div{
      display: flex;
      justify-content:flex-end;
    }

    .otherAddBranch_div{
        display: flex;
        padding: 0rem 1rem;
        margin-bottom: 1rem;
    }

    .removeSec_row .inputBox_mb{
     margin-bottom: 0;
    }

    .otherDetailsSec_Col1 h2{
      color: var(--neutral-pure-white, #FFF);
      font-family: Uniform;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin: 0;
    }
    .otherDetailsSec_Col1 p{
      color: var(--neutral-pure-white, #FFF);
      font-family: Uniform;
      font-size: 14px;
      font-style: normal;
      font-weight: 100;
      line-height: 24px ;
    }   
    .otherDetails_logoDiv{
      height: 8vh;
    }
    .otherDetails_logoDiv img {
      width: 112.678px;
      height: 44px;  
    }
    .otherDetails_timeLineContents{
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
    .otherDetailsSec_Col1 .otherDetails_timeLineContents p{
      font-family: Uniform;
      font-size: 16px;
      font-style: normal;
      font-weight: 100;
      line-height: 24px ;
     }
    
    .otherDetailsSec_Col2 p {
      font-family: Uniform;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 25px;
      margin: 0;
    }

   .otherDetailsSec_Col2 h1 {
      font-family: Uniform;
      font-size: 28px;
      font-weight: 900;
      text-align: left;
      margin: 0 0 0px 0;
      -webkit-text-stroke-width: 1px;
      margin-bottom: 20px;
    }

    .odOtherLink{
      display: flex;
      flex-direction: row;
      gap: 2%;
      position: relative;
      margin-top: 32px;
      margin-bottom: 8px;
    }

    .odRemoveBtn{
      position:absolute;
      right: 0;
      top: 35px;
      bottom: 0;
    }

  .attachment_Paragraph{
    margin: 0;
    color: var(--neutral-charcoal-black-200, #979796);
    font-family: Uniform;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    }

    .otherDetails_form{
    }
    .removeSec_row{
      display: flex;
      flex-direction: row;
      align-items: center !important;
    }
    
    .otherDetails_topBg{
      height: 19rem;
      position: absolute;
      right: -181px;
      top: 0%;
      opacity: 0.2;
     }
    .otherDetails_bottomBg{
      height: 9rem;
      position: absolute;
      right: 0px;
      bottom: 40%;
      opacity: 0.2;
     }
  
    .otherDetails_leftBg{
      height: 6rem;
      position: absolute;
      left: 0%;
      top: 28%;
      opacity: 0.2;
     }
  
    .cancelBtn_Row{
      display: flex;
      justify-content: flex-end;
    }
    .NextBtn{
      display: contents;
    }
    .od_BtnRow{
      display:flex;
      justify-content: space-between;
    }

 
    
    
    
      /* Screen For 1440*900 */
      /* @media screen and (min-width: 1281px) and (max-width: 1620px) {
        .otherDetails_timeLineContents {
          width: 80%;
        }  
        .otherDetailsSec_Col1 h1{
          font-size: 52px;
        }
        .otherDetailsSec_Col1 h2{
          font-size: 24px;
        }
        .otherDetailsSec_Col1 p{
          font-size: 16px;
        }
    
      }
     */
     /* Screen For 1280*1024 */
     /* @media screen and (max-width: 1280px) {
      
      .otherDetailsSec_Col1 {
        width: 55%;
      }
      .otherDetailsSec_Col2 {
        width: 45%;
      }
      .otherDetails_timeLineContents {
        width: 93%;
      }  
      .otherDetailsSec_Col1 h1{
        font-size: 52px;
      }
      .otherDetailsSec_Col1 h2{
        font-size: 22px;
      }
      .otherDetailsSec_Col1 p{
        font-size: 14px;
      }
      .otherDetailsSec_Col2 p {
        font-family: Uniform;
        font-size: 14px;
      }
    
      } */
       /* Screen For 1024*600 */
      /* @media screen and (min-width: 992px) and (max-width: 1242px) {
    
      } */
    
    
     
    
    
     