.actionPlanCon{
    display: flex;
    flex-direction: row;
    gap: 12px ;
    width: -webkit-fill-available;
    justify-content: space-between;
}

.actionPlanCol1{
    width: 50%;
}

.actionPlanCol1 p{
    font-family: Uniform;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
    -webkit-text-stroke-width: 0.2px;
}

.actionPlanCol2{
    display: flex;
    flex-direction: row;
    gap: 12px;
}

