.ghgEmissionCon{
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: -webkit-fill-available;
}

.ghgEmissTitle{
    color: #1D1D1B;
    font-family: Uniform;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
    -webkit-text-stroke-width: 0.5px;
}

.ghgEmissScopeRow{
    display: flex;
    gap: 12px;
}

.ghgEmissScope{
    width: -webkit-fill-available;
    display: flex;
    flex-direction: row;
    gap: 12px;
}

.ghgEmissCol{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
    background-color: #FAFAFA;
    border-radius: 8px;
    padding: 16px;
}

.ghgEmissCol h1{
    color: #003A4B;
    font-family: Uniform;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    margin: 0;
    -webkit-text-stroke-width: 0.5px;
}

.resultSec{
    display: flex;
    flex-direction: column;
    gap: 12px;
}