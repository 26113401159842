  /* OurAim Section */
  .ourAim_section{
    padding-top: 70px;
    padding-bottom: 70px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 12px;
    width: 100%;
    background: linear-gradient(175deg, #00141A -18.79%, #003A4B 102.02%); 
    /* position: relative; */
   }
   .ourAim_Inner{
    position: relative;
   }
   .ourAim_section p{
    margin: 0px;
    color: #8ACADC;
    text-align: center;
    font-family: Uniform;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
   }
   .ourAim_section h1{
    margin: 0px;
    color: #ffffff;
    text-align: center;
    font-family: Uniform;
    font-size: 40px;
    font-style: normal;
    font-weight: 800;
    line-height: 60px;
   }
   .ourAim_section .ourAim_autherName{
    color: #007EA2;
    text-align: center;
    font-family: Uniform;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    margin-top:42px;
   }

   .ourAimPara{
    position: relative;
   }

   .aim_StartQuote {
    width: 150px;
    position: absolute;
    left: -140px;
    top: -92px;
    fill: #FFF;
    opacity: 0.2;
  }
  .aim_EndQuote {
    width: 150px;
    position: absolute;
    bottom: -85px;
    right: -138px;
    fill: #FFF;
    opacity: 0.2;
  }

   .not_text{
    color: #ffffff;
    font-weight: 800;
   }

   /* Screen For 1680*1050 */
   @media screen and (min-width: 1621px) and (max-width: 1820px) {
  }
  /* Screen For 1440*900 */
  @media screen and (min-width: 1281px) and (max-width: 1620px) {
    
  }
  /* Screen For 1280*1024 */
  @media screen and (max-width: 1280px) { }
  /* Screen For 1024*600 */
  @media screen and (min-width: 992px) and (max-width: 1242px) {
    .container {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: auto;
        width: 90%;
    }
    
     .aim_StartQuote {
        width: 140px;
        left: -90px;
    }
    .aim_EndQuote {
        width: 140px;
        right: -85px;
    }
    

  }

  @media screen and (min-width: 768px) and (max-width: 992px) {
    .ourAim_section h1 {
        font-size: 30px;
    }
    .aim_StartQuote {
        width: 120px;
        left: -70px;
    }
    .aim_EndQuote {
        width: 120px;
        right: -70px;
    }
    
  }
  @media screen and (max-width: 767px) {
    .ourAim_section h1 {
        font-size: 25px;
    }
    .ourAim_section p {
        font-size: 20px;
    }
    .aim_StartQuote {
        width: 120px;
        left: -35px;
    }
    .aim_EndQuote {
        width: 120px;
        right: -35px;
    }
    

   }
   @media screen and (max-width: 575px) {
    body {
        display: block;
        margin: 0px;
    }
    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: auto;
        width: 80%;
        overflow: hidden;
    }
    /* Our Aim Section */
    .ourAim_section {
        padding-top: 35px;
        padding-bottom: 40px;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 12px;
        width: 100%;
        background: linear-gradient(175deg, #00141A -18.79%, #003A4B 102.02%);
    }
    .ourAim_section h1 {
        margin: 0px;
        color: #ffffff;
        text-align: center;
        font-size: 17px;
        font-style: normal;
        line-height: 40px;
    }
    .ourAim_section p {
        margin: 0px;
        color: #8ACADC;
        text-align: center;
        font-size: 13px;
        font-style: normal;
        line-height: normal;
    }
    .aim_StartQuote {
        width: 75px;
        left: -20px;
        top: -28%;
    }
    .aim_EndQuote {
        width: 75px;
        right: -20px;
        bottom:-33%;
    }

   

   }
   /* ----------------------------------------------400----------------------------------------- */
    
   @media (min-width: 300px) and (max-width: 400px) {
    body {
        display: block;
        margin: 0px;
    }
    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: auto;
        width: 75%;
    }
    /* Our Aim Section */
    .ourAim_section {
        padding-top: 35px;
        padding-bottom: 35px;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 12px;
        width: 100%;
        background: linear-gradient(175deg, #00141A -18.79%, #003A4B 102.02%);
    }
    .ourAim_section h1 {
        margin: 0px;
        color: #ffffff;
        text-align: center;
        font-size: 15px;
        font-style: normal;
        line-height: 40px;
    }
    .ourAim_section p {
        margin: 0px;
        color: #8ACADC;
        text-align: center;
        font-size: 12px;
        font-style: normal;
        line-height: normal;
    }

    .aim_StartQuote {
        width: 75px;
        left: 5px;
        top: -28%;
    }
    .aim_EndQuote {
        width: 75px;
        right: 5px;
        bottom:-33%;
    }
    

}

