.InveBoundariesCon{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--M, 12px);
    align-self: stretch;
}

.invBoundInnerCon{
    display: flex;
    padding: var(--L, 16px);
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    gap: var(--M, 12px);
    align-self: stretch;
    border-radius: 4px;
    background: #FAFAFA;
}

.invBoundRow{
    display: flex;
    flex-direction: row;
    width: 100%;
}

.invBoundInnerCon .invBoundRow .invBoundCol1 h1{
    color: var(--Neutral-Charcoal-Black-500, #1D1D1B);
    font-family: Uniform;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-transform: uppercase;
    margin: 0;
    -webkit-text-stroke-width: 0.3px;
}

.invBoundInnerCon .invBoundRow .invBoundCol2 p{
    color: var(--Neutral-Charcoal-Black, #1D1D1B);
    font-family: Uniform;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
    -webkit-text-stroke-width: 0.3px;
}

.invBoundCol1{
    width: 50%;
}

.invBoundCol2{
    width: 50%;
}

.emissOffset1row{
    display: flex;
    flex-direction: row;
    width: -webkit-fill-available;
}

.emissOffset1Col1{
    width: 50%;
}

.emissOffset1Col2{
    width: 50%;
    display: flex;
    justify-content: flex-end;
}