

.preview_section {
    background: linear-gradient(175deg,#00141a -18.79%,#003a4b 102.02%);
    display: flex;
    flex-direction: row;
    align-items:stretch;
    margin: auto;
    width: -webkit-fill-available; 
    min-height: 100vh;
    overflow: hidden; 
    }
  
    .previewSec_Col1 {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      width: 25%;
      padding: 4% 2% 0% 2%;
      align-items: center;
    }
    .previewSec_Col2 {
      background-color: #F7F7F7;
      padding: 8% 8% 8% 8%;
      width: 75%;
      display: flex;
      flex-direction: column;
    }

    .basicDetailsEdit_section{
       border-radius: 4px;
       background: #ffffff;
       padding: 20px; 
       display: flex;
       flex-direction: column;
       gap: 12px;
    }
    .orgDetailsEdit_section{
        border-radius: 4px;
        background: #ffffff;
        padding: 20px; 
        margin-top: 2rem;
        display: flex;
        flex-direction: column;
        gap: 12px;
     }
    
    .titleRow{
      margin-bottom: 1rem;
    }

    .edit_div{
        display: flex;
        flex-direction: row;
    }
    .edit{
        color: #008BB2;
        text-align: right;
        font-family: Uniform;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        display: flex;
        align-items: center;
    }
    .edit_div h3{
     color:#003A4B;
     font-family: Uniform;
     font-size: 20px;
     font-style: normal;
     font-weight: bolder;
     line-height: normal;
     margin: 0;
     margin-bottom: 24px;
    }
    .editIcon{
      width: 35%;
      height: auto;
    }
    .basicDetailsEdit_section p{
        font-family: Uniform;
        font-size: 15px;
        font-style: normal;
        font-weight: 800;
        line-height: 20px;
      }
    .rowDiv {
        display: flex;
        flex-direction: row;
        margin-top: 2rem;
        margin-bottom: 2rem;
      }
    .detailsRowDiv{
        display: flex;
        flex-direction: row;
        /* margin-bottom: 12px; */
    }

    .webLinkData{
      margin-left: 24px !important;
    }
  
    .preHoriLine{
      margin-top: 40px;
    }

    .preTitle{
      color: var(--neutral-charcoal-black-300, #686866);
      font-family: Uniform;
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      text-transform: uppercase;
      margin: 0;
    }

    .preValue{
      color: var(--primary-aqua-blue-700, #00637E);
      font-family: Uniform;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      margin: 0;
      overflow-wrap: anywhere !important;
      /* -webkit-text-stroke: 0.2px; */
    }

    .releventDelDiv{
      display: flex;
      flex-direction: column;
      gap: 12px;
      align-items: flex-start;
    }

    .preOtherDelText{
      color: var(--Neutral-Charcoal-Black, #1D1D1B);
      font-family: Uniform;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin: 0;
      -webkit-text-stroke-width: 0.5px;
    }
    

    .preViewText{
      color: var(--primary-aqua-blue, #008BB2);
      font-family: Uniform;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      margin: 0;
      text-transform: lowercase;
      margin-left: 8px !important;
      -webkit-text-stroke-width: 0.2px;
    }

    .passSec{
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .viewSec{
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-left: 8px;
    }

    .checkboxTitle{
        min-width: 200px;
        margin: 0;
        color: #1D1D1B;
        font-family: Uniform;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
        margin-top: 20px; 
    }
     .upload_regDetails {
    font-family: Uniform !important;
    font-size: 16px !important;
    font-weight: 800 !important;
    line-height: 20px !important;
    }
    .preview_headQuarter{
      border-radius: 4px;
      background: #F7F7F7;
      padding: 12px;
      display: flex;
      flex-direction: column;
      gap: 12px;
    }
    .preview_headQuarter .Headquarters_title {
      color: #003A4B;
      font-family: Uniform;
      font-size: 15px;
      font-style: normal;
      font-weight: 800;
      line-height: normal;
      margin: 0;
    }

    .headqDetails{
      display: flex;
      flex-direction: row;
    }
    
    .removeOtherDetails_div{
      display: flex;
      justify-content:  flex-end;;
    }

    .otherAddBranch_div{
        display: flex;
        padding: 0rem 1rem;
        margin-bottom: 1rem;
    }

    .removeSec_row .inputBox_mb{
     margin-bottom: 0;
    }
      
    .pdf_Row{
        display: flex;
        flex-direction: row;
    }
    
    .previewSec_Col1 h1{
      color: var(--primary-aqua-blue-200, #8ACADC);
      font-family: Uniform;
      font-size: 28px;
      font-style: normal;
      font-weight: 800;
      line-height: normal;
    }
    .previewSec_Col1 h2{
      color: var(--neutral-pure-white, #FFF);
      font-family: Uniform;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin: 0;
    }
    .previewSec_Col1 p{
      color: var(--neutral-pure-white, #FFF);
      font-family: Uniform;
      font-size: 14px;
      font-style: normal;
      font-weight: 100;
      line-height: 24px ;
    }   
    .preview_logoDiv{
      /* height: 8vh; */
    }
    .preview_logoDiv img {
      width: 112.678px;
      height: 44px; 
    }
    .preview_timeLineContents{
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
    .previewSec_Col1 .preview_timeLineContents p{
      font-family: Uniform;
      font-size: 16px;
      font-style: normal;
      font-weight: 100;
      line-height: 24px ;
     }
    
    .previewSec_Col2 p {
      font-family: Uniform;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 25px;
      margin: 0;
    }

   .previewSec_Col2 h1 {
      font-family: Uniform;
      font-size: 28px;
      font-weight: 900;
      text-align: left;
      margin: 0 0 0px 0;
      -webkit-text-stroke-width: 1px;
      margin-bottom: 40px;
    }
    
    .editContainer{
      display: flex;
      justify-content: flex-end;
    }

    .removeSec_row{
        display: flex;
        flex-direction: row;
        align-items: center !important;
    }
    
    .preview_topBg{
      height: 19rem;
      position: absolute;
      right: -181px;
      top: 0%;
      opacity: 0.2;
     }
     .preview_bottomBg{
      height: 9rem;
      position: absolute;
      right: 0%;
      top: 35%;
      opacity: 0.2;
     }
  
     .preview_leftBg{
      height: 6rem;
      position: absolute;
      left: 0%;
      top: 18%;
      opacity: 0.2;
     }
  
    .cancelBtn_Row{
      display: flex;
      justify-content: flex-end;
    }
    .NextBtn{
      display: contents;
    }
    .od_BtnRow{
      display:flex;
      justify-content: space-between;
    }

    .PrePassBlock{
      margin: 0;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      font-weight: 400;
    }

    .password-hidden{
      margin-top: 5px;
    }

    .previewPdfContainer{
      display: flex;
      flex-direction: row;
      width: 98%;
      gap: 2%;
    } 

    .preview_pdfRow {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 20px 25px;
    }
    
    .previewPdfContainer {
      box-sizing: border-box;
    }

    .preOtherDocs{
      
    }
    
    
      /* Screen For 1440*900 */
      /* @media screen and (min-width: 1281px) and (max-width: 1620px) {
        .preview_timeLineContents {
          width: 80%;
        }  
        .previewSec_Col1 h1{
          font-size: 52px;
        }
        .previewSec_Col1 h2{
          font-size: 24px;
        }
        .previewSec_Col1 p{
          font-size: 16px;
        }
    
      } */
    
     /* Screen For 1280*1024 */
     /* @media screen and (max-width: 1280px) {
      
      .previewSec_Col1 {
        width: 55%;
      }
      .previewSec_Col2 {
        width: 45%;
      }
      .preview_timeLineContents {
        width: 93%;
      }  
      .previewSec_Col1 h1{
        font-size: 52px;
      }
      .previewSec_Col1 h2{
        font-size: 22px;
      }
      .previewSec_Col1 p{
        font-size: 14px;
      }
      .previewSec_Col2 p {
        font-family: Uniform;
        font-size: 14px;
      }
    
      } */
       /* Screen For 1024*600 */
      /* @media screen and (min-width: 992px) and (max-width: 1242px) {
    
      } */
    
    
     
    
    
     