

.sideMenuContainer{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
}

.adminLogoSec{
    display: flex;
    padding: 32px 32px 24px 32px;
    align-items: center;
    gap: 16px;
    align-self: stretch;
    justify-content: space-between;
}

.adminLogoSec{
    display: flex;
    padding: 32px 32px 24px 32px;
    align-items: center;
    gap: 16px;
    align-self: stretch;
}

.menuTitleContainer{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    padding: 0px 20px;
}

.menuTitles{
    display: flex;
    padding: 8px 12px;
    gap: 8px;
    align-self: stretch;
}

.adminMenuTitle{
    cursor: pointer;
    width: -webkit-fill-available;
    display: flex;
    flex-direction: row;
    gap: 7px;
}

.sideMenuCol{
    width: -webkit-fill-available;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.sideMenuCol h3{
    color: var(--Neutral-Charcoal-Black-200, #8ACADC);
    font-family: Uniform;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    -webkit-text-stroke-width: 0.2px;
    margin: 0;
}

.adminMenuRow{
    width: -webkit-fill-available;
    display: flex;
}

/* .adminMenuTitle h1 {
    color: var(--Neutral-Pure-White, #FFF);
    font-family: Uniform;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
} */

.admMenuActive {
    color: var(--Neutral-Pure-White, #FFFFFF);
    font-family: Uniform;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
}

.admMenuInactive {
    color: var(--Neutral-Pure-White, #B9B9B8);
    font-family: Uniform;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
}

.adminMenuTitle img {
    /* width: 19px;
    height: auto; */
}

.feedBackImg {
    width: 19px;
    height: auto;
}

.roleMImg{
    /* width: 19px; */
}

.dashImag{
    /* width: 19px; */
}