.offsetEmis{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap:4px;
  }
  .offsetEmis1{
    width: 49%;
  }
  .offsetEmis2{
    width: 49%;
  }