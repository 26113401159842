.snackbar {
    background-color: #070616 !important;
  
    &.success {
      border-radius: 6px !important;
      margin: auto;
      border: 1px solid rgba(#a6faaf, 40%) !important;
  
      .container {
        background-color: rgba(#a6faaf, 0.1) !important;
        display: flex !important;
        align-items: center;
        gap: 10px;
        padding: 6px 10px 6px 18px !important;
      }
  
      p {
        color: lighten(#a6faaf, 10%) !important;
      }
  
      button {
        color: lighten(#a6faaf, 10%) !important;
      }
    }
  
    &.error {
      border: 1px solid (rgba#f67476, 40%) !important;
      border-radius: 6px !important;
      margin: auto;
  
      .container {
        background-color: rgba(#f67476, 0.1) !important;
        display: flex !important;
        align-items: center;
        gap: 10px;
        padding: 6px 10px 6px 18px !important;
      }
  
      p {
        color: lighten(#f67476, 10%) !important;
      }
  
      button {
        color: lighten(#f67476, 10%) !important;
      }
    }
  
    &.info {
      border: 1px solid rgba(#c8d8ff, 40%) !important;
      border-radius: 6px !important;
      margin: auto;
  
      .container {
        background-color: rgba(#c8d8ff, 0.1) !important;
        display: flex !important;
        align-items: center;
        gap: 10px;
        padding: 6px 10px 6px 18px !important;
      }
  
      p {
        color: lighten(#c8d8ff, 10%) !important;
      }
  
      button {
        color: lighten(#c8d8ff, 10%) !important;
      }
    }
  
    &.warning {
      border: 1px solid rgba(#ffc75b, 40%) !important;
      border-radius: 6px !important;
      margin: auto;
  
      .container {
        background-color: rgba(#ffc75b, 0.1) !important;
        display: flex !important;
        align-items: center;
        gap: 10px;
        padding: 6px 10px 6px 18px !important;
      }
  
      p {
        color: lighten(#ffc75b, 10%) !important;
      }
  
      button {
        color: lighten(#ffc75b, 10%) !important;
      }
    }
  }