.currentratSection{
    display: flex;
    width: calc(25% - 10.6px);
    box-sizing: border-box;
    padding: 24px 24px 0px 24px;
    align-items: flex-start;
    gap: 24px;
    border-radius: 8px;
    background: linear-gradient(122deg, #EAF6ED 6.8%, #BDE4C7 100.83%);
    box-shadow: 0px 4px 40px 0px #E8E8E8;
    flex-direction: column;
    justify-content: space-between;
}

.curRatHeading{
    color: var(--neutral-charcoal-black-200, #979796);
    font-family: Uniform;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    margin: 0;
}

.curRatInner h3{
    color: var(--neutral-charcoal-black, #1D1D1B);
    font-family: Uniform;
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 1.92px;
    margin: 0;
    -webkit-text-stroke-width: 2px;
}

.validDate{
    color: var(--neutral-charcoal-black-200, #979796);
    font-family: Uniform;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
    margin-top: 12px;
}

.curRatImg{
    display: flex;
    justify-content: flex-end;
    width: -webkit-fill-available;
}

.curRatImg img{
    display: flex;
    align-items: flex-end;
    width: -webkit-fill-available;
}



