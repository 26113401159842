.directEmis{
    gap:2%;
  }
  .directEmis1{
    width: 49%;
  }
  .directEmis2{
    width: 49%;
  }

  .facilitySec{
    width: 90%;
  }

  .country{
    display: flex;
    gap:2%;
  }
  .country1{
    width: 28%;
  }
  .country2{
    width: 69%;
  }

  .flexwrap{
    display: flex;
    flex-wrap: wrap;
  }

  .emisHeat{
    gap:2%
  }
  .emisHeat1{
    width: 49%;
    margin-bottom: 25px;
  }
  .emisHeat2{
    width: 49%;
    margin-top: -8px;
    margin-bottom: 25px;
  }

  .emisHeat2 textarea{
    width: -webkit-fill-available !important;
   }

   .infoOnEmisPdfSec .pdfUpload {
       width: fit-content;
   }