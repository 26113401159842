.addButtonContainer{
    display: flex;
    flex-direction: row;
    border-radius: 4px;
    padding: 8px;
    gap: 4px;
}

.addButtonContainer p{
    color: var(--Primary-Aqua-Blue, #008BB2);
    font-family: Uniform;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    margin: 0;
    -webkit-text-stroke-width: 0.5px;
}