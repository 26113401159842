.initiative1Con {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--M, 12px);
  align-self: stretch;
}

.locationCon {
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: 20px;
  align-self: stretch;
}

.locationCon h1 {
  color: #1d1d1b;
  font-family: Uniform;
  font-size: 12px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  margin: 0;
  -webkit-text-stroke-width: 0.1px;
}

.locationCol1 {
  width: 20%;
}

.locationCol2 {
  width: 40%;
}

.locationCol3 {
  width: 40%;
}

.initiativeDesc {
  width: -webkit-fill-available;
}

.timlineRightCol {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 51vw;
  flex-wrap: wrap;
}

