.viewCommentContainer {
  display: flex;
  align-items: center;
  gap: 8px;
}

.viewCommentCol1 {
  display: flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
}

.viewCommentCol1 p {
  color: var(--Secondary-Royal-Blue, #0300b2);
  text-align: center;
  font-family: Uniform;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  margin: 0;
  -webkit-text-stroke-width: 0.2px;
}

.viewCommentCol2 {
  display: flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
}

.viewCommentCol2 p {
  color: var(--Secondary-Magenta, #a20074);
  text-align: center;
  font-family: Uniform;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  margin: 0;
  -webkit-text-stroke-width: 0.2px;
}

.viewCommentBtnSec {
  display: flex;
  /* padding-left: 260px; */
  align-items: center;
  gap: 8px;
}
