.infoEmisScopesRow{
    display: flex;
    align-items: flex-start;
    gap: 20px;
    align-self: stretch;
    width: -webkit-fill-available;
}

.infoEmisScopesCol1{
    width: 20%;
}
.infoEmisScopesCol2{
    width: 40%;
}
.infoEmisScopesCol3{
    width: 40%;
}
