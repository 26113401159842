
 /* About Section */
 .about_section{
    padding-top: 80px;
    padding-bottom: 120px; 
   }
  .aboutSec_watermark{
    background-image: url('../../../../assets/AboutUsWatermark.png');
    background-size: 70% 55%;
    background-position: left center;
    background-repeat: no-repeat;
    position: absolute ! important;
    left: 0%;
  }

  .container.about_section {
    position: relative;
  }

  .aboutBackground-text {
    bottom: 0;
    font-family: 'Woodman-HeavyRounded';
    font-size: 202px;
    left: -175px;
    line-height: 200px;
    opacity: 0.09;
    pointer-events: none;
    position: absolute;
    right: 0;
    text-align: left;
    top: 90px;
    z-index: -1;
    color: transparent;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: black;
}
  
  
  .aboutSec_Col1{
   width: 60%;
   display: flex;
   flex-direction: column;
   gap: 32px;
  }
  .aboutSec_Col2{
    width: 40%;
    display: flex;
    justify-content: right;
   }
  .aboutSec_Col1 h1{
    color: var(--neutral-charcoal-black, #1D1D1B);
    margin: 0;
    font-family: Woodman-HeavyRounded ;
    font-size: 40px;
    text-align: left;
    font-weight: 400;
   }
   .aboutSec_Col1 p{
    margin: 0;
    color: var(--neutral-charcoal-black, #1D1D1B);
    font-family: Uniform;
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    line-height: 28px;
    width: 90%;
    -webkit-text-stroke-width: 0.2px;

   }
   .aboutSec_Col2 img{
    width: 60%;
    height: auto;
    margin-top: 3.5rem;
   }
   .about_p{
    margin-top: 22px;
   }

    /* Screen For 1680*1050 */
    @media screen and (min-width: 1621px) and (max-width: 1820px) {
        .about_section {
            padding-top: 25px;
            padding-bottom: 80px;
        }
        .aboutBackground-text{
            top: 33px;
            left: -175px;
        }
    
    }
    /* Screen For 1440*900 */
    @media screen and (min-width: 1281px) and (max-width: 1620px) {
        .aboutBackground-text {
            bottom: 0;
            left: -12.8%;
            position: absolute;
            right: 0;
            text-align: left;
            top: 2%;
        }
        .about_section {
            padding-top: 25px;
            padding-bottom: 80px;
        }
        .aboutSec_Col2 img {
            width: 65%;
        }
    }

    /* Screen For 1280*1024 */
     @media screen and (max-width: 1280px) {
       .aboutBackground-text {
        left: -155px;
        top: 8px;
        }
        .about_section {
            padding-top: 25px;
            padding-bottom: 80px;
        }
    }
    /* Screen For 1024*600 */
    @media screen and (min-width: 992px) and (max-width: 1242px) {
    
        .container {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: auto;
            width: 90%;
        }
        .aboutSec_Col2 img {
            width: 92%;
            height: auto;
        }
        .aboutBackground-text {
            top: -10px;
            left: -80px;
            font-size: 130px;
        }
       
        .about_section {
            padding-top: 25px;
            padding-bottom: 80px;
        }
        
    
    }

    @media screen and (min-width: 768px) and (max-width: 992px) {
        .container.about_section {
            position: relative;
            display: flex;
            flex-direction: column;
        }
        .aboutSec_Col2 img {
            width: 85%;
            height: auto;
        }
        .aboutSec_Col1 {
            width: 100%;
        }
        .aboutSec_Col2{
            display: none;
        }
        .aboutBackground-text {
            top: -14px;
            left: -65px;
            font-size: 130px;
        }
        .aboutSec_Col1 p {
            width: 100%;
        }

    }
    @media screen and (max-width: 767px) {
        .aboutSec_Col2 img {
            width: 85%;
            height: auto;
        }
        .container.about_section {
            position: relative;
            display: flex;
            flex-direction: column;
        }
        .aboutSec_Col2 img {
            width: 85%;
            height: auto;
        }
        .aboutSec_Col1 {
            width: 100%;
        }
        .aboutSec_Col2{
            display: none;
        }
        .aboutBackground-text {
            top: -14px;
            left: -65px;
            font-size: 130px;
        }
        .aboutSec_Col1 p {
            width: 100%;
        } 
    }

    @media screen and (max-width: 575px) {
        body {
            display: block;
            margin: 0px;
        }
        .aboutBackground-text {
            display: none;
        }
        .aboutSec_Col2 img{
          display: none;
        }
        .container {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: auto;
            width: 80%;
            overflow: hidden;
        }
        /* About Section */
        .container.about_section {
            padding-top: 35px;
            padding-bottom: 60px;
            width: 90%;
        }
        .aboutBackground-text {
            text-align: left;
            position: absolute;
            top: 13px;
            left: 0;
            right: 0;
            bottom: 0;
            font-size: 128px;
        }
        .aboutSec_Col1 {
            width: 100%;
        }
        .aboutSec_Col2 {
            width: 100%;
            display: flex;
            justify-content: center;
        }
        .aboutSec_Col1 h1 {
            font-size: 23px;
        }
        .aboutSec_Col1 p {
            font-size: 15px;
            width: 100%;
        }
      
    
    }
    /* ----------------------------------------------400----------------------------------------- */
    
    @media (min-width: 300px) and (max-width: 400px) {
        body {
            display: block;
            margin: 0px;
        }
        .container {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: auto;
            width: 75%;
        }
      /* About Section */
        .about_section {
            padding-top: 35px;
            padding-bottom: 60px;
        }
        .aboutBackground-text {
            text-align: left;
            position: absolute;
            top: 13px;
            left: 0;
            right: 0;
            bottom: 0;
            font-size: 128px;
        }
        .aboutSec_Col1 {
            width: 100%;
        }
        .aboutSec_Col2 {
            width: 100%;
            display: flex;
            justify-content: center;
        }
        .aboutSec_Col1 h1 {
            font-size: 23px;
        }
        .aboutSec_Col1 p {
            font-size: 15px;
            width: 100%;
        }
    
    }
