.dash-timeline {
    background-color: transparent;
    width: 100%;
}

.rating-timeline {
    background-color: white;
}

.rating-timeline ul {
    list-style: none;
    margin: 0;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 40px;
}

.rating-timeline ul li input[type="radio"].active:after {
    content: '';
    position: absolute;
    display: block;
    width: 12px;
    height: 12px;
    border: 2px solid #3e97d7;
    border-radius: 2px;
    transform: rotate(45deg);
    z-index: 11;
    margin: 6px;
}

.rating-timeline ul li input[type="radio"].completed:after {
    content: '\2713';
    position: absolute;
    display: block;
    font-size: large;
    top: 0px;
    color: white;
    z-index: 11;
    margin: 6px;
}

.rating-timeline ul input[type="radio"].completed:before {
    content: '';
    position: absolute;
    display: block;
    width: 22px;
    height: 22px;
    background-color: rgb(23, 179, 70);
    border: 4px solid rgb(23, 179, 70);
    border-radius: 6px;
    z-index: 10;
}

.rating-timeline ul input[type="radio"]:before {
    content: '';
    position: absolute;
    display: block;
    width: 22px;
    height: 22px;
    background-color: white;
    border: 4px solid white;
    outline: 1px solid #98dede;
    border-radius: 6px;
    z-index: 10;
}

.rating-timeline li {
    display: flex;
    align-items: center;
    gap: 20px;
    flex-direction: column;
}

.rating-timeline label {
    font-size: 12px;
    color: black;
}

.rating-timeline ul:before {
    content: '';
    position: absolute;
    display: block;
    left: 0;
    top: 10px;
    width: 100%;
    height: 2px;
    background: linear-gradient(to right, #00bd00 0%, #00bd00 5%, #add 8%, #add 100%);
    margin-top: 7px;
}

.dashTime li label {
    color: var(--neutral-charcoal-black-300, #686866);
    text-align: center;
    font-family: Uniform;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
