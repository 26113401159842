
.orgsRatingBread .orgsRatingText{
    color: #686866 !important;
    font-family: Uniform !important;
    font-size: 12px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: normal !important;
    text-decoration-line: none !important;
}

.orgsRatingBread .adminOrgsUser {
    color: #008BB2 !important;
    font-family: Uniform !important;
    font-size: 12px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: normal !important;
}