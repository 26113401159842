.profileLogOutBtn button{
    display: flex;
    padding: 12px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 4px;
    background: var(--Secondary-Emerald-Green, #000000);
}

.profileLogOutBtn button div{
    color: var(--Neutral-Pure-White, #FFF);
    text-align: center;
    font-family: Uniform;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}