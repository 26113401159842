
.proViiewDetailContainer{
    display: flex;
    align-items: flex-start;
    gap: 20px;
    align-self: stretch;
}

.proViewDetCol1{
    display: flex;
    flex-direction: column;
    gap: 24px;
    border-radius: 8px;
    border: 1px solid #E8E8E8;
    background: #FFF;
    /* box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.08); */
    width: 344px;
    padding-bottom:24px;
}

.proViewDetCol2{
    display: flex;
    flex-direction: column;
    flex: 1 0 0;
    height: 100%;
}

.profileUserSec{
    background: url('../../../assets/bgImages/profileBG.svg') center/cover no-repeat;
    background-size: contain;
    background-position: top;
}

.profileBottomDiv {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-top: 0;
    padding: 16px 16px 24px;
}

.profileImgDiv{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
    padding: 0 25px;
}


.profileUserSec img{
    display: flex;
    width: 120px;
    height: 120px;
    align-items: flex-start;
    gap: 10px;
    border-radius: 200px;
    border: 4px solid #1D1D1B;
}

.profileUserSec h1{
    color: var(--Neutral-Charcoal-Black, #1D1D1B);
    font-family: Uniform;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin: 0;
    margin-top: 10px;
    -webkit-text-stroke-width: 0.5px;
}

.profileUserSec p{
    text-align: center;
    color: var(--Primary-Aqua-Blue-300, #54B1CB);
    font-family: Uniform;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    margin: 0;
    margin-top: 5px;
    -webkit-text-stroke-width: 0.2px;
    overflow-wrap: anywhere;
}

.profileHorizontalLine{
    width: 100%;
    border: 1px solid #1D1D1B;
    opacity: 0.1;
}

.requestedUser{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: -webkit-fill-available;
    gap: 3px;
}

.requestedUser p{
    color: var(--Neutral-Charcoal-Black-300, #686866);
    font-family: Uniform;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    margin: 0;
    -webkit-text-stroke-width: 0.2px;
}

.requestedUser h1{
    color: var(--Neutral-Charcoal-Black, #1D1D1B);
    font-family: Uniform;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
    -webkit-text-stroke-width: 0.5px;
    overflow-wrap: anywhere;
}

.adminProStatusBtn{
    display: flex;
    padding: 3px 6px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 4px;
    background: #F3A738;
}

.adminProStatusBtn p{
    color: var(--Neutral-Pure-White, #FFF);
    text-align: center;
    font-family: Uniform;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
    text-transform: capitalize;
}

.pastRegistraion p{
    color: var(--Neutral-Charcoal-Black, #1D1D1B);
    font-family: Uniform;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    margin: 0;
    -webkit-text-stroke-width: 0.8px;
    width: 75%;
}

.regisLogsRow{
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
}

.regisLogs{
    display: flex;
    padding: 8px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 4px;
    background: #1D1D1B;
    border: none;
}

.regisLogs p{
    color: var(--Neutral-Pure-White, #FFF);
    text-align: center;
    font-family: Uniform;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    margin: 0;
    -webkit-text-stroke-width: 0.2px;
}

