.priorityPreCon {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--XL, 24px);
  align-self: stretch;
  border-radius: 8px;
  background: #fff;
}

.pillarActionPreCon {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--M, 12px);
  align-self: stretch;
}

.priorityPreRow {
  display: flex;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
}

.priorityPreRow h2 {
  margin: 0;
  color: #4a4a49;
  font-family: Uniform;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-transform: uppercase;
  -webkit-text-stroke-width: 0.3px;
}

.priorityPreRow p {
  color: var(--Neutral-Charcoal-Black, #1d1d1b);
  font-family: Uniform;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin: 0;
  -webkit-text-stroke-width: 0.3px;
}

.priorityPreCol1 {
  width: 25%;
}

.priorityPreCol2 {
  width: 75%;
}
