.checkList_SectionDiv{
    background: var(--gradient-light-green, linear-gradient(122deg, #EAF6ED 6.8%, #BDE4C7 100.83%));
    display: flex;
    padding: 24px 24px 24px 24px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    align-self: stretch;
    border-radius: 8px;
    margin-top: 16px;
  }

  .leftCheckDiv{
    display: flex;
    justify-content: space-between;
    width: -webkit-fill-available;
    margin-bottom: 5px;
  }

  .secTitle_style {
    color:#003A4B !important;
    font-family: Uniform;
    font-size: 16px !important;
    font-style: normal;
    font-weight: 800 !important;
    line-height: normal;
    margin: 0;
  }

  .leftCheckPara{
    color: #686866;
    margin-bottom: 16px;
  }

  .documentLists{
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  .documentRow{
    display: flex;
    flex-direction: row;
  }

  .documentRow img{
    margin-right: 8px;
  }

  .documentRow p{
    color: #1D1D1B;
    margin: 0;
  }