.css-1rv8zyt-MuiStack-root {
  overflow: hidden !important;
  display: flex;
  padding: 12px;
  align-items: center;
  gap: 4px;
  align-self: stretch;
}

.css-z3c6am-MuiFormControl-root-MuiTextField-root {
  border-radius: 4px;
  border: 0.5px solid var(--Neutral-Charcoal-Black-100, #b9b9b8) !important;
}

.calenderContainer {
  display: flex;
  align-items: flex-start;
  gap: 20px;
}

.calenderInnerContainer {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 5px;
}

.dateField.error {
  border: 2px solid #F45B69 !important;
  box-shadow: 0 0 4px 0 rgba(244, 91, 105, 0.5) !important;
  color: #1D1D1B !important;
}

.calenderContainer p.inputError{
   text-wrap: nowrap;
}
