     /* Rating Section */
          .rating_section {
              display: flex;
              flex-direction: column;
              padding-top: 50px;
              padding-bottom: 20px;
              align-items: flex-start;
              position: relative;
              gap: 32px;
          }
          .ratingsBackground-text{
            bottom: 0;
            font-family: 'Woodman-HeavyRounded';
            font-size: 202px;
            left: -12%;
            line-height: 200px;
            opacity: 0.09;
            pointer-events: none;
            position: absolute;
            right: 0;
            text-align: left;
            top: 37px;
            z-index: -1;
            color: transparent;
            -webkit-text-stroke-width: 1px;
            -webkit-text-stroke-color: black;
        }
          .rating_section p{
            color: var(--neutral-charcoal-black, #1D1D1B);
            margin: 0;
            font-family: Uniform;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            -webkit-text-stroke-width: 0.2px;
          }
          .rating_section h1{
            color: var(--neutral-charcoal-black, #1D1D1B);
            margin: 0;
            font-family:Woodman-HeavyRounded;
            font-size: 40px;
            font-weight: 400;
          }
          .ratingImg_section{
            width: 100%;
            padding-top: 35px;
            display: flex;
            flex-direction: column;
          }
          .rating_section img{
            width: 100%;
          }
          .globle_ghg{
            margin-top: 20px;
          }
          .ratingEEE_row{
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content:center
          }
          .ratingEEE_cols{
            padding: 0px 40px;
            width: 15%;
            display: flex;
            flex-direction: column;
          }
          .EEE_box{
           display: inline-flex;
           padding: 5px 10px;
           justify-content: center;
           align-items: center;
           gap: 8px;
           border-radius: 12px;
           border: 1px solid var(--secondary-emerald-green, #2BA84A);
           background: #F8FFF9;

          }

          .EEE_box h1{
            text-align: center;
            font-family: Uniform;
            font-size: 40px;
            font-style: normal;
            font-weight: bolder;
            line-height: 45px;
          }
          .EEE_color1{
            color: #1F7735;
          }
          .EEE_color2{
            color: #279943;
          }
          .EEE_color3{
            color: #2BA84A;
          }
          .EEE_color4{
            color: #55B96E;
          }
          .EEE_color5{
            color: #71C586;
          }
          
          .dashes_line{
            width: 0px;
            height: 10vh;
            border: 1px dashed gray;
            margin: auto;
            display: flex;
            margin-top: 10px;
            margin-bottom: 10px;
            flex-direction: column;
          }
          .year_row{
            width: 100%;
            display: flex;
            padding: 8px 0px;
            justify-content: center;
            border-radius: 4px;
            background: #F7F7F7;
          }
         
          .year_row h2{
            color: #979796;
            text-align: center;
            font-family: Uniform;
            font-size: 20px;
            font-style: normal;
            font-weight: 800;
            line-height: normal;
          }
          .theQuicker_text h3{
            color: #1D1D1B;
            text-align: center;
            font-family: Woodman-HeavyRounded;
            font-size: 19px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-transform: uppercase;
          }

   /* Screen For 1680*1050 */
    @media screen and (min-width: 1621px) and (max-width: 1820px) { }
    
    /* Screen For 1440*900 */
    @media screen and (min-width: 1281px) and (max-width: 1620px) { }

    /* Screen For 1280*1024 */
    @media screen and (max-width: 1280px) {
      .rating_section{
        display: flex !important;
        flex-direction: column !important;
        align-items: flex-start !important;
        margin: auto;
      }

      .ratingsBackground-text{
        top: 36px;
      }
     }

    /* Screen For 1024*600 */
    @media screen and (min-width: 992px) and (max-width: 1242px) {
        .rating_section {
            padding-top: 60px;
            display: flex !important;
            flex-direction: column ! important;
        }
        .ratingsBackground-text{
          top: 25px;
          left: -8%;
          font-size: 130px;
        }
   }

   @media screen and (min-width: 768px) and (max-width: 992px) {
    .rating_section {
        padding-top: 60px;
        display: flex !important;
        flex-direction: column ! important;
        align-items: flex-start;
    }
    .rating_section h1 {
        text-align: left;
    }
    .ratingsBackground-text{
      top: 25px;
      left: -8%;
      font-size: 130px;
    }
   }

   @media screen and (max-width: 767px) {
    .rating_section h1 {
      font-size: 39px;
    }
    .ratingsBackground-text{
      top: 12px;
      left: -8%;
      font-size: 130px;
    }
    }

    @media screen and (max-width: 575px) {
        body {
            display: block;
            margin: 0px;
        }
        .ratingsBackground-text{
            display: none;
        }
        .container.rating_section{
          width: 90%;
        }
        /* Rating Section */
          .rating_section {
            padding-top: 15px;
          }
          .rating_section h1 {
            font-size: 23px;
            margin-top: 20px;
          }
    }

    /* ----------------------------------------------400----------------------------------------- */
    
    @media (min-width: 300px) and (max-width: 400px) {
        body {
            display: block;
            margin: 0px;
        }
       /* Rating Section */
          .rating_section {
            padding-top: 15px;
          }
          .rating_section h1 {
            font-size: 23px;
          } 
    }