
.userDetailsContainer th{
    color: #686866;
    font-family: Uniform;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    -webkit-text-stroke-width: 0.3px;
    border: none;
    text-align: left;
    padding: 9px 12px;
}

.userDetailsContainer td{
    overflow: hidden;
    color: var(--neutral-charcoal-black, #1D1D1B);
    text-overflow: ellipsis;
    font-family: Uniform;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 9px 12px;
}

.userDetailsContainer th, table tr:not(:last-child) td {
    width: -webkit-fill-available;
    border: none;
    padding: 9px 12px;
}

.userDetailTitle{
    border-radius: 4px;
    background: #EEE;
}

.listedUser{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 7px;
}

.listedUser img{
    width: 28px;
    height: 28px;
}

.userGmailData{
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    text-align: left;
}

.listedUser h1{
    overflow: hidden;
    color: var(--neutral-charcoal-black, #1D1D1B);
    text-overflow: ellipsis;
    font-family: Uniform;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    -webkit-text-stroke-width: 0.2px;
    margin: 0;
}

.listedUser h3{
    overflow: hidden;
    color: var(--primary-aqua-blue, #008BB2);
    text-overflow: ellipsis;
    font-family: Uniform;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    -webkit-text-stroke-width: 0.2px;
    margin: 0;
}

.adminUserList{
    border-radius: 4px;
    border-bottom: 1px solid #b9b8b824;
}

.adminUserList td p{
    overflow: hidden;
    color: var(--neutral-charcoal-black, #1D1D1B);
    text-overflow: ellipsis;
    font-family: Uniform;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    -webkit-text-stroke-width: 0.2px;
    margin: 0;
    text-align: left;
    width: 110px;
    overflow-wrap: anywhere;
}
  
.activeBtn{
    display: flex;
    padding: 3px 6px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 4px;
    border: 0.5px solid #2BA84A;
    background: #EAF6ED;
}

.activeBtn span{
    color: var(--secondary-emerald-green-900, #12471F);
    text-align: center;
    font-family: Uniform;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    -webkit-text-stroke-width: 0.2px;
    margin: 0;
}

.rejectBtnStyle {
    display: flex;
    padding: 3px 6px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 4px;
    border: 0.5px solid #F45B69;
    background: #FEEFF0;
  }

.inactiveStatus {
    display: flex;
    padding: 3px 6px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 4px;
    border: 0.5px solid #979796;
    background: #E8E8E8;
  }

.toggleMenu{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 24px;
}

  /* toggle in label designing */
  .toggle {
    position : relative ;
    display : inline-block;
    width: 32px;
    height: 16px;
    background-color: 008BB2;
    border-radius: 30px;
    background: #008BB2;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.12) inset;
}
       
/* After slide changes */
.toggle:after {
    content: '';
    position: absolute;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background-color: #FFF;
    top: 1px; 
    left: 1px;
    transition:  all 0.5s;
}

.checkbox:checked + .toggle::after {
    left : 16px; 
}
       
.checkbox:checked + .toggle {
    background-color: green;
}

.checkbox { 
    display : none;
}

.orgRatingTable{
    /* background-color: #ffffff; */
}

.orgCompanyLogo{
    background-color: #E8E8E8;
    padding: 8px;
    border-radius: 25px;
}

.orgProManager{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 7px;
}

.orgProManager h1{
    overflow: hidden;
    color: var(--neutral-charcoal-black, #1D1D1B);
    text-overflow: ellipsis;
    font-family: Uniform;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    -webkit-text-stroke-width: 0.2px;
    margin: 0;
}

.orgProManager h3{
    overflow: hidden;
    color: var(--primary-aqua-blue, #008BB2);
    text-overflow: ellipsis;
    font-family: Uniform;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    -webkit-text-stroke-width: 0.2px;
    margin: 0;
}

td.feedbackAction p{
    overflow: hidden;
    color: var(--primary-aqua-blue, #008BB2);
    text-overflow: ellipsis;
    font-family: Uniform;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    -webkit-text-stroke-width: 0.2px;
    margin: 0;
}

.listedUser .admFeedbackTitle p{
    overflow: hidden;
    color: var(--neutral-charcoal-black, #1D1D1B);
    text-overflow: ellipsis;
    font-family: Uniform;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    -webkit-text-stroke-width: 0.2px;
    margin: 0;
    width: 200px;
    text-align: left;
    overflow-wrap: anywhere;
}
.feedbackBy{
    display: flex;
    gap: 12px;
}

.feedbackByBorder{
    display: flex;
    border-right: 1px solid #1D1D1B;
    height: 2vh;
    opacity: 0.2;
}

.feedbackBy h2{
    color: var(--secondary-emerald-green-900, #4A4A49);
    text-align: center;
    font-family: Uniform;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    -webkit-text-stroke-width: 0.2px;
    margin: 0;
}

.roleManagTextCol{
    width: 145px;
}

.roleManageText{
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.roleManageText h1{
    color: var(--secondary-emerald-green-900, #1D1D1B);
    text-align: left;
    font-family: Uniform;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    -webkit-text-stroke-width: 0.5px;
    margin: 0;
}

div.roleManageText p{
    color: var(--secondary-emerald-green-900, #1D1D1B);
    text-align: left;
    font-family: Uniform;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    -webkit-text-stroke-width: 0.2px;
    margin: 0;
    width: 200px;
}

th.roleTd{
    width: 100px;
    text-align: center;
}

th.roleAdvMember{
    width: 100px;
    text-align: center;
}

.checkTd .css-j204z7-MuiFormControlLabel-root{
    display: flex;
    justify-content: center;
    margin-right: 0 !important;
}