  /* Today Situation Section */
  .situation_section{
    padding-top: 60px;
    padding-bottom: 0px; 
   }
  
  .situationSec_Col1{
   width: 55%;
  }
  .situationSec_Col2{
    width: 45%;
   }
   .situation_section h1{
    color: var(--neutral-charcoal-black, #1D1D1B);
    margin: 0;
    font-family: Woodman-HeavyRounded;
    font-weight: 400;
    font-size: 40px;
    line-height: 56px;
   }

  .situation_section p{
    color: var(--neutral-charcoal-black, #1D1D1B);
    margin: 0;
    margin-top: 10px;
    font-family: Uniform;
    font-style: normal;
    text-align: left;
    font-size: 14px;
    line-height: 28px; 
    font-weight: 400;
    -webkit-text-stroke-width: 0.2px;
   }
   .situationGraph_img img{
       width:100%;
   }
    .situationSec_Col1 h1{
        width: 70%;
    }

   /* Screen For 1680*1050 */
   @media screen and (min-width: 1621px) and (max-width: 1820px) {
      
   }
    /* Screen For 1440*900 */
    @media screen and (min-width: 1281px) and (max-width: 1620px) {
    }
    /* Screen For 1280*1024 */
    @media screen and (max-width: 1280px) {
    }
    /* Screen For 1024*600 */
    @media screen and (min-width: 992px) and (max-width: 1242px) {
       .container {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: auto;
            width: 90%;
        }
    }
    @media screen and (min-width: 768px) and (max-width: 992px) {
        .situation_section {
            display: flex;
            flex-direction: column !important;
            align-items: flex-start !important;
            margin: auto;
            width: 90%;
        } 
        .situationSec_Col1 {
            width: 100%;
        }   
        .situationSec_Col2 {
            width: 100%;
        }
    }
    @media screen and (max-width: 767px) {
        .situation_section {
            display: flex;
            flex-direction: column !important;
            align-items: flex-start !important;
            margin: auto;
            width: 90%;
        } 
        .situationSec_Col1 {
            width: 100%;
        } 
        .situationSec_Col2 {
            width: 100%;
        }
    }
    @media screen and (max-width: 575px) {
        body {
            display: block;
            margin: 0px;
        }
        .container.situation_section {
            width: 90%;
        }
        /* Situation Today Section */
        .situation_section {
            padding-top: 35px;
            padding-bottom: 0px;
        }
        .situationSec_Col1 {
            width: 100%;
        }
        .situationSec_Col2 {
            width: 100%;
        }
        .situationGraph_img img {
            width: 100%;
            height: 8rem;
        }
        .situationSec_Col1 h1{
            width: 100%;
            font-size: 23px;
        }
    }
     /* ----------------------------------------------400----------------------------------------- */
    
     @media (min-width: 300px) and (max-width: 400px) {
        body {
            display: block;
            margin: 0px;
        }
        /* Situation Today Section */
        .situation_section {
            padding-top: 35px;
            padding-bottom: 0px;
        }
        .situationSec_Col1 {
            width: 100%;
        }
        .situationSec_Col2 {
            width: 100%;
        }
        .situationSec_Col1 h1{
            width: 100%;
            font-size: 23px;
        }
        .situationGraph_img img {
            width: 100%;
        }
    
    }