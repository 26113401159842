.previewEditBtn{
    display: flex;
    align-items: center;
    background-color: #a20074;
    border-radius: 12px;
    padding: 2px 8px;
    margin-right: 20px;
}

.previewEditBtn p{
    color: var(--Neutral-Charcoal-Black, #FFF);
    font-family: Uniform;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
}