

  .signUp_section{
    background: linear-gradient(175deg,#00141a -18.79%,#003a4b 102.02%);
    display: flex;
    flex-direction: row;
    align-items: stretch;
    margin: auto;
    width: -webkit-fill-available !important; 
    min-height: 100vh !important;
    overflow: hidden;
  }
  .signSec_Col1 {
    position: relative;
    display: flex !important;
    flex-direction: column !important;
    justify-content: space-between !important;
    width: 65% !important;
    padding: 2% 0% 2% 8% !important;
  }
  .signSec_Col2 {
    background-color: #fff;
    padding: 0 5%;
    width: 35%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .signUpAlert{
    margin-top: -1rem;
   }
   .signUpAlert p {
    margin:0;
    color: red;
   }
  .signSec_Col1 h1{
    color: var(--primary-aqua-blue-200, #8ACADC);
    font-family: Uniform;
    font-size: 64px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
  }
  .signSec_Col1 h2{
    color: var(--neutral-pure-white, #FFF);
    font-family: Uniform;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
    -webkit-text-stroke-width: 1px;
  }
  .signSec_Col1 .signUp_divContents p{
    font-family: Uniform;
    font-size: 14px;
    font-style: normal;
    font-weight: 100;
    line-height: 24px !important;
    opacity: 0.8;
   }
  .signSec_Col1 p{
    color: var(--neutral-pure-white, #FFF);
    font-family: Uniform;
    font-size: 14px;
    font-style: normal;
    font-weight: 100;
    line-height: 24px !important;
  }   
  .signUp_logoDiv{
  }
  .signUp_logoDiv img {
    width: 112.678px;
    height: 44px;
   }
  .signUp_divContents{
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .signSec_Col2 p {
    font-family: Uniform;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
    margin: 0;
  }
  .signUp_section .signSec_Col2 h1 {
    color: #1D1D1B;
    font-family: Uniform;
    font-size: 28px;
    font-weight: 800;
    text-align: left;
    margin: 0 0 10px 0;
    -webkit-text-stroke-width: 1px;
  }

  .verifySec{
    display: flex;
    padding: 12px;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    border-radius: 4px;
    background: #EAF6ED;
    justify-content: center;
  }

   .verifySec p{
    color: var(--neutral-charcoal-black, #1D1D1B);
    text-align: center;
    font-family: Uniform;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    -webkit-text-stroke-width: 0.2px;
   }

  .OtpHeadingSec{
    display: flex;
    flex-direction: column;
   }

   .OtpHeadingSec p{
    color: var(--neutral-charcoal-black, #1D1D1B);
    font-family: Uniform;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    -webkit-text-stroke-width: 0.2px;
   }

   .verifiOtpBtn{
    display: flex;
    margin-top: 24px;
    width: 100%;
    align-items: center;
    justify-content: center;
    
   }

   .successHeading{
    display: flex;
    flex-direction: column;
    align-items: center;
   }

   .successHeading p{
    margin: 0;
   }

 .signUp_form{
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
 }
 
.Otp_title_container {
  display: flex;
  justify-content: center;
}

.Otp_title {
  padding-top: 15px;
  color: var(--primary-aqua-blue, #008BB2);
  text-align: center;
  font-family: Uniform;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  -webkit-text-stroke-width: 0.2px;
}

.count_down_mb{
  cursor: pointer;
margin-bottom: 55px;
}

.EditNameSec{
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}

.EditNameSec span{
  color: var(--neutral-charcoal-black, #1D1D1B);
  font-family: Uniform;
  font-size: 12px;
  font-style: normal;
  font-weight: 800;
  line-height: 20px;
  margin-right: 16px;
}

.EditNameDiv{
  display: flex;
  align-items: center;
}

p.nameEdit{
  color: var(--primary-aqua-blue, #008BB2);
  font-family: Uniform;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin: 0;
}
  
  .signUpBtn {
    background: #008BB2;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(0,139,178,.12);
    justify-content: center;
    width: 100% !important;
  }
  .submitBtn .signUp_BtnText {
    font-family: Uniform;
    color: #fff;
    font-size: 15px;
    font-weight: 500;
    padding: 12px 10px;
    text-align: center!important;
    margin: 0;
  }
   .signUpalready_HaveAcc {
    display: flex;
    justify-content: center;
}
   .signUpalready_HaveAcc p{
    color: var(--neutral-charcoal-black, #1D1D1B);
    font-family: Uniform;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: center;
    -webkit-text-stroke-width: 0.2px;
   }
   .signUpalready_HaveAcc span{
    color: #008BB2;
    font-family: Uniform;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
   }

  .signUp_topBg{
    position: absolute;
    right: -5%;
    top: 0%;
    opacity: 0.2;
   }
   .signUp_bottomBg{
    height: 25%;
    position: absolute;
    right: 20%;
    bottom: 0%;
    opacity: 0.2;
   }
   .signUp_leftBg{
    position: absolute;
    left: 0%;
    top: 40%;
    opacity: 0.2;
   }
   
   .copyRight_div p{
    margin: 0;
    color: var(--neutral-pure-white, #FFF);
    font-family: Uniform;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    opacity: 0.3;
   }


.verifiFailedSec{
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.verifiFailedSec p{
  color: var(--neutral-charcoal-black, #1D1D1B);
  text-align: center;
  font-family: Uniform;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  -webkit-text-stroke-width: 0.2px ;
}

.signSec_Col2 .successMsg{
  color: var(--neutral-charcoal-black, #1D1D1B);
  text-align: center;
  font-family: Uniform;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.notAllowed{
  cursor: not-allowed;
}
.verifySuccessSec{
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}



   /* Screen For 1280*1024 */
   @media screen and (max-width: 1280px) {
    .signSec_Col1 h1{
      font-size: 60px;
    }
    .signUp_divContents {
      width: 75%;
    }
  } 

  @media screen and (min-width: 600px) and (max-width: 992px) {
    .signUp_section {
      display: flex;
      flex-direction: column;
    }
    .signSec_Col1 {
      width: -webkit-fill-available !important;
      padding: 40px 40px !important;
    }
    .signSec_Col2 {
      width: -webkit-fill-available;
      padding: 40px 40px;
      display: block;
      min-height: 55vh;
    }
    .copyRight_div{
      display: none;
    }
  }

  @media screen and (max-width: 575px) {
    .signUp_section {
      display: flex;
      flex-direction: column;
    }
    .signUp_logoDiv img {
      width: 100px;
      height: 44px;
    }
    .signSec_Col1 h1{
      font-size: 35px;
      padding-top: 20px;
    }
    .signUp_divContents {
      width: 100%;
    }
    .signSec_Col1 {
      width: -webkit-fill-available !important;
      padding: 30px 30px !important;
    }
    .signSec_Col2 {
      width: -webkit-fill-available;
      padding: 30px 30px;
      display: block;
      min-height: 55vh;
    }
    .copyRight_div{
      display: none;
    }
  }
  

   


   