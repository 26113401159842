
.registReqContainer{
    display: flex;
    flex-direction: column;
    gap: 4px;
    justify-content: space-between;
    width: -webkit-fill-available;
}

.requestSection{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: -webkit-fill-available;
}

.userRequestCol1 h1{
    color: var(--neutral-charcoal-black, #1D1D1B);
    text-align: center;
    font-family: Uniform;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
    -webkit-text-stroke-width: 1px;
}